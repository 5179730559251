import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { CustomHttpResponse } from 'src/app/model/custom-http-response';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadStatus } from 'src/app/model/file-upload.status';
import { Role } from 'src/app/enum/role.enum';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private titleSubject = new BehaviorSubject<string>('Users');
  public titleAction$ = this.titleSubject.asObservable();
  public users: User[];
  public user: User;
  public refreshing: boolean;
  public selectedUser: User;
  public fileName: string;
  public profileImage: File;
  private subscriptions: Subscription[] = [];
  public editUser = new User();
  private currentUsername: string;
  public fileStatus = new FileUploadStatus();
  public logInMenu = false;
  public loginButtonClicked = false;
  public isAllValuesInFeets = true;
  @Output() clickedButtonFeetsMeters = new EventEmitter<boolean>();

  constructor(private router: Router, 
              public authenticationService: AuthenticationService,
              private userService: UserService, 
              private notificationService: NotificationService,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.authenticationService.isUserLoggedIn()) {
      this.user = this.authenticationService.getUserFromLocalCache();
    } 
    this.route.queryParams.subscribe(params => {
      if (params.feet != null) {
        this.isAllValuesInFeets = JSON.parse(params.feet);
      }
    });

  }

  public isAirportComponent(): boolean{
    if (this.route.snapshot.paramMap.get('iata') && this.route.snapshot.paramMap.get('iata').length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public onLogIn(): void {
    this.router.navigate(['/signin']);
    this.sendNotification(NotificationType.SUCCESS, `You've been successfully passed to Login page`);
  }

  public onButtonFeetMeters(): void {
    if (this.isAllValuesInFeets === false) {
      this.isAllValuesInFeets = true;
    } else {
      this.isAllValuesInFeets = false;
    }
    this.clickedButtonFeetsMeters.emit(this.isAllValuesInFeets);
  }

  public showUserDetails(): void {
    this.router.navigate(['/user']);
    this.sendNotification(NotificationType.SUCCESS, `You've been successfully passed to User details page`);
    }

  public closeLogInMenu() {
    if (this.loginButtonClicked === true) {
      this.logInMenu = false;
      this.loginButtonClicked = false;
    }
  }

  public showLogInMenu() {
    if (this.logInMenu === false) {
      this.logInMenu = true;
      this.loginButtonClicked = true;
    } else if (this.logInMenu === true) {
      this.logInMenu = false;
      this.loginButtonClicked = false;
    }
  }

  public onLogOut(): void {
    this.authenticationService.logOut();
    this.router.navigate(['/signin']);
    this.sendNotification(NotificationType.SUCCESS, `You've been successfully Signed Out`);
  }

  public get isAdmin(): boolean {
    return this.getUserRole() === Role.ADMIN || this.getUserRole() === Role.SUPER_ADMIN;
  }

  public get isManager(): boolean {
    return this.isAdmin || this.getUserRole() === Role.MANAGER;
  }

  public get isAdminOrManager(): boolean {
    return this.isAdmin || this.isManager;
  }

  private getUserRole(): string {
    return this.authenticationService.getUserFromLocalCache().role;
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
