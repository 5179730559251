import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { Airport } from '../common/airport';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, debounceTime, distinctUntilChanged, tap, switchMapTo, switchMap } from 'rxjs/operators';
import { Runway } from '../common/runway';
import { AircraftConfigurator } from '../common/aircraftConfigurator';
import { Remark } from '../common/remark';

@Injectable({
    providedIn: 'root'
})

export class AirportService {
    // private url = "http://localhost:8080";
    private url = "https://checkairport.net:8443/checkAirport";

    private baseUrlAiports = `${this.url}/api/airports`;
    private baseUrlRunways = `${this.url}/api/runways`;
    private baseUrlAircraftConfigurators = `${this.url}/api/aircraftConfigurators`;
    private baseUrlremarks = `${this.url}/api/remarks`;

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    constructor(private httpClient: HttpClient) { }

    /** GET */
    getAirport(iata: string): Observable<Airport> {
        //need to build URL based on airport id
        iata = iata.split('?')[0]; 
        const airportUrl = `${this.baseUrlAiports}/${iata}`;
        return this.httpClient.get<Airport>(airportUrl);
    }
    
    formatParameters(airport: Airport): Airport {
        if (airport.usage=="PU") {
            airport.usage = "Public";
        } else if  (airport.usage="PR") {
            airport.usage = "Private";
        } 
        
        if (airport.aptentry=="N") {
            airport.aptentry = "No";
        } else if  (airport.aptentry="Y") {
            airport.aptentry = "Yes";
        } 

        if (airport.landingrights=="N") {
            airport.landingrights = "No";
        } else if  (airport.landingrights="Y") {
            airport.landingrights = "Yes";
        } 
        if (airport.fire!=null && airport.fire.length>2) {
        let array: string[] = airport.fire.split(" ");
        airport.fire = array[1];
        airport.fireNumber = this.calculateARFFinNUM(airport.fire);
        }
       return airport;
    }

    calculateARFFinNUM(tmp: string): number {
        let result = null;
        if (tmp) {
          if (tmp === 'A') {
            result = 5;
          } else if (tmp === 'B') {
            result = 6;
          } else if (tmp === 'C') {
            result = 7;
          } else if (tmp === 'D') {
            result = 8;
          } else if (tmp === 'E') {
            result = 10;
          }
        }
        return result;
    }

    formatRunwaysParameters(runways: Runway[]): Runway[] {
        for (let i=0; i<runways.length; i++) {
            if (runways[i].runwaySurfaceTypeCondition!=null && runways[i].runwaySurfaceTypeCondition.length>2){
                if (runways[i].runwaySurfaceTypeCondition.substring(runways[i].runwaySurfaceTypeCondition.length-2)=="-E"){
                    runways[i].runwaySurfaceType = runways[i].runwaySurfaceTypeCondition.substring(0,runways[i].runwaySurfaceTypeCondition.length-2);
                    runways[i].runwaySurfaceCondition = "EXCELLENT";
                } else if (runways[i].runwaySurfaceTypeCondition.substring(runways[i].runwaySurfaceTypeCondition.length-2)=="-G"){
                    runways[i].runwaySurfaceType = runways[i].runwaySurfaceTypeCondition.substring(0,runways[i].runwaySurfaceTypeCondition.length-2);
                    runways[i].runwaySurfaceCondition = "GOOD";
                } else if (runways[i].runwaySurfaceTypeCondition.substring(runways[i].runwaySurfaceTypeCondition.length-2)=="-F"){
                    runways[i].runwaySurfaceType = runways[i].runwaySurfaceTypeCondition.substring(0,runways[i].runwaySurfaceTypeCondition.length-2);
                    runways[i].runwaySurfaceCondition = "FAIR";
                } else if (runways[i].runwaySurfaceTypeCondition.substring(runways[i].runwaySurfaceTypeCondition.length-2)=="-P"){
                    runways[i].runwaySurfaceType = runways[i].runwaySurfaceTypeCondition.substring(0,runways[i].runwaySurfaceTypeCondition.length-2);
                    runways[i].runwaySurfaceCondition = "POOR";
                } else if (runways[i].runwaySurfaceTypeCondition.substring(runways[i].runwaySurfaceTypeCondition.length-2)=="-L"){
                    runways[i].runwaySurfaceType = runways[i].runwaySurfaceTypeCondition.substring(0,runways[i].runwaySurfaceTypeCondition.length-2);
                    runways[i].runwaySurfaceCondition = "FAILED";
                } else {
                runways[i].runwaySurfaceType = runways[i].runwaySurfaceTypeCondition;
                runways[i].runwaySurfaceCondition = "UNKNOWN";
                }
            }
            if(runways[i].runwayPcn!=null && runways[i].runwayPcn.includes(" /")){
            runways[i].runwayPcn=runways[i].runwayPcn.replace(" /","/");
            }
            if (runways[i].baseEndMarkingsType!=null && runways[i].baseEndMarkingsType.length>0){
                if (runways[i].baseEndMarkingsType == "NPI"){
                    runways[i].baseEndMarkingsType = "Non-precision instrument";
                } else if (runways[i].baseEndMarkingsType == "NSTD"){
                    runways[i].baseEndMarkingsType = "Non-standard";
                } else if (runways[i].baseEndMarkingsType == "BSC"){
                    runways[i].baseEndMarkingsType = "Basic";
                } else if (runways[i].baseEndMarkingsType == "PIR"){
                    runways[i].baseEndMarkingsType = "Precision instrument";
                } else if (runways[i].baseEndMarkingsType == "NRS"){
                    runways[i].baseEndMarkingsType = "Numbers only";
                } else if (runways[i].baseEndMarkingsType == "BUOY"){
                    runways[i].baseEndMarkingsType = "Buoys";
                }
            }
            if (runways[i].reciprocalEndMarkingsType!=null && runways[i].reciprocalEndMarkingsType.length>0){
                if (runways[i].reciprocalEndMarkingsType == "NPI"){
                    runways[i].reciprocalEndMarkingsType = "Non-precision instrument";
                } else if (runways[i].reciprocalEndMarkingsType == "NSTD"){
                    runways[i].reciprocalEndMarkingsType = "Non-standard";
                } else if (runways[i].reciprocalEndMarkingsType == "BSC"){
                    runways[i].reciprocalEndMarkingsType = "Basic";
                } else if (runways[i].reciprocalEndMarkingsType == "PIR"){
                    runways[i].reciprocalEndMarkingsType = "Precision instrument";
                } else if (runways[i].reciprocalEndMarkingsType == "NRS"){
                    runways[i].reciprocalEndMarkingsType = "Numbers only";
                } else if (runways[i].reciprocalEndMarkingsType == "BUOY"){
                    runways[i].reciprocalEndMarkingsType = "Buoys";
                }
            }

            if (runways[i].baseEndMarkingsCondition!=null && runways[i].baseEndMarkingsCondition.length>0){
                if (runways[i].baseEndMarkingsCondition == "G"){
                    runways[i].baseEndMarkingsCondition = "Good";
                } else if (runways[i].baseEndMarkingsCondition == "F"){
                    runways[i].baseEndMarkingsCondition = "Fair";
                } else if (runways[i].baseEndMarkingsCondition == "P"){
                    runways[i].baseEndMarkingsCondition = "Poor";
                }
            }
            if (runways[i].reciprocalEndMarkingsCondition!=null && runways[i].reciprocalEndMarkingsCondition.length>0){
                if (runways[i].reciprocalEndMarkingsCondition == "G"){
                    runways[i].reciprocalEndMarkingsCondition = "Good";
                } else if (runways[i].reciprocalEndMarkingsCondition == "F"){
                    runways[i].reciprocalEndMarkingsCondition = "Fair";
                } else if (runways[i].reciprocalEndMarkingsCondition == "P"){
                    runways[i].reciprocalEndMarkingsCondition = "Poor";
                }
            }
            if (runways[i].edgeLightsIntensity!=null && runways[i].edgeLightsIntensity.length>0){
                if (runways[i].edgeLightsIntensity == "HIGH"){
                    runways[i].edgeLightsIntensity = "High intensity";
                } else if (runways[i].edgeLightsIntensity == "MED"){
                    runways[i].edgeLightsIntensity = "Medium intensity";
                } else if (runways[i].edgeLightsIntensity == "PERI"){
                    runways[i].edgeLightsIntensity = "Perimeter";
                } else if (runways[i].edgeLightsIntensity == "NSTD"){
                    runways[i].edgeLightsIntensity = "Non-standard";
                } else if (runways[i].edgeLightsIntensity == "LOW"){
                    runways[i].edgeLightsIntensity = "Low intensity";
                } else if (runways[i].edgeLightsIntensity == "FLD"){
                    runways[i].edgeLightsIntensity = "Flood";
                } else if (runways[i].edgeLightsIntensity == "STRB"){
                    runways[i].edgeLightsIntensity = "Strobe";
                }
            }
            if (runways[i].baseEndVASI!=null && runways[i].baseEndVASI.length>0){
                if (runways[i].baseEndVASI == "P4R"){
                    runways[i].baseEndVASI = "4-light PAPI on right";
                } else if (runways[i].baseEndVASI == "P2L"){
                    runways[i].baseEndVASI = "2-light PAPI on left";
                } else if (runways[i].baseEndVASI == "V2L"){
                    runways[i].baseEndVASI = "2-box VASI on left";
                } else if (runways[i].baseEndVASI == "V2R"){
                    runways[i].baseEndVASI = "2-box VASI on right";
                } else if (runways[i].baseEndVASI == "P4L"){
                    runways[i].baseEndVASI = "4-light PAPI on left";
                } else if (runways[i].baseEndVASI == "V4L"){
                    runways[i].baseEndVASI = "4-box VASI on left";
                } else if (runways[i].baseEndVASI == "V4R"){
                    runways[i].baseEndVASI = "4-box VASI on right";
                } else if (runways[i].baseEndVASI == "P2R"){
                    runways[i].baseEndVASI = "2-light PAPI on right";
                } else if (runways[i].baseEndVASI == "PSIL"){
                    runways[i].baseEndVASI = "PVASI on left";
                } else if (runways[i].baseEndVASI == "TRIL"){
                    runways[i].baseEndVASI = "TRVC on left";
                } else if (runways[i].baseEndVASI == "S2L"){
                    runways[i].baseEndVASI = "2-box SAVASI on left";
                } else if (runways[i].baseEndVASI == "NSTD"){
                    runways[i].baseEndVASI = "Non-standard";
                } else if (runways[i].baseEndVASI == "TRIR"){
                    runways[i].baseEndVASI = "TVRC on right";
                } else if (runways[i].baseEndVASI == "V6L"){
                    runways[i].baseEndVASI = "6-box VASI on left";
                } else if (runways[i].baseEndVASI == "S2R"){
                    runways[i].baseEndVASI = "2-box SAVASI on right";
                } else if (runways[i].baseEndVASI == "PNIL"){
                    runways[i].baseEndVASI = "APAP on left";
                } else if (runways[i].baseEndVASI == "V12"){
                    runways[i].baseEndVASI = "12-box VASI on both sides";
                } else if (runways[i].baseEndVASI == "PSIR"){
                    runways[i].baseEndVASI = "PVASI on right";
                } else if (runways[i].baseEndVASI == "PNIR"){
                    runways[i].baseEndVASI = "APAP on right";
                } else if (runways[i].baseEndVASI == "VAS"){
                    runways[i].baseEndVASI = "TBD";
                } else if (runways[i].baseEndVASI == "PVT"){
                    runways[i].baseEndVASI = "Privately owned on public aprt";
                } 
            }
            if (runways[i].reciprocalEndVASI!=null && runways[i].reciprocalEndVASI.length>0){
                if (runways[i].reciprocalEndVASI == "P4R"){
                    runways[i].reciprocalEndVASI = "4-light PAPI on right";
                } else if (runways[i].reciprocalEndVASI == "P2L"){
                    runways[i].reciprocalEndVASI = "2-light PAPI on left";
                } else if (runways[i].reciprocalEndVASI == "V2L"){
                    runways[i].reciprocalEndVASI = "2-box VASI on left";
                } else if (runways[i].reciprocalEndVASI == "V2R"){
                    runways[i].reciprocalEndVASI = "2-box VASI on right";
                } else if (runways[i].reciprocalEndVASI == "P4L"){
                    runways[i].reciprocalEndVASI = "4-light PAPI on left";
                } else if (runways[i].reciprocalEndVASI == "V4L"){
                    runways[i].reciprocalEndVASI = "4-box VASI on left";
                } else if (runways[i].reciprocalEndVASI == "V4R"){
                    runways[i].reciprocalEndVASI = "4-box VASI on right";
                } else if (runways[i].reciprocalEndVASI == "P2R"){
                    runways[i].reciprocalEndVASI = "2-light PAPI on right";
                } else if (runways[i].reciprocalEndVASI == "PSIL"){
                    runways[i].reciprocalEndVASI = "PVASI on left";
                } else if (runways[i].reciprocalEndVASI == "TRIL"){
                    runways[i].reciprocalEndVASI = "TRVC on left";
                } else if (runways[i].reciprocalEndVASI == "S2L"){
                    runways[i].reciprocalEndVASI = "2-box SAVASI on left";
                } else if (runways[i].reciprocalEndVASI == "NSTD"){
                    runways[i].reciprocalEndVASI = "Non-standard";
                } else if (runways[i].reciprocalEndVASI == "TRIR"){
                    runways[i].reciprocalEndVASI = "TVRC on right";
                } else if (runways[i].reciprocalEndVASI == "V6L"){
                    runways[i].reciprocalEndVASI = "6-box VASI on left";
                } else if (runways[i].reciprocalEndVASI == "S2R"){
                    runways[i].reciprocalEndVASI = "2-box SAVASI on right";
                } else if (runways[i].reciprocalEndVASI == "PNIL"){
                    runways[i].reciprocalEndVASI = "APAP on left";
                } else if (runways[i].reciprocalEndVASI == "V12"){
                    runways[i].reciprocalEndVASI = "12-box VASI on both sides";
                } else if (runways[i].reciprocalEndVASI == "PSIR"){
                    runways[i].reciprocalEndVASI = "PVASI on right";
                } else if (runways[i].reciprocalEndVASI == "PNIR"){
                    runways[i].reciprocalEndVASI = "APAP on right";
                } else if (runways[i].reciprocalEndVASI == "VAS"){
                    runways[i].reciprocalEndVASI = "TBD";
                } else if (runways[i].reciprocalEndVASI == "PVT"){
                    runways[i].reciprocalEndVASI = "Privately owned on public aprt";
                }
            }
            if (runways[i].baseEndCenterlineLights!=null && runways[i].baseEndCenterlineLights.length>0){
                if (runways[i].baseEndCenterlineLights == "Y"){
                    runways[i].baseEndCenterlineLights = "Yes";
                } else if (runways[i].baseEndCenterlineLights == "N"){
                    runways[i].baseEndCenterlineLights = "No";
                }
            }
            if (runways[i].reciprocalEndCenterlineLights!=null && runways[i].reciprocalEndCenterlineLights.length>0){
                if (runways[i].reciprocalEndCenterlineLights == "Y"){
                    runways[i].reciprocalEndCenterlineLights = "Yes";
                } else if (runways[i].reciprocalEndCenterlineLights == "N"){
                    runways[i].reciprocalEndCenterlineLights = "No";
                }
            }
            if (runways[i].baseEndREIL!=null && runways[i].baseEndREIL.length>0){
                if (runways[i].baseEndREIL == "Y"){
                    runways[i].baseEndREIL = "Yes";
                } else if (runways[i].baseEndREIL == "N"){
                    runways[i].baseEndREIL = "No";
                }
            }
            if (runways[i].reciprocalEndREIL!=null && runways[i].reciprocalEndREIL.length>0){
                if (runways[i].reciprocalEndREIL == "Y"){
                    runways[i].reciprocalEndREIL = "Yes";
                } else if (runways[i].reciprocalEndREIL == "N"){
                    runways[i].reciprocalEndREIL = "No";
                }
            }
            if (runways[i].baseEndTouchdownLights!=null && runways[i].baseEndTouchdownLights.length>0){
                if (runways[i].baseEndTouchdownLights == "Y"){
                    runways[i].baseEndTouchdownLights = "Yes";
                } else if (runways[i].baseEndTouchdownLights == "N"){
                    runways[i].baseEndTouchdownLights = "No";
                }
            }
            if (runways[i].reciprocalEndTouchdownLights!=null && runways[i].reciprocalEndTouchdownLights.length>0){
                if (runways[i].reciprocalEndTouchdownLights == "Y"){
                    runways[i].reciprocalEndTouchdownLights = "Yes";
                } else if (runways[i].reciprocalEndTouchdownLights == "N"){
                    runways[i].reciprocalEndTouchdownLights = "No";
                }
            }
            /*BaseEndDeclaredDistances*/
            if (runways[i].baseEndTakeOffRunAvailableTORA===null) {
                runways[i].baseEndTakeOffRunAvailableTORA=runways[i].runwayLength;
            }
            if (runways[i].baseEndTakeOffDistanceAvailableTODA===null) {
                runways[i].baseEndTakeOffDistanceAvailableTODA=runways[i].runwayLength;
            }
            if (runways[i].baseEndAcltStopDistanceAvailableASDA===null) {
                runways[i].baseEndAcltStopDistanceAvailableASDA=runways[i].runwayLength;
            }
            if (runways[i].baseEndLandingDistanceAvailableLDA === null) {
                if (runways[i].baseEndDisplacedLength !== null) {
                    runways[i].baseEndLandingDistanceAvailableLDA = runways[i].runwayLength - runways[i].baseEndDisplacedLength;
                } else if (runways[i].baseEndDisplacedLength === null){
                    runways[i].baseEndLandingDistanceAvailableLDA = runways[i].runwayLength;
                }
            }
            /*ReciprocalEndDeclaredDistances*/
            if (runways[i].reciprocalEndTakeOffRunAvailableTORA===null) {
                runways[i].reciprocalEndTakeOffRunAvailableTORA=runways[i].runwayLength;
            }
            if (runways[i].reciprocalEndTakeOffDistanceAvailableTODA===null) {
                runways[i].reciprocalEndTakeOffDistanceAvailableTODA=runways[i].runwayLength;
            }
            if (runways[i].reciprocalEndAcltStopDistanceAvailableASDA===null) {
                runways[i].reciprocalEndAcltStopDistanceAvailableASDA=runways[i].runwayLength;
            }
            if (runways[i].reciprocalEndLandingDistanceAvailableLDA===null) {
                if (runways[i].reciprocalEndDisplacedLength!==null){
                    runways[i].reciprocalEndLandingDistanceAvailableLDA=runways[i].runwayLength-runways[i].reciprocalEndDisplacedLength;
                } else if (runways[i].reciprocalEndDisplacedLength===null){
                    runways[i].reciprocalEndLandingDistanceAvailableLDA=runways[i].runwayLength;
                }
            }

            /*Runway slope*/
            /*baseEnd*/
            runways[i].baseEndEstimatedSlope =
            ((runways[i].reciprocalEndPhysicalElevation - runways[i].baseEndPhysicalElevation)/runways[i].runwayLength)*100;

            runways[i].baseEndEstimatedSlope = +runways[i].baseEndEstimatedSlope.toFixed(2);

            if (runways[i].baseEndEstimatedSlope > 0) {
                runways[i].baseEndEstimatedSlope_Str_forView = '↑ ' + runways[i].baseEndEstimatedSlope + '%';
            } else if (runways[i].baseEndEstimatedSlope < 0) {
                let tmp = runways[i].baseEndEstimatedSlope*(-1);
                runways[i].baseEndEstimatedSlope_Str_forView = '↓ ' + tmp + '%';
            } else if (runways[i].baseEndEstimatedSlope === 0
                && runways[i].reciprocalEndPhysicalElevation - runways[i].baseEndPhysicalElevation !== 0) {
                runways[i].baseEndEstimatedSlope_Str_forView = '≈ ' + runways[i].baseEndEstimatedSlope + '%';
            } else if (runways[i].baseEndEstimatedSlope === 0) {
                runways[i].baseEndEstimatedSlope_Str_forView = runways[i].baseEndEstimatedSlope + '%';
            }

            /*reciprocalEnd*/
            runways[i].reciprocalEndEstimatedSlope =
            ((runways[i].baseEndPhysicalElevation - runways[i].reciprocalEndPhysicalElevation)/runways[i].runwayLength)*100;

            runways[i].reciprocalEndEstimatedSlope = +runways[i].reciprocalEndEstimatedSlope.toFixed(2);

            if (runways[i].reciprocalEndEstimatedSlope > 0) {
                runways[i].reciprocalEndEstimatedSlope_Str_forView = '↑ '  + runways[i].reciprocalEndEstimatedSlope + '%';
            } else if (runways[i].reciprocalEndEstimatedSlope < 0) {
                let tmp = runways[i].reciprocalEndEstimatedSlope*(-1);
                runways[i].reciprocalEndEstimatedSlope_Str_forView = '↓ ' + tmp + '%';
            } else if (runways[i].reciprocalEndEstimatedSlope === 0
                && runways[i].reciprocalEndPhysicalElevation - runways[i].baseEndPhysicalElevation !== 0) {
                runways[i].reciprocalEndEstimatedSlope_Str_forView = '≈ ' + runways[i].reciprocalEndEstimatedSlope + '%';
            } else if (runways[i].reciprocalEndEstimatedSlope === 0) {
                runways[i].reciprocalEndEstimatedSlope_Str_forView = runways[i].reciprocalEndEstimatedSlope + '%';
            }

            runways[i].baseEndPhysicalLatitudeMap = this.formatLatitudeForGoogleMap(runways[i].baseEndPhysicalLatitude);
            runways[i].baseEndPhysicalLongitudeMap = this.formatLongitudeForGoogleMap(runways[i].baseEndPhysicalLongitude);
          
            runways[i].reciprocalEndPhysicalLatitudeMap = this.formatLatitudeForGoogleMap(runways[i].reciprocalEndPhysicalLatitude);
            runways[i].reciprocalEndPhysicalLongitudeMap = this.formatLongitudeForGoogleMap(runways[i].reciprocalEndPhysicalLongitude);
        }
        return runways;
    }

    getAirportList(): Observable<Airport[]> {
        return this.httpClient.get<GetResponseAirports>(this.baseUrlAiports).pipe(
            map(response => response._embedded.airports)
        );
    }

    /* GET */
    getAircraftConfiguratorList(): Observable<AircraftConfigurator[]> {
        return this.httpClient.get<GetAircraftConfigurator>(this.baseUrlAircraftConfigurators).pipe(
            map(response => response._embedded.aircraftConfigurators));
    }

    getAircraftConfiguratorByUserName(username: string): Observable<AircraftConfigurator[]> {
        return this.httpClient.get<GetAircraftConfigurator>(`${this.baseUrlAircraftConfigurators}/search/findByUsername?username=${username}`).pipe(
            map(response => response._embedded.aircraftConfigurators));
    }
   
    /** POST */
    postAircraftConfigurator(aircraftConfigurator: AircraftConfigurator): Observable<any> {
        let searchUrl = `${this.baseUrlAircraftConfigurators}/`;
        return this.httpClient.post(searchUrl, aircraftConfigurator, this.httpOptions);
    }

    /** PUT */
    updateAircraftConfigurator(aircraftConfigurator: AircraftConfigurator): Observable<any> {
        let searchUrl = `${this.baseUrlAircraftConfigurators}/${aircraftConfigurator.idConfigurator}`;
        return this.httpClient.put(searchUrl, aircraftConfigurator, this.httpOptions);
    }
    
    /* DELETE */
    deleteThisAicraftConfigurator(id: number): Observable<AircraftConfigurator> {
        const airportUrl = `${this.baseUrlAircraftConfigurators}/${id}`;
        return this.httpClient.delete<AircraftConfigurator>(airportUrl, this.httpOptions);
    }

    searchAirports(theKeyword: string): Observable<Airport[]> {
        // let searchUrl = `${this.baseUrlAiports}/search/findByFullAptNameContainingOrderByIataDesc?name=${theKeyword}`;

        let searchUrl = `${this.baseUrlAiports}/search/findByIataContainingOrIcaoContainingOrAptnameContainingOrCityContainingOrderByIataDesc?iata=${theKeyword}&icao=${theKeyword}&aptname=${theKeyword}&city=${theKeyword}`;
        return this.getAirports(searchUrl);
    }

    private getAirports(searchUrl: string): Observable<Airport[]> {
        return this.httpClient.get<GetResponseAirports>(searchUrl).pipe(
            map(response => response._embedded.airports));
    }

    searchRunways(facilityId: string): Observable<Runway[]> {
        let searchUrl = `${this.baseUrlRunways}/search/findByRunwayCodeContaining?name=${facilityId}`;
        return this.getRunways(searchUrl);
    }

    private getRunways(searchUrl: string): Observable<Runway[]> {
        return this.httpClient.get<GetResponseRunways>(searchUrl).pipe(map(response => response._embedded.runways));
    }

    getOnlyRunways(runways: Runway[]): Runway[] {
        let tmpArray: Runway[] = [];
        for (let i=0; i<runways.length; i++){
            if(runways[i].runwayId.includes("/")){
            tmpArray.push(runways[i]);
            }
        }
        return tmpArray;
    }

    getOnlyHelipads(runways: Runway[]): Runway[] {
        let tmpArray: Runway[] = [];
        for (let i=0; i<runways.length; i++){
            if(!runways[i].runwayId.includes('/') && runways[i].runwayId.substring(0,1) === 'H'){
                tmpArray.push(runways[i]);
            }
        }
        return tmpArray;
    }

    formatCoordinates(airport: Airport): Airport {
        /* for displaying in menu only */
        const arrayLatitude: string[] = airport.latitude.split('-');
        const latitudeDegrees = arrayLatitude[0] + '°';
        const latitudeMinutes = arrayLatitude[1] + '\'';
        const latitudeNumberOfSeconds = arrayLatitude[2].substring(0, arrayLatitude[2].length-1) + '\"';
        const NorthOrSouth = arrayLatitude[2].substring( arrayLatitude[2].length-1);
        airport.latitudeMainMenu = latitudeDegrees + latitudeMinutes + latitudeNumberOfSeconds + NorthOrSouth;

        const arrayLongitude: string[] = airport.longitude.split('-');
        const longitudeDegrees = arrayLongitude[0] + '°';
        const longitudeMinutes = arrayLongitude[1] + '\'';
        const longitudeNumberOfSeconds = arrayLongitude[2].substring(0, arrayLongitude[2].length-1) + '\"';
        const EastOrWest = arrayLongitude[2].substring( arrayLongitude[2].length-1);
        airport.longitudeMainMenu = longitudeDegrees + longitudeMinutes + longitudeNumberOfSeconds + EastOrWest;

        airport.latitudeMap = +arrayLatitude[0] + (+arrayLatitude[1] +
        (+arrayLatitude[2].substring(0, arrayLatitude[2].length-1))/60)/60;
        if (NorthOrSouth ==='S') {
            airport.latitudeMap = -airport.latitudeMap;
        }

        airport.longitudeMap = +arrayLongitude[0] + (+arrayLongitude[1] +
        (+arrayLongitude[2].substring(0, arrayLongitude[2].length-1))/60)/60;
        if (EastOrWest ==='W') {
            airport.longitudeMap = -airport.longitudeMap;
        }

        return airport;
    }

    formatLatitudeForGoogleMap(latitude: string): number {
        if (latitude !== null) {
        const arrayLatitude: string[] = latitude.split('-');
        const NorthOrSouth = arrayLatitude[2].substring( arrayLatitude[2].length-1);

        let latitudeForGoogleMap = +arrayLatitude[0] + (+arrayLatitude[1] +
        (+arrayLatitude[2].substring(0, arrayLatitude[2].length-1))/60)/60;
        if (NorthOrSouth ==='S') {
            latitudeForGoogleMap = -latitudeForGoogleMap;
        }
        return latitudeForGoogleMap;
        } else {
            return null;
        }
    }

    formatLongitudeForGoogleMap(longitude: string): number {
        if (longitude !== null) {
        const arrayLongitude: string[] = longitude.split('-');
        const EastOrWest = arrayLongitude[2].substring( arrayLongitude[2].length-1);

        let longitudeForGoogleMap = +arrayLongitude[0] + (+arrayLongitude[1] +
            (+arrayLongitude[2].substring(0, arrayLongitude[2].length-1))/60)/60;
            if (EastOrWest ==='W') {
                longitudeForGoogleMap = -longitudeForGoogleMap;
            }
        return longitudeForGoogleMap;
        } else {
            return null;
        }
    }

    /*Remarks */
    searchRemarks(facilityId: string): Observable<Remark[]> {
        let searchUrl = `${this.baseUrlremarks}/search/findByRemarkcodeContaining?name=${facilityId}`;
        return this.getRemarks(searchUrl);
    }
    private getRemarks(searchUrl: string): Observable<Remark[]> {
        return this.httpClient.get<GetResponseRemarks>(searchUrl).pipe(map(response => response._embedded.remarks));
    }
    /*END. Remarks */

    /*Get color for map*/
    getColorOfRunwayMap(runway: Runway, aircraftConfigurator: AircraftConfigurator, airport: Airport, 
        switchConfiguratorStatus: boolean): string {
        
        let arraySuitableParams = [];
        let arrayRestrParams = [];
        let arrayNotSuitableParams = [];

        let result = 'black';

        if (runway.baseEndPhysicalElevation != null && aircraftConfigurator.turnOnOffMaxElevationAll === true &&
                (aircraftConfigurator.elevationSuitableMin != null && aircraftConfigurator.elevationSuitableMax != null ||
                aircraftConfigurator.elevationSuitableRestrMin != null && aircraftConfigurator.elevationSuitableRestrMax != null)) {
            if (aircraftConfigurator.elevationSuitableMin != null && aircraftConfigurator.elevationSuitableMax != null &&
                aircraftConfigurator.turnOnOffMaxElevationSuitable === true &&
                runway.baseEndPhysicalElevation >= aircraftConfigurator.elevationSuitableMin &&
                runway.baseEndPhysicalElevation <= aircraftConfigurator.elevationSuitableMax) {
                arraySuitableParams.push('SUI baseEndPhysicalElevation');
            } else if (aircraftConfigurator.elevationSuitableRestrMin != null && aircraftConfigurator.elevationSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMaxElevationRestr === true &&
                runway.baseEndPhysicalElevation >= aircraftConfigurator.elevationSuitableRestrMin &&
                runway.baseEndPhysicalElevation <= aircraftConfigurator.elevationSuitableRestrMax) {
                arrayRestrParams.push('RESTR baseEndPhysicalElevation');
            } else if (aircraftConfigurator.turnOnOffMaxElevationUnSuitable === true) {
                arrayNotSuitableParams.push('NOT baseEndPhysicalElevation');
            }
        }

        if (runway.reciprocalEndPhysicalElevation != null && aircraftConfigurator.turnOnOffMaxElevationAll === true &&
                (aircraftConfigurator.elevationSuitableMin != null && aircraftConfigurator.elevationSuitableMax != null ||
                aircraftConfigurator.elevationSuitableRestrMin != null && aircraftConfigurator.elevationSuitableRestrMax != null)) {
            if (aircraftConfigurator.elevationSuitableMin != null && aircraftConfigurator.elevationSuitableMax != null &&
                aircraftConfigurator.turnOnOffMaxElevationSuitable === true &&
                runway.reciprocalEndPhysicalElevation >= aircraftConfigurator.elevationSuitableMin &&
                runway.reciprocalEndPhysicalElevation <= aircraftConfigurator.elevationSuitableMax) {
                arraySuitableParams.push('SUI reciprocalEndPhysicalElevation');
            } else if (aircraftConfigurator.elevationSuitableRestrMin != null && aircraftConfigurator.elevationSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMaxElevationRestr === true &&
                runway.reciprocalEndPhysicalElevation >= aircraftConfigurator.elevationSuitableRestrMin &&
                runway.reciprocalEndPhysicalElevation <= aircraftConfigurator.elevationSuitableRestrMax) {
                arrayRestrParams.push('RESTR reciprocalEndPhysicalElevation');
            } else if (aircraftConfigurator.turnOnOffMaxElevationUnSuitable === true) {
                arrayNotSuitableParams.push('NOT reciprocalEndPhysicalElevation');
            }
        }

        if (runway.runwayLength != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.runwayLength >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.runwayLength <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI runwayLength');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.runwayLength >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.runwayLength <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR runwayLength');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT runwayLength');
            }
        }

        if (runway.runwayWidth != null && aircraftConfigurator.turnOnOffMinRunwayWidthAll === true &&
                (aircraftConfigurator.rwyWidthSuitableMin != null && aircraftConfigurator.rwyWidthSuitableMax != null ||
                aircraftConfigurator.rwyWidthSuitableRestrMin != null && aircraftConfigurator.rwyWidthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyWidthSuitableMin != null && aircraftConfigurator.rwyWidthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayWidthSuitable === true &&
                runway.runwayWidth >= aircraftConfigurator.rwyWidthSuitableMin &&
                runway.runwayWidth <= aircraftConfigurator.rwyWidthSuitableMax) {
                arraySuitableParams.push('SUI runwayWidth');
            } else if (aircraftConfigurator.rwyWidthSuitableRestrMin != null && aircraftConfigurator.rwyWidthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayWidthRestr === true &&
                runway.runwayWidth >= aircraftConfigurator.rwyWidthSuitableRestrMin &&
                runway.runwayWidth <= aircraftConfigurator.rwyWidthSuitableRestrMax) {
                arrayRestrParams.push('RESTR runwayWidth');
            } else if (aircraftConfigurator.turnOnOffMinRunwayWidthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT runwayWidth');
            }
        }

        if (runway.runwaySurfaceType != null && aircraftConfigurator.turnOnOffSurfaceSuitableAll === true &&
            (aircraftConfigurator.rwySurfaceSuitableArray.length > 0 || aircraftConfigurator.rwySurfaceSuitableRestrArray.length > 0)) {
            if (this.checkSuiRunwaySurfaceType(runway.runwaySurfaceType, aircraftConfigurator) && aircraftConfigurator.turnOnOffSurfaceSuitable === true) {
                arraySuitableParams.push('SUI runwaySurfaceType');
            } else if (this.checkRestrRunwaySurfaceType(runway.runwaySurfaceType, aircraftConfigurator) && aircraftConfigurator.turnOnOffSurfaceSuitableRestr === true) {
                arrayRestrParams.push('RESTR runwaySurfaceType');
            } else if (aircraftConfigurator.turnOnOffSurfaceUnSuitable === true)  {
                arrayNotSuitableParams.push('NOT runwaySurfaceType');
            }
        }
        
        if (aircraftConfigurator.turnOnOffMinRunwayStrengthAll) {
            let arrayGreenParams = [];
            let arrayYellowParams = [];
            let arrayRedParams = [];
            
            if (runway.runwayPcn !== null &&
                ((aircraftConfigurator.rwyStrengthSuitableMin !== null && aircraftConfigurator.rwyStrengthSuitableMax !== null) ||
                (aircraftConfigurator.rwyStrengthSuitableRestrMin !== null && aircraftConfigurator.rwyStrengthSuitableRestrMin !== null))) {
                if (this.checkSuiRunwayPCN(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable === true) {
                    arrayGreenParams.push('SUI runwayPcn');
                } else if (this.checkRestrRunwayPCN(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthRestr === true) {
                    arrayYellowParams.push('RESTR runwayPcn');
                } else if (aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable === true)  {
                    arrayRedParams.push('NOT runwayPcn');
                }
            }
        
            if (runway.runwayWeightBearingCapacitySW !== null &&
                ((aircraftConfigurator.rwyStrengthSuitableMin !== null && aircraftConfigurator.rwyStrengthSuitableMax !== null) ||
                (aircraftConfigurator.rwyStrengthSuitableRestrMin !== null && aircraftConfigurator.rwyStrengthSuitableRestrMin !== null))) {
                if (this.checkSuiRunwaySW(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable === true) {
                    arrayGreenParams.push('SUI runwayWeightBearingCapacitySW');
                } else if (this.checkRestrRunwaySW(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthRestr === true) {
                    arrayYellowParams.push('RESTR runwayWeightBearingCapacitySW');
                } else if (aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable === true) {
                    arrayRedParams.push('NOT runwayWeightBearingCapacitySW');
                }
            }
        
            if (runway.runwayWeightBearingCapacityDW !== null &&
                ((aircraftConfigurator.rwyStrengthSuitableMin !== null && aircraftConfigurator.rwyStrengthSuitableMax !== null) ||
                (aircraftConfigurator.rwyStrengthSuitableRestrMin !== null && aircraftConfigurator.rwyStrengthSuitableRestrMin !== null))) {
                if (this.checkSuiRunwayDW(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable === true) {
                    arrayGreenParams.push('SUI runwayWeightBearingCapacityDW');
                } else if (this.checkRestrRunwayDW(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthRestr === true) {
                    arrayYellowParams.push('RESTR runwayWeightBearingCapacityDW');
                } else if (aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable === true) {
                    arrayRedParams.push('NOT runwayWeightBearingCapacityDW');
                }  
            }
        
            if (runway.runwayWeightBearingCapacityDT !== null &&
                ((aircraftConfigurator.rwyStrengthSuitableMin !== null && aircraftConfigurator.rwyStrengthSuitableMax !== null) ||
                (aircraftConfigurator.rwyStrengthSuitableRestrMin !== null && aircraftConfigurator.rwyStrengthSuitableRestrMin !== null))) {
                if (this.checkSuiRunwayDT(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable === true) {
                    arrayGreenParams.push('SUI runwayWeightBearingCapacityDT');
                } else if (this.checkRestrRunwayDT(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthRestr === true) {
                    arrayYellowParams.push('RESTR runwayWeightBearingCapacityDT');
                } else if (aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable === true) {
                    arrayRedParams.push('NOT runwayWeightBearingCapacityDT');
                }
            }
        
            if (runway.runwayWeightBearingCapacityDDT !== null &&
                ((aircraftConfigurator.rwyStrengthSuitableMin !== null && aircraftConfigurator.rwyStrengthSuitableMax !== null) ||
                (aircraftConfigurator.rwyStrengthSuitableRestrMin !== null && aircraftConfigurator.rwyStrengthSuitableRestrMin !== null))) {
                if (this.checkSuiRunwayDDT(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable === true) {
                    arrayGreenParams.push('SUI runwayWeightBearingCapacityDDT');
                } else if (this.checkRestrRunwayDDT(runway, aircraftConfigurator) && aircraftConfigurator.turnOnOffMinRunwayStrengthRestr === true) {
                    arrayYellowParams.push('RESTR runwayWeightBearingCapacityDDT');
                } else if (aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable === true) {
                    arrayRedParams.push('NOT runwayWeightBearingCapacityDDT');
                }
            }

            if (arrayGreenParams.length > 0) {
                arraySuitableParams.push('Total strength is Suitable');
            } else if (arrayYellowParams.length > 0) {
                arrayRestrParams.push('Total strength is Restr.suitable');
            } else if (arrayRedParams.length > 0) {
                arrayNotSuitableParams.push('Total strength is UN.suitable');
            }
        }

        if (runway.baseEndEstimatedSlope != null && aircraftConfigurator.turnOnOffMaxSlopeAll === true &&
                (aircraftConfigurator.rwySlopeSuitableMin != null && aircraftConfigurator.rwySlopeSuitableMax != null ||
                aircraftConfigurator.rwySlopeSuitableFirstRestrMin != null && aircraftConfigurator.rwySlopeSuitableFirstRestrMax != null ||
                aircraftConfigurator.rwySlopeSuitableSecondRestrMin != null && aircraftConfigurator.rwySlopeSuitableSecondRestrMax != null)) {
            if (aircraftConfigurator.rwySlopeSuitableMin != null && aircraftConfigurator.rwySlopeSuitableMax != null &&
                aircraftConfigurator.turnOnOffMaxSlopeSuitable === true &&
                runway.baseEndEstimatedSlope >= aircraftConfigurator.rwySlopeSuitableMin &&
                runway.baseEndEstimatedSlope <= aircraftConfigurator.rwySlopeSuitableMax) {
                arraySuitableParams.push('SUI baseEndEstimatedSlope');
            } else if ((aircraftConfigurator.rwySlopeSuitableFirstRestrMin != null && aircraftConfigurator.rwySlopeSuitableFirstRestrMax != null &&
                runway.baseEndEstimatedSlope >= aircraftConfigurator.rwySlopeSuitableFirstRestrMin &&
                runway.baseEndEstimatedSlope <= aircraftConfigurator.rwySlopeSuitableFirstRestrMax &&
                aircraftConfigurator.turnOnOffMaxSlopeFirstRestr === true) ||
                    (aircraftConfigurator.rwySlopeSuitableSecondRestrMin != null && aircraftConfigurator.rwySlopeSuitableSecondRestrMax != null &&
                    runway.baseEndEstimatedSlope >= aircraftConfigurator.rwySlopeSuitableSecondRestrMin &&
                    runway.baseEndEstimatedSlope <= aircraftConfigurator.rwySlopeSuitableSecondRestrMax &&
                    aircraftConfigurator.turnOnOffMaxSlopeFirstRestr === true)) {
                arrayRestrParams.push('RESTR baseEndEstimatedSlope');
            } else if (aircraftConfigurator.turnOnOffMaxSlopeUnSuitable === true) {
                arrayNotSuitableParams.push('NOT baseEndEstimatedSlope');
            }
        }

        if (runway.reciprocalEndEstimatedSlope != null && aircraftConfigurator.turnOnOffMaxSlopeAll === true &&
            (aircraftConfigurator.rwySlopeSuitableMin != null && aircraftConfigurator.rwySlopeSuitableMax != null ||
                aircraftConfigurator.rwySlopeSuitableFirstRestrMin != null && aircraftConfigurator.rwySlopeSuitableFirstRestrMax != null ||
                aircraftConfigurator.rwySlopeSuitableSecondRestrMin != null && aircraftConfigurator.rwySlopeSuitableSecondRestrMax != null)) {
            if (aircraftConfigurator.rwySlopeSuitableMin != null && aircraftConfigurator.rwySlopeSuitableMax != null &&
                runway.reciprocalEndEstimatedSlope >= aircraftConfigurator.rwySlopeSuitableMin &&
                runway.reciprocalEndEstimatedSlope <= aircraftConfigurator.rwySlopeSuitableMax &&
                aircraftConfigurator.turnOnOffMaxSlopeSuitable === true) {
                arraySuitableParams.push('SUI reciprocalEndEstimatedSlope');
            } else if ((aircraftConfigurator.rwySlopeSuitableFirstRestrMin != null && aircraftConfigurator.rwySlopeSuitableFirstRestrMax != null &&
                runway.reciprocalEndEstimatedSlope >= aircraftConfigurator.rwySlopeSuitableFirstRestrMin &&
                runway.reciprocalEndEstimatedSlope <= aircraftConfigurator.rwySlopeSuitableFirstRestrMax &&
                aircraftConfigurator.turnOnOffMaxSlopeFirstRestr === true) ||
                    (aircraftConfigurator.rwySlopeSuitableSecondRestrMin != null && aircraftConfigurator.rwySlopeSuitableSecondRestrMax != null &&
                    runway.reciprocalEndEstimatedSlope >= aircraftConfigurator.rwySlopeSuitableSecondRestrMin &&
                    runway.reciprocalEndEstimatedSlope <= aircraftConfigurator.rwySlopeSuitableSecondRestrMax &&
                    aircraftConfigurator.turnOnOffMaxSlopeFirstRestr === true)) {
                arrayRestrParams.push('RESTR reciprocalEndEstimatedSlope');
            } else if (aircraftConfigurator.turnOnOffMaxSlopeUnSuitable === true) {
                arrayNotSuitableParams.push('NOT reciprocalEndEstimatedSlope');
            }
        }

        if (runway.baseEndTakeOffRunAvailableTORA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.baseEndTakeOffRunAvailableTORA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.baseEndTakeOffRunAvailableTORA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI baseEndTakeOffRunAvailableTORA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.baseEndTakeOffRunAvailableTORA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.baseEndTakeOffRunAvailableTORA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR baseEndTakeOffRunAvailableTORA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT baseEndTakeOffRunAvailableTORA');
            }
        }

        if (runway.reciprocalEndTakeOffRunAvailableTORA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.reciprocalEndTakeOffRunAvailableTORA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.reciprocalEndTakeOffRunAvailableTORA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI reciprocalEndTakeOffRunAvailableTORA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.reciprocalEndTakeOffRunAvailableTORA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.reciprocalEndTakeOffRunAvailableTORA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR reciprocalEndTakeOffRunAvailableTORA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT reciprocalEndTakeOffRunAvailableTORA');
            }
        }

        if (runway.baseEndTakeOffDistanceAvailableTODA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.baseEndTakeOffDistanceAvailableTODA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.baseEndTakeOffDistanceAvailableTODA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI baseEndTakeOffDistanceAvailableTODA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.baseEndTakeOffDistanceAvailableTODA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.baseEndTakeOffDistanceAvailableTODA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR baseEndTakeOffDistanceAvailableTODA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT baseEndTakeOffDistanceAvailableTODA');
            }
        }

        if (runway.reciprocalEndTakeOffDistanceAvailableTODA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.reciprocalEndTakeOffDistanceAvailableTODA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.reciprocalEndTakeOffDistanceAvailableTODA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI reciprocalEndTakeOffDistanceAvailableTODA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.reciprocalEndTakeOffDistanceAvailableTODA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.reciprocalEndTakeOffDistanceAvailableTODA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR reciprocalEndTakeOffDistanceAvailableTODA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT reciprocalEndTakeOffDistanceAvailableTODA');
            }
        }

        if (runway.baseEndAcltStopDistanceAvailableASDA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.baseEndAcltStopDistanceAvailableASDA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.baseEndAcltStopDistanceAvailableASDA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI baseEndAcltStopDistanceAvailableASDA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.baseEndAcltStopDistanceAvailableASDA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.baseEndAcltStopDistanceAvailableASDA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR baseEndAcltStopDistanceAvailableASDA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT baseEndAcltStopDistanceAvailableASDA');
            }
        }

        if (runway.reciprocalEndAcltStopDistanceAvailableASDA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.reciprocalEndAcltStopDistanceAvailableASDA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.reciprocalEndAcltStopDistanceAvailableASDA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI reciprocalEndAcltStopDistanceAvailableASDA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.reciprocalEndAcltStopDistanceAvailableASDA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.reciprocalEndAcltStopDistanceAvailableASDA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR reciprocalEndAcltStopDistanceAvailableASDA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT reciprocalEndAcltStopDistanceAvailableASDA');
            }
        }

        if (runway.baseEndLandingDistanceAvailableLDA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
                (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
                aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.baseEndLandingDistanceAvailableLDA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.baseEndLandingDistanceAvailableLDA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI baseEndLandingDistanceAvailableLDA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.baseEndLandingDistanceAvailableLDA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.baseEndLandingDistanceAvailableLDA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR baseEndLandingDistanceAvailableLDA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT baseEndLandingDistanceAvailableLDA');
            }
        }

        if (runway.reciprocalEndLandingDistanceAvailableLDA != null && aircraftConfigurator.turnOnOffMinRunwayLengthAll === true &&
            (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null ||
             aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null)) {
            if (aircraftConfigurator.rwyLengthSuitableMin != null && aircraftConfigurator.rwyLengthSuitableMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true &&
                runway.reciprocalEndLandingDistanceAvailableLDA >= aircraftConfigurator.rwyLengthSuitableMin &&
                runway.reciprocalEndLandingDistanceAvailableLDA <= aircraftConfigurator.rwyLengthSuitableMax) {
                arraySuitableParams.push('SUI reciprocalEndLandingDistanceAvailableLDA');
            } else if (aircraftConfigurator.rwyLengthSuitableRestrMin != null && aircraftConfigurator.rwyLengthSuitableRestrMax != null &&
                aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true &&
                runway.reciprocalEndLandingDistanceAvailableLDA >= aircraftConfigurator.rwyLengthSuitableRestrMin &&
                runway.reciprocalEndLandingDistanceAvailableLDA <= aircraftConfigurator.rwyLengthSuitableRestrMax) {
                arrayRestrParams.push('RESTR reciprocalEndLandingDistanceAvailableLDA');
            } else if (aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true) {
                arrayNotSuitableParams.push('NOT reciprocalEndLandingDistanceAvailableLDA');
            }
        }

        if (aircraftConfigurator.turnOnOffMinARFFAll === true &&
            (aircraftConfigurator.arffSuitableMin != null && aircraftConfigurator.arffSuitableMax != null ||
             aircraftConfigurator.arffSuitableRestrMin != null && aircraftConfigurator.arffSuitableRestrMax != null)) {
            if (aircraftConfigurator.turnOnOffMinARFFSuitable === true &&
                airport.fireNumber >= aircraftConfigurator.arffSuitableMin && airport.fireNumber <= aircraftConfigurator.arffSuitableMax) {
                arraySuitableParams.push('SUI fireNumber');
            } else if (aircraftConfigurator.turnOnOffMinARFFRestr === true &&
                airport.fireNumber >= aircraftConfigurator.arffSuitableRestrMin && airport.fireNumber <= aircraftConfigurator.arffSuitableRestrMax) {
                arrayRestrParams.push('RESTR fireNumber');
            } else if (aircraftConfigurator.turnOnOffMinARFFUnSuitable === true) {
                arrayNotSuitableParams.push('NOT fireNumber');
            }
        }

        if (switchConfiguratorStatus && arraySuitableParams.length > 0 && arrayRestrParams.length == 0 && arrayNotSuitableParams.length == 0) {
            result = 'chartreuse';
        } else if (switchConfiguratorStatus && arrayRestrParams.length > 0 && arrayNotSuitableParams.length == 0) {
            result = 'yellow';
        } else if (switchConfiguratorStatus && arrayNotSuitableParams.length > 0) {
            result = 'red';
        }
        return result;
    }

    checkACNexist(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway !== null && tempRunway.runwayPcn !== null) {
          
          let tempRunwayPcnArray = tempRunway.runwayPcn.split('/');
    
          for (let i = 0; i < tempRunwayPcnArray.length; i++) {
            if (tempRunwayPcnArray.length >= 3) {
              let PCN = +tempRunwayPcnArray[0].trim();
              if (tempRunwayPcnArray[1] === 'F') {
                if (tempRunwayPcnArray[2] === 'A' && aircraftConfigurator.acn_MTOW_F_A !== null && aircraftConfigurator.acn_DOW_F_A !== null) {
                  result = true;
                }
                if (tempRunwayPcnArray[2] === 'B' && aircraftConfigurator.acn_MTOW_F_B !== null && aircraftConfigurator.acn_DOW_F_B !== null) {
                  result = true;
                }
                if (tempRunwayPcnArray[2] === 'C' && aircraftConfigurator.acn_MTOW_F_C !== null && aircraftConfigurator.acn_DOW_F_C !== null) {
                  result = true;
                }
                if (tempRunwayPcnArray[2] === 'D' && aircraftConfigurator.acn_MTOW_F_D !== null && aircraftConfigurator.acn_DOW_F_D !== null) {
                  result = true;
                }
              }
              if (tempRunwayPcnArray[1] === 'R') {
                if (tempRunwayPcnArray[2] === 'A' && aircraftConfigurator.acn_MTOW_R_A !== null && aircraftConfigurator.acn_DOW_R_A !== null) {
                  result = true;
                }
                if (tempRunwayPcnArray[2] === 'B' && aircraftConfigurator.acn_MTOW_R_B !== null && aircraftConfigurator.acn_DOW_R_B !== null) {
                  result = true;
                }
                if (tempRunwayPcnArray[2] === 'C' && aircraftConfigurator.acn_MTOW_R_C !== null && aircraftConfigurator.acn_DOW_R_C !== null) {
                  result = true;
                }
                if (tempRunwayPcnArray[2] === 'D' && aircraftConfigurator.acn_MTOW_R_D !== null && aircraftConfigurator.acn_DOW_R_D !== null) {
                  result = true;
                }
              }
        }
        }
        }
        return result;
      }
    checkSuiRunwaySurfaceType(tempRunwaySurfaceType: string, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (aircraftConfigurator.rwySurfaceSuitableArray) {
            for (let tempSurface of aircraftConfigurator.rwySurfaceSuitableArray) {
                if (tempSurface === tempRunwaySurfaceType) {
                    result = true;
                }
            }
        }
        return result;
    }

    checkRestrRunwaySurfaceType(tempRunwaySurfaceType: string, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (aircraftConfigurator.rwySurfaceSuitableRestrArray) {
            for (let tempSurface of aircraftConfigurator.rwySurfaceSuitableRestrArray) {
                if (tempSurface === tempRunwaySurfaceType) {
                    result = true;
                }
            }
        }
        return result;
    }

    /*BEGINNING. PCN*/
    checkSuiRunwayPCN(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayPcn !== null) {
          result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayPCN(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    compareSuitableMTOWwithConfiguratorMINandMAX(tmp: number, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        let MTOWinPercents = tmp;

        if (aircraftConfigurator.rwyStrengthSuitableMin !== null &&
            aircraftConfigurator.rwyStrengthSuitableMax !== null &&
            MTOWinPercents != null) {
            if (aircraftConfigurator.rwyStrengthSuitableMax === 100 &&
                MTOWinPercents > aircraftConfigurator.rwyStrengthSuitableMax) {
                result = true;
            }
            if (MTOWinPercents >= aircraftConfigurator.rwyStrengthSuitableMin &&
                MTOWinPercents <= aircraftConfigurator.rwyStrengthSuitableMax) {
                result = true;
            }
        }
        return result;
    }

    calcPercentsFromRunwayPCN(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): number {
        let result = null;
        if (tempRunway != null && tempRunway.runwayPcn != null) {

            let tempRunwayPcnArray = tempRunway.runwayPcn.split('/');

            for (let i = 0; i < tempRunwayPcnArray.length; i++) {
                if (tempRunwayPcnArray.length >= 3) {
                    let PCN = +tempRunwayPcnArray[0].trim();
                    if (tempRunwayPcnArray[1] === 'F') {
                        if (tempRunwayPcnArray[2] === 'A') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_F_A, aircraftConfigurator.acn_DOW_F_A, aircraftConfigurator);
                        }
                        if (tempRunwayPcnArray[2] === 'B') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_F_B, aircraftConfigurator.acn_DOW_F_B, aircraftConfigurator);
                        }
                        if (tempRunwayPcnArray[2] === 'C') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_F_C, aircraftConfigurator.acn_DOW_F_C, aircraftConfigurator);
                        }
                        if (tempRunwayPcnArray[2] === 'D') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_F_D, aircraftConfigurator.acn_DOW_F_D, aircraftConfigurator);
                        }
                    }
                    if (tempRunwayPcnArray[1] === 'R') {
                        if (tempRunwayPcnArray[2] === 'A') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_R_A, aircraftConfigurator.acn_DOW_R_A, aircraftConfigurator);
                        }
                        if (tempRunwayPcnArray[2] === 'B') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_R_B, aircraftConfigurator.acn_DOW_R_B, aircraftConfigurator);
                        }
                        if (tempRunwayPcnArray[2] === 'C') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_R_C, aircraftConfigurator.acn_DOW_R_C, aircraftConfigurator);
                        }
                        if (tempRunwayPcnArray[2] === 'D') {
                            result = this.calculateMTOWfromACNinPercents(PCN, aircraftConfigurator.acn_MTOW_R_D, aircraftConfigurator.acn_DOW_R_D, aircraftConfigurator);
                        }
                    }
                }
            }
        }
        return result;
    }

    calculateMTOWfromACNinPercents(PCN: number, ACN_MTOW: number, ACN_DOW: number, aircraftConfigurator: AircraftConfigurator): number {
        let MTOWfromACNinPercents = null;
        if (PCN != null && ACN_MTOW != null && ACN_DOW != null &&
            aircraftConfigurator.aircraftMTOW != null && aircraftConfigurator.aircraftDOW != null) {
            if (PCN < ACN_DOW) {
                let MTOWfromACN = (aircraftConfigurator.aircraftDOW / ACN_DOW) * PCN;
                MTOWfromACNinPercents = +((MTOWfromACN / aircraftConfigurator.aircraftMTOW) * 100).toFixed();
            } else if (PCN >= ACN_DOW) {
                let MTOWfromACN = (((aircraftConfigurator.aircraftMTOW - aircraftConfigurator.aircraftDOW) /
                    (ACN_MTOW - ACN_DOW)) * (PCN - ACN_DOW)) + aircraftConfigurator.aircraftDOW;

                MTOWfromACNinPercents = +((MTOWfromACN / aircraftConfigurator.aircraftMTOW) * 100).toFixed();
            }
        }
        return MTOWfromACNinPercents;
    }

    checkRestrRunwayPCN(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayPcn !== null) {
          result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayPCN(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    compareRestrMTOWwithConfiguratorMINandMAX(tmp: number, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        let MTOWinPercents = tmp;

        if (aircraftConfigurator.rwyStrengthSuitableRestrMin !== null &&
            aircraftConfigurator.rwyStrengthSuitableRestrMax !== null &&
            MTOWinPercents != null) {
            if (aircraftConfigurator.rwyStrengthSuitableRestrMax === 100 &&
                MTOWinPercents > aircraftConfigurator.rwyStrengthSuitableRestrMax) {
                result = true;
            }
            if (MTOWinPercents >= aircraftConfigurator.rwyStrengthSuitableRestrMin &&
                MTOWinPercents <= aircraftConfigurator.rwyStrengthSuitableRestrMax) {
                result = true;
            }
        }
        return result;
    }
    /*END. PCN*/

    /*BEGINNING. SW/DW/DT/DDT*/
    /* SW */
    checkSuiRunwaySW(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacitySW !== null) {
          result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwaySW(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    checkRestrRunwaySW(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacitySW !== null) {
          result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwaySW(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    calcPercentsFromRunwaySW(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): number {
        return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacitySW,
            aircraftConfigurator.mtow_SW, aircraftConfigurator.dow_SW, aircraftConfigurator);
    }
    /* END. SW */

    /* DW */
    checkSuiRunwayDW(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacityDW !== null) {
          result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDW(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    checkRestrRunwayDW(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacityDW !== null) {
          result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDW(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    calcPercentsFromRunwayDW(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): number {
        return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacityDW,
            aircraftConfigurator.mtow_DW, aircraftConfigurator.dow_DW, aircraftConfigurator);
    }
    /* END. DW */

    /* DT */
    checkSuiRunwayDT(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacityDT !== null) {
          result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDT(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    checkRestrRunwayDT(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacityDT !== null) {
          result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDT(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    calcPercentsFromRunwayDT(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): number {
        return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacityDT,
            aircraftConfigurator.mtow_DT, aircraftConfigurator.dow_DT, aircraftConfigurator);
    }
    /* END. DT */

    /* DDT */
    checkSuiRunwayDDT(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacityDDT !== null) {
          result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDDT(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    checkRestrRunwayDDT(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): boolean {
        let result = false;
        if (tempRunway.runwayWeightBearingCapacityDDT !== null) {
          result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDDT(tempRunway, aircraftConfigurator), aircraftConfigurator);
        }
        return result;
    }

    calcPercentsFromRunwayDDT(tempRunway: Runway, aircraftConfigurator: AircraftConfigurator): number {
        return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacityDDT,
            aircraftConfigurator.mtow_DDT, aircraftConfigurator.dow_DDT, aircraftConfigurator);
    }
        /* END. DDT */
    /*END. SW/DW/DT/DDT*/
    /*END.Get color for map*/
}

interface GetResponseAirports {
    _embedded: {
        airports: Airport[];
    }
}

interface GetResponseRunways {
    _embedded: {
        runways: Runway[];
    }
}

interface GetAircraftConfigurator {
    _embedded: {
        aircraftConfigurators: AircraftConfigurator[];
    }
}

interface GetResponseRemarks {
    _embedded: {
        remarks: Remark[];
    }
}