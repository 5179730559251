export class AircraftConfigurator {
  idConfigurator: number;
  
  username: string;
  created_date: Date;
  last_updated_date: Date;

  aircraftName: string;

  elevationSuitableMin: number;
  elevationSuitableMinMeters: number;
  elevationSuitableMax: number;
  elevationSuitableMaxMeters: number;
  elevationSuitableRestrMin: number;
  elevationSuitableRestrMinMeters: number;
  elevationSuitableRestrMax: number;
  elevationSuitableRestrMaxMeters: number;
  elevationNotSuitableMin: number;
  elevationNotSuitableMax: number;

  elevationSuitableRemark: string;
  elevationSuitableRestrRemark: string;

  turnOnOffMaxElevationAll: boolean;
  turnOnOffMaxElevationSuitable: boolean;
  turnOnOffMaxElevationRestr: boolean;
  turnOnOffMaxElevationUnSuitable: boolean;

  rwyLengthSuitableMin: number;
  rwyLengthSuitableMinMeters: number;
  rwyLengthSuitableMax: number;
  rwyLengthSuitableMaxMeters: number;
  rwyLengthSuitableRestrMin: number;
  rwyLengthSuitableRestrMinMeters: number;
  rwyLengthSuitableRestrMax: number;
  rwyLengthSuitableRestrMaxMeters: number;
  rwyLengthNotSuitableMin: number;
  rwyLengthNotSuitableMax: number;

  rwyLengthSuitableRemark: string;
  rwyLengthSuitableRestrRemark: string;

  turnOnOffMinRunwayLengthAll: boolean;
  turnOnOffMinRunwayLengthSuitable: boolean;
  turnOnOffMinRunwayLengthRestr: boolean;
  turnOnOffMinRunwayLengthUnSuitable: boolean;

  rwyWidthSuitableMin: number;
  rwyWidthSuitableMinMeters: number;
  rwyWidthSuitableMax: number;
  rwyWidthSuitableMaxMeters: number;
  rwyWidthSuitableRestrMin: number;
  rwyWidthSuitableRestrMinMeters: number;
  rwyWidthSuitableRestrMax: number;
  rwyWidthSuitableRestrMaxMeters: number;
  rwyWidthNotSuitableMin: number;
  rwyWidthNotSuitableMax: number;

  rwyWidthSuitableRemark: string;
  rwyWidthSuitableRestrRemark: string;

  turnOnOffMinRunwayWidthAll: boolean;
  turnOnOffMinRunwayWidthSuitable: boolean;
  turnOnOffMinRunwayWidthRestr: boolean;
  turnOnOffMinRunwayWidthUnSuitable: boolean;

  rwySlopeSuitableMin: number;
  rwySlopeSuitableMax: number;
  rwySlopeSuitableFirstRestrMin: number;
  rwySlopeSuitableFirstRestrMax: number;
  rwySlopeSuitableSecondRestrMin: number;
  rwySlopeSuitableSecondRestrMax: number;
  rwySlopeNotSuitableMin: number;
  rwySlopeNotSuitableMax: number;

  rwySlopeSuitableRemark: string;
  rwySlopeSuitableFirstRestrRemark: string;
  rwySlopeSuitableSecondRestrRemark: string;

  turnOnOffMaxSlopeAll: boolean;
  turnOnOffMaxSlopeSuitable: boolean;
  turnOnOffMaxSlopeFirstRestr: boolean;
  turnOnOffMaxSlopeSecondRestr: boolean;
  turnOnOffMaxSlopeUnSuitable: boolean;

  rwySurfaceSuitable: string;
  rwySurfaceSuitableRestr: string;
  rwySurfaceNotSuitable: string;

  rwySurfaceSuitableRemark: string;
  rwySurfaceSuitableRestrRemark: string;

  turnOnOffSurfaceSuitableAll: boolean;
  turnOnOffSurfaceSuitable: boolean;
  turnOnOffSurfaceSuitableRestr: boolean;
  turnOnOffSurfaceUnSuitable: boolean;

  rwyStrengthSuitableMin: number;
  rwyStrengthSuitableMax: number;
  rwyStrengthSuitableRestrMin: number;
  rwyStrengthSuitableRestrMax: number;
  rwyStrengthNotSuitableMin: number;
  rwyStrengthNotSuitableMax: number;

  rwyStrengthSuitableRemark: string;
  rwyStrengthSuitableRestRemark: string;

  turnOnOffMinRunwayStrengthAll: boolean;
  turnOnOffMinRunwayStrengthSuitable: boolean;
  turnOnOffMinRunwayStrengthRestr: boolean;
  turnOnOffMinRunwayStrengthUnSuitable: boolean;

  refCodeSuitableMin: string;
  refCodeSuitableMax: string;
  refCodeSuitableRestrMin: string;
  refCodeSuitableRestrMax: string;
  refCodeNotSuitableMin: string;
  refCodeNotSuitableMax: string;

  arffSuitableMin: number;
  arffSuitableMax: number;
  arffSuitableRestrMin: number;
  arffSuitableRestrMax: number;
  arffNotSuitableMin: number;
  arffNotSuitableMax: number;

  arffsuitableremark: string;
  arffsuitablerestrremark: string;

  turnOnOffMinARFFAll: boolean;
  turnOnOffMinARFFSuitable: boolean;
  turnOnOffMinARFFRestr: boolean;
  turnOnOffMinARFFUnSuitable: boolean;

  aircraftMTOW: number;
  aircraftMTOW_KG: number;

  aircraftDOW: number;
  aircraftDOW_KG: number;

  acn_MTOW_F_A: number;
  acn_MTOW_F_B: number;
  acn_MTOW_F_C: number;
  acn_MTOW_F_D: number;

  acn_MTOW_R_A: number;
  acn_MTOW_R_B: number;
  acn_MTOW_R_C: number;
  acn_MTOW_R_D: number;

  acn_DOW_F_A: number;
  acn_DOW_F_B: number;
  acn_DOW_F_C: number;
  acn_DOW_F_D: number;

  acn_DOW_R_A: number;
  acn_DOW_R_B: number;
  acn_DOW_R_C: number;
  acn_DOW_R_D: number;

  mtow_SW: number;
  mtow_DW: number;
  mtow_DT: number;
  mtow_DDT: number;

  dow_SW: number;
  dow_DW: number;
  dow_DT: number;
  dow_DDT: number;

  rwySurfaceSuitableArray: string[];
  rwySurfaceSuitableRestrArray: string[];

  constructor(
    idConfigurator: number,

    username: string,
    created_date: Date,
    last_updated_date: Date,

    aircraftName: string,

    elevationSuitableMin: number,
    elevationSuitableMinMeters: number,
    elevationSuitableMax: number,
    elevationSuitableMaxMeters: number,
    elevationSuitableRestrMin: number,
    elevationSuitableRestrMinMeters: number,
    elevationSuitableRestrMax: number,
    elevationSuitableRestrMaxMeters: number,
    elevationNotSuitableMin: number,
    elevationNotSuitableMax: number,

    elevationSuitableRemark: string,
    elevationSuitableRestrRemark: string,

    turnOnOffMaxElevationAll: boolean,
    turnOnOffMaxElevationSuitable: boolean,
    turnOnOffMaxElevationRestr: boolean,
    turnOnOffMaxElevationUnSuitable: boolean,

    rwyLengthSuitableMin: number,
    rwyLengthSuitableMinMeters: number,
    rwyLengthSuitableMax: number,
    rwyLengthSuitableMaxMeters: number,
    rwyLengthSuitableRestrMin: number,
    rwyLengthSuitableRestrMinMeters: number,
    rwyLengthSuitableRestrMax: number,
    rwyLengthSuitableRestrMaxMeters: number,
    rwyLengthNotSuitableMin: number,
    rwyLengthNotSuitableMax: number,

    rwyLengthSuitableRemark: string,
    rwyLengthSuitableRestrRemark: string,

    turnOnOffMinRunwayLengthAll: boolean,
    turnOnOffMinRunwayLengthSuitable: boolean,
    turnOnOffMinRunwayLengthRestr: boolean,
    turnOnOffMinRunwayLengthUnSuitable: boolean,

    rwyWidthSuitableMin: number,
    rwyWidthSuitableMinMeters: number,
    rwyWidthSuitableMax: number,
    rwyWidthSuitableMaxMeters: number,
    rwyWidthSuitableRestrMin: number,
    rwyWidthSuitableRestrMinMeters: number,
    rwyWidthSuitableRestrMax: number,
    rwyWidthSuitableRestrMaxMeters: number,
    rwyWidthNotSuitableMin: number,
    rwyWidthNotSuitableMax: number,

    rwyWidthSuitableRemark: string,
    rwyWidthSuitableRestrRemark: string,

    turnOnOffMinRunwayWidthAll: boolean,
    turnOnOffMinRunwayWidthSuitable: boolean,
    turnOnOffMinRunwayWidthRestr: boolean,
    turnOnOffMinRunwayWidthUnSuitable: boolean,

    rwySlopeSuitableMin: number,
    rwySlopeSuitableMax: number,
    rwySlopeSuitableFirstRestrMin: number,
    rwySlopeSuitableFirstRestrMax: number,
    rwySlopeSuitableSecondRestrMin: number,
    rwySlopeSuitableSecondRestrMax: number,
    rwySlopeNotSuitableMin: number,
    rwySlopeNotSuitableMax: number,

    rwySlopeSuitableRemark: string,
    rwySlopeSuitableFirstRestrRemark: string,
    rwySlopeSuitableSecondRestrRemark: string,
  
    turnOnOffMaxSlopeAll: boolean,
    turnOnOffMaxSlopeSuitable: boolean,
    turnOnOffMaxSlopeFirstRestr: boolean,
    turnOnOffMaxSlopeSecondRestr: boolean,
    turnOnOffMaxSlopeUnSuitable: boolean,

    rwySurfaceSuitable: string,
    rwySurfaceSuitableRestr: string,
    rwySurfaceNotSuitable: string,

    rwySurfaceSuitableRemark: string,
    rwySurfaceSuitableRestrRemark: string,

    turnOnOffSurfaceSuitableAll: boolean,
    turnOnOffSurfaceSuitable: boolean,
    turnOnOffSurfaceSuitableRestr: boolean,
    turnOnOffSurfaceUnSuitable: boolean,

    rwyStrengthSuitableMin: number,
    rwyStrengthSuitableMax: number,
    rwyStrengthSuitableRestrMin: number,
    rwyStrengthSuitableRestrMax: number,
    rwyStrengthNotSuitableMin: number,
    rwyStrengthNotSuitableMax: number,

    rwyStrengthSuitableRemark: string,
    rwyStrengthSuitableRestRemark: string,
  
    turnOnOffMinRunwayStrengthAll: boolean,
    turnOnOffMinRunwayStrengthSuitable: boolean,
    turnOnOffMinRunwayStrengthRestr: boolean,
    turnOnOffMinRunwayStrengthUnSuitable: boolean,

    refCodeSuitableMin: string,
    refCodeSuitableMax: string,
    refCodeSuitableRestrMin: string,
    refCodeSuitableRestrMax: string,
    refCodeNotSuitableMin: string,
    refCodeNotSuitableMax: string,

    arffSuitableMin: number,
    arffSuitableMax: number,
    arffSuitableRestrMin: number,
    arffSuitableRestrMax: number,
    arffNotSuitableMin: number,
    arffNotSuitableMax: number,

    arffsuitableremark: string,
    arffsuitablerestrremark: string,
  
    turnOnOffMinARFFAll: boolean,
    turnOnOffMinARFFSuitable: boolean,
    turnOnOffMinARFFRestr: boolean,
    turnOnOffMinARFFUnSuitable: boolean,

    aircraftMTOW: number,
    aircraftMTOW_KG: number,

    aircraftDOW: number,
    aircraftDOW_KG: number,

    acn_MTOW_F_A: number,
    acn_MTOW_F_B: number,
    acn_MTOW_F_C: number,
    acn_MTOW_F_D: number,

    acn_MTOW_R_A: number,
    acn_MTOW_R_B: number,
    acn_MTOW_R_C: number,
    acn_MTOW_R_D: number,

    acn_DOW_F_A: number,
    acn_DOW_F_B: number,
    acn_DOW_F_C: number,
    acn_DOW_F_D: number,

    acn_DOW_R_A: number,
    acn_DOW_R_B: number,
    acn_DOW_R_C: number,
    acn_DOW_R_D: number,

    mtow_SW: number,
    mtow_DW: number,
    mtow_DT: number,
    mtow_DDT: number,

    dow_SW: number,
    dow_DW: number,
    dow_DT: number,
    dow_DDT: number
  ) {
    this.idConfigurator = idConfigurator;

    this.username = username;
    this.created_date = created_date;
    this.last_updated_date = last_updated_date;

    this.aircraftName = aircraftName;

    this.elevationSuitableMin = elevationSuitableMin;
    this.elevationSuitableMinMeters = elevationSuitableMinMeters;
    this.elevationSuitableMax = elevationSuitableMax;
    this.elevationSuitableMaxMeters = elevationSuitableMaxMeters;
    this.elevationSuitableRestrMin = elevationSuitableRestrMin;
    this.elevationSuitableRestrMinMeters = elevationSuitableRestrMinMeters;
    this.elevationSuitableRestrMax = elevationSuitableRestrMax;
    this.elevationSuitableRestrMaxMeters = elevationSuitableRestrMaxMeters;
    this.elevationNotSuitableMin = elevationNotSuitableMin;
    this.elevationNotSuitableMax = elevationNotSuitableMax;

    this.elevationSuitableRemark = elevationSuitableRemark;
    this.elevationSuitableRestrRemark = elevationSuitableRestrRemark;

    this.turnOnOffMaxElevationAll = turnOnOffMaxElevationAll;
    this.turnOnOffMaxElevationSuitable = turnOnOffMaxElevationSuitable;
    this.turnOnOffMaxElevationRestr = turnOnOffMaxElevationRestr;
    this.turnOnOffMaxElevationUnSuitable = turnOnOffMaxElevationUnSuitable;

    this.rwyLengthSuitableMin = rwyLengthSuitableMin;
    this.rwyLengthSuitableMinMeters = rwyLengthSuitableMinMeters;
    this.rwyLengthSuitableMax = rwyLengthSuitableMax;
    this.rwyLengthSuitableMaxMeters = rwyLengthSuitableMaxMeters;
    this.rwyLengthSuitableRestrMin = rwyLengthSuitableRestrMin;
    this.rwyLengthSuitableRestrMinMeters = rwyLengthSuitableRestrMinMeters;
    this.rwyLengthSuitableRestrMax = rwyLengthSuitableRestrMax;
    this.rwyLengthSuitableRestrMaxMeters = rwyLengthSuitableRestrMaxMeters;
    this.rwyLengthNotSuitableMin = rwyLengthNotSuitableMin;
    this.rwyLengthNotSuitableMax = rwyLengthNotSuitableMax;

    this.rwyLengthSuitableRemark = rwyLengthSuitableRemark;
    this.rwyLengthSuitableRestrRemark = rwyLengthSuitableRestrRemark;
    
    this.turnOnOffMinRunwayLengthAll = turnOnOffMinRunwayLengthAll;
    this.turnOnOffMinRunwayLengthSuitable = turnOnOffMinRunwayLengthSuitable;
    this.turnOnOffMinRunwayLengthRestr = turnOnOffMinRunwayLengthRestr;
    this.turnOnOffMinRunwayLengthUnSuitable = turnOnOffMinRunwayLengthUnSuitable;

    this.rwyWidthSuitableMin = rwyWidthSuitableMin;
    this.rwyWidthSuitableMinMeters = rwyWidthSuitableMinMeters;
    this.rwyWidthSuitableMax = rwyWidthSuitableMax;
    this.rwyWidthSuitableMaxMeters = rwyWidthSuitableMaxMeters;
    this.rwyWidthSuitableRestrMin = rwyWidthSuitableRestrMin;
    this.rwyWidthSuitableRestrMinMeters = rwyWidthSuitableRestrMinMeters;
    this.rwyWidthSuitableRestrMax = rwyWidthSuitableRestrMax;
    this.rwyWidthSuitableRestrMaxMeters = rwyWidthSuitableRestrMaxMeters;
    this.rwyWidthNotSuitableMin = rwyWidthNotSuitableMin;
    this.rwyWidthNotSuitableMax = rwyWidthNotSuitableMax;

    this.rwyWidthSuitableRemark = rwyWidthSuitableRemark;
    this.rwyWidthSuitableRestrRemark = rwyWidthSuitableRestrRemark;

    this.turnOnOffMinRunwayWidthAll = turnOnOffMinRunwayWidthAll;
    this.turnOnOffMinRunwayWidthSuitable = turnOnOffMinRunwayWidthSuitable;
    this.turnOnOffMinRunwayWidthRestr = turnOnOffMinRunwayWidthRestr;
    this.turnOnOffMinRunwayWidthUnSuitable = turnOnOffMinRunwayWidthUnSuitable;
    
    this.rwySlopeSuitableMin = rwySlopeSuitableMin;
    this.rwySlopeSuitableMax = rwySlopeSuitableMax;
    this.rwySlopeSuitableMin = rwySlopeSuitableMin;
    this.rwySlopeSuitableMax = rwySlopeSuitableMax;
    this.rwySlopeSuitableFirstRestrMin = rwySlopeSuitableFirstRestrMin;
    this.rwySlopeSuitableFirstRestrMax = rwySlopeSuitableFirstRestrMax;
    this.rwySlopeSuitableSecondRestrMin = rwySlopeSuitableSecondRestrMin;
    this.rwySlopeSuitableSecondRestrMax = rwySlopeSuitableSecondRestrMax;
    this.rwySlopeNotSuitableMin = rwySlopeNotSuitableMin;
    this.rwySlopeNotSuitableMax = rwySlopeNotSuitableMax;

    this.rwySlopeSuitableRemark = rwySlopeSuitableRemark;
    this.rwySlopeSuitableFirstRestrRemark = rwySlopeSuitableFirstRestrRemark;
    this.rwySlopeSuitableSecondRestrRemark = rwySlopeSuitableSecondRestrRemark;

    this.turnOnOffMaxSlopeAll = turnOnOffMaxSlopeAll;
    this.turnOnOffMaxSlopeSuitable = turnOnOffMaxSlopeSuitable;
    this.turnOnOffMaxSlopeFirstRestr = turnOnOffMaxSlopeFirstRestr;
    this.turnOnOffMaxSlopeSecondRestr = turnOnOffMaxSlopeSecondRestr;
    this.turnOnOffMaxSlopeUnSuitable = turnOnOffMaxSlopeUnSuitable;

    this.rwySurfaceSuitable = rwySurfaceSuitable;
    this.rwySurfaceSuitableRestr = rwySurfaceSuitableRestr;
    this.rwySurfaceNotSuitable = rwySurfaceNotSuitable;

    this.rwySurfaceSuitableRemark = rwySurfaceSuitableRemark;
    this.rwySurfaceSuitableRestrRemark = rwySurfaceSuitableRestrRemark;

    this.turnOnOffSurfaceSuitableAll = turnOnOffSurfaceSuitableAll;
    this.turnOnOffSurfaceSuitable = turnOnOffSurfaceSuitable;
    this.turnOnOffSurfaceSuitableRestr = turnOnOffSurfaceSuitableRestr;
    this.turnOnOffSurfaceUnSuitable = turnOnOffSurfaceUnSuitable;
    
    this.rwyStrengthSuitableMin = rwyStrengthSuitableMin;
    this.rwyStrengthSuitableMax = rwyStrengthSuitableMax;
    this.rwyStrengthSuitableRestrMin = rwyStrengthSuitableRestrMin;
    this.rwyStrengthSuitableRestrMax = rwyStrengthSuitableRestrMax;
    this.rwyStrengthNotSuitableMin = rwyStrengthNotSuitableMin;
    this.rwyStrengthNotSuitableMax = rwyStrengthNotSuitableMax;

    this.rwyStrengthSuitableRemark = rwyStrengthSuitableRemark;
    this.rwyStrengthSuitableRestRemark = rwyStrengthSuitableRestRemark;
  
    this.turnOnOffMinRunwayStrengthAll = turnOnOffMinRunwayStrengthAll;
    this.turnOnOffMinRunwayStrengthSuitable = turnOnOffMinRunwayStrengthSuitable;
    this.turnOnOffMinRunwayStrengthRestr = turnOnOffMinRunwayStrengthRestr;
    this.turnOnOffMinRunwayStrengthUnSuitable = turnOnOffMinRunwayStrengthUnSuitable;
    
    this.refCodeSuitableMin = refCodeSuitableMin;
    this.refCodeSuitableMax = refCodeSuitableMax;
    this.refCodeSuitableRestrMin = refCodeSuitableRestrMin;
    this.refCodeSuitableRestrMax = refCodeSuitableRestrMax;
    this.refCodeNotSuitableMin = refCodeNotSuitableMin;
    this.refCodeNotSuitableMax = refCodeNotSuitableMax;

    this.arffSuitableMin = arffSuitableMin;
    this.arffSuitableMax = arffSuitableMax;
    this.arffSuitableRestrMin = arffSuitableRestrMin;
    this.arffSuitableRestrMax = arffSuitableRestrMax;
    this.arffNotSuitableMin = arffNotSuitableMin;
    this.arffNotSuitableMax = arffNotSuitableMax;

    this.arffsuitableremark = arffsuitableremark;
    this.arffsuitablerestrremark = arffsuitablerestrremark;
    
    this.turnOnOffMinARFFAll = turnOnOffMinARFFAll;
    this.turnOnOffMinARFFSuitable = turnOnOffMinARFFSuitable;
    this.turnOnOffMinARFFRestr = turnOnOffMinARFFRestr;
    this.turnOnOffMinARFFUnSuitable = turnOnOffMinARFFUnSuitable;

    this.aircraftMTOW = aircraftMTOW;
    this.aircraftMTOW_KG = aircraftMTOW_KG;

    this.aircraftDOW = aircraftDOW;
    this.aircraftDOW_KG = aircraftDOW_KG;

    this.acn_MTOW_F_A = acn_MTOW_F_A;
    this.acn_MTOW_F_B = acn_MTOW_F_B;
    this.acn_MTOW_F_C = acn_MTOW_F_C;
    this.acn_MTOW_F_D = acn_MTOW_F_D;

    this.acn_MTOW_R_A = acn_MTOW_R_A;
    this.acn_MTOW_R_B = acn_MTOW_R_B;
    this.acn_MTOW_R_C = acn_MTOW_R_C;
    this.acn_MTOW_R_D = acn_MTOW_R_D;

    this.acn_DOW_F_A = acn_DOW_F_A;
    this.acn_DOW_F_B = acn_DOW_F_B;
    this.acn_DOW_F_C = acn_DOW_F_C;
    this.acn_DOW_F_D = acn_DOW_F_D;

    this.acn_DOW_R_A = acn_DOW_R_A;
    this.acn_DOW_R_B = acn_DOW_R_B;
    this.acn_DOW_R_C = acn_DOW_R_C;
    this.acn_DOW_R_D = acn_DOW_R_D;

    this.mtow_SW = mtow_SW;
    this.mtow_DW = mtow_DW;
    this.mtow_DT = mtow_DT;
    this.mtow_DDT = mtow_DDT;

    this.dow_SW = dow_SW;
    this.dow_DW = dow_DW;
    this.dow_DT = dow_DT;
    this.dow_DDT = dow_DDT;
  }
}
