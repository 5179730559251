import { Component, OnInit } from '@angular/core';
import { AirportService } from 'src/app/services/airport.service';
import { ActivatedRoute } from '@angular/router';
import { Airport } from 'src/app/common/airport';

@Component({
  selector: 'app-airport-list',
  templateUrl: './airport-list.component.html',
  styleUrls: ['./airport-list.component.css']
})
export class AirportListComponent implements OnInit {
  airports: Airport[];
  searchMode: boolean;

  constructor(private airportService: AirportService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(() => {
      this.listAirports();
    });
  }

  listAirports() {
    this.searchMode = this.route.snapshot.paramMap.has('keyword');
    
    if (this.searchMode){
      this.handleSearchAirports();
    } else {
      this.airportService.getAirportList().subscribe(
        data => {
          this.airports = data;
        }
      );
    }
  }

  handleSearchAirports() {
    const theKeyword: string = this.route.snapshot.paramMap.get('keyword');

    //now search for the airport using keyword
    this.airportService.searchAirports(theKeyword).subscribe(
      data => {
        this.airports = data;
      }
    )
  }

}
