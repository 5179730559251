import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feetsToMeters'
})
export class FeetsToMetersPipe implements PipeTransform {
  transform(value: number, ...args: any[]): number {
    return +(value*0.3048).toFixed(1);
  }
}
