import { Pipe, PipeTransform } from '@angular/core';
import { Runway } from 'src/app/common/runway';

@Pipe({
  name: 'coordinatesNotNull'
})
export class CoordinatesNotNullPipe implements PipeTransform {

  transform(runways: Runway[]): any[] {
    if (runways) {
      return runways.filter(runway => runway.baseEndPhysicalLatitudeMap);
    } else {
      return runways;
    }
  }
}
