import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { HeaderType } from 'src/app/enum/header-type.enum';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { CustomHttpResponse } from 'src/app/model/custom-http-response';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})

export class ResetpasswordComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  public refreshing = false;
  private subscriptions: Subscription[] = [];
  emailSearchResults = false;

  streamCheckUsername$: Observable<Boolean>;
  streamCheckEmail$: Observable<Boolean>;

  private searchTermsCheckUsername$ = new Subject<string>();
  private searchTermsCheckEmail$ = new Subject<string>();
  private userWasSuccessfullyCreated = false;
  
  constructor(private router: Router, 
              private authenticationService: AuthenticationService,
              private notificationService: NotificationService, 
              private userService: UserService) {}

  ngOnInit(): void {
    this.streamCheckUsername$ = this.searchTermsCheckUsername$.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.userService.checkIsUsernameFree(term))
    );

    this.streamCheckEmail$ = this.searchTermsCheckEmail$.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.userService.checkIsEmailFree(term))
    );
  }

   // Push a search term into the observable stream.
    doSearchEmail(term: string): void {
      let regexp = new RegExp('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}');
      let test = regexp.test(term);
      
      if (test === true) {
        this.searchTermsCheckEmail$.next(term);
        this.emailSearchResults = true;
      } else {
        this.emailSearchResults = false;
      }
    }

  public onResetPassword(emailForm: NgForm): void {
    this.refreshing = true;
    const emailAddress = emailForm.value['resetpasswordemail'];
    this.subs.add(
      this.userService.resetPassword(emailAddress).subscribe(
        (response: CustomHttpResponse) => {
          this.sendNotification(NotificationType.SUCCESS, response.message);
          this.refreshing = false;
        },
        (error: HttpErrorResponse) => {
          this.sendNotification(NotificationType.WARNING, error.error.message);
          this.refreshing = false;
        },
        () => emailForm.reset()
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}