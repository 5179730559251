import { AfterViewChecked } from '@angular/core';
import { AfterContentInit } from '@angular/core';
import { Component, AfterViewInit, Input, OnChanges, SimpleChanges, OnInit, DoCheck, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as L from 'leaflet';
import * as L1 from 'leaflet.markercluster';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { AircraftConfigurator } from 'src/app/common/aircraftConfigurator';
import { Airport } from 'src/app/common/airport';
import { Runway } from 'src/app/common/runway';
import { MarkerService } from 'src/app/services/marker.service';
import { __values } from 'tslib';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements OnChanges {
  private map;
  runwayLines = new L.LayerGroup();
  runwayMarkes = new L1.MarkerClusterGroup();

  @Input() airportChild: Airport;
  @Input() runwaysChild: Runway[];
  @Input() aircraftConfiguratorChild: AircraftConfigurator;
  @Input() aircraftConfiguratorChangedChild: boolean;
  @Input() switchConfiguratorStatusChild: boolean;

  constructor(private markerService: MarkerService, private route: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['airportChild']) {
      this.initMap();
      this.markerService.makeAirportMarker(this.map, this.airportChild);
    }

    if (changes['runwaysChild']) {
      this.drawMarkers();
      this.drawRunways();
    }

    if (changes['aircraftConfiguratorChangedChild']) {
      this.drawRunways();
    }
    if (changes['switchConfiguratorStatusChild']) {
        this.drawRunways();
      }
  }

  drawRunways() {
    this.runwayLines = this.markerService.makeRunways(this.runwaysChild, this.aircraftConfiguratorChild, this.airportChild, this.switchConfiguratorStatusChild);

    if (this.runwayLines !=undefined) {
      this.map.addLayer(this.runwayLines);
      const localMap = this.map;
      let localRunwayLines = this.runwayLines;
      let runwaysDownload;

      if (runwaysDownload === false) {
        localRunwayLines = this.markerService.makeRunways(this.runwaysChild, this.aircraftConfiguratorChild, this.airportChild, this.switchConfiguratorStatusChild);
      }

      if (this.map.getZoom() <= 12) {
        if (localMap.hasLayer(localRunwayLines)) {
          localMap.removeLayer(localRunwayLines);
          runwaysDownload = false;
        }
      }

      this.map.on('zoomend', function (event) {
        if (event.target._zoom <= 12) {
          if (localMap.hasLayer(localRunwayLines)) {
            localMap.removeLayer(localRunwayLines);
            runwaysDownload = false;
          }
        }
        if (event.target._zoom > 12 && !localMap.hasLayer(localRunwayLines)) {
          if (localRunwayLines) {
            localMap.addLayer(localRunwayLines);
            runwaysDownload = true;
          }
        }
      });
    }
  }

  drawMarkers() {
    this.runwayMarkes = this.markerService.makeRunwaysMarkers(this.runwaysChild);
    if (this.runwayMarkes !=undefined) {
      this.map.addLayer(this.runwayMarkes);
      const localMap = this.map;
      let localRunwayMarkes = this.runwayMarkes;
      let markersDownload;

      if (markersDownload === false) {
        localRunwayMarkes = this.markerService.makeRunwaysMarkers(this.runwaysChild);
      }

      this.map.on('zoomend', function (event) {
        if (event.target._zoom <= 12) {
          if (localMap.hasLayer(localRunwayMarkes)) {
            localMap.removeLayer(localRunwayMarkes);
            markersDownload = false;
          }
        }
        if (event.target._zoom > 12 && !localMap.hasLayer(localRunwayMarkes)) {
          if (localRunwayMarkes) {
            localMap.addLayer(localRunwayMarkes);
            markersDownload = true;
          }
        }
      });
    }
  }

  private initMap(): void {
    if (this.map != undefined) {
      this.map.off();
      this.map.remove();
    }

    this.map = L.map('map', {
      center: [this.airportChild.latitudeMap, this.airportChild.longitudeMap],
      zoom: 14
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }
}