import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, Observable, Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { CustomHttpResponse } from 'src/app/model/custom-http-response';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { FileUploadStatus } from 'src/app/model/file-upload.status';
import { Role } from 'src/app/enum/role.enum';
import { SubSink } from 'subsink';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private titleSubject = new BehaviorSubject<string>('Users');
  public titleAction$ = this.titleSubject.asObservable();
  public users: User[];
  public user: User;
  public refreshingSaveChanges: boolean;
  public refreshingCancelChanges: boolean;

  public selectedUser: User;
  public fileName: string;
  public profileImage: File;
  private subscriptions: Subscription[] = [];
  public editUser = new User();
  private currentUsername: string;
  public fileStatus = new FileUploadStatus();
  public logInMenu = false;
  public loginButtonClicked = false;
  public showUserDetails = true;
  public showUserSettings = false;
 
  streamCheckUsername$: Observable<Boolean>;
  streamCheckEmail$: Observable<Boolean>;

  private searchTermsCheckUsername$ = new Subject<string>();
  private searchTermsCheckEmail$ = new Subject<string>();
  emailSearchResults = false;
  usernameSearchResults = false;
  originalUsername: string;

  constructor(private router: Router, 
              private authenticationService: AuthenticationService,
              private notificationService: NotificationService,
              private userService: UserService) {}

  ngOnInit(): void {
    if (this.authenticationService.isUserLoggedIn()){
      this.user = this.authenticationService.getUserFromLocalCache();
      this.originalUsername = this.user.username;
    }
    this.streamCheckUsername$ = this.searchTermsCheckUsername$.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.userService.checkIsUsernameFree(term))
    );
    this.streamCheckEmail$ = this.searchTermsCheckEmail$.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.userService.checkIsEmailFree(term))
    );
  }

  onCancelChanges() {
    this.refreshingCancelChanges = true;
    if (this.authenticationService.isUserLoggedIn()){
      this.user = this.authenticationService.getUserFromLocalCache();
      this.originalUsername = this.user.username;
    }
    this.refreshingCancelChanges = false;
  }

  // Push a search term into the observable stream.
  doSearchUsername(term: string): void {
    let regexp = new RegExp('[a-zA-Z0-9.-_\S+]*');
    let test = regexp.test(term);

    if (test === true && !term.includes(' ') && term !== null && term !== '') {

      this.searchTermsCheckUsername$.next(term);
      this.usernameSearchResults = true;
    } else {
      this.usernameSearchResults = false;
    }
  }

  // Push a search term into the observable stream.
  doSearchEmail(term: string): void {
    let regexp = new RegExp('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}');
    let test = regexp.test(term);
    
    if (test === true) {
      this.searchTermsCheckEmail$.next(term);
      this.emailSearchResults = true;
    } else {
      this.emailSearchResults = false;
    }
  }

  onClickUserDetails() {
    this.showUserDetails = true;
    this.showUserSettings = false;
  }

  onClickSettings() {
    this.showUserSettings = true;
    this.showUserDetails = false;
  }

  public onProfileImageChange(fileName: string, profileImage: File): void {
    this.fileName =  fileName;
    this.profileImage = profileImage;
  }

  public onUpdateCurrentUser(user: User): void {
    this.refreshingSaveChanges = true;
    this.currentUsername = this.authenticationService.getUserFromLocalCache().username;
    const formData = this.userService.createUserFormData(this.currentUsername, user, this.profileImage);

    this.subs.add(
      this.userService.updateUser(formData).subscribe(
        (response: User) => {
          this.authenticationService.addUserToLocalCache(response);
          this.originalUsername = this.user.username;
          this.refreshingSaveChanges = false;
          this.fileName = null;
          this.profileImage = null;
          this.sendNotification(NotificationType.SUCCESS, `${response.firstName} ${response.lastName} updated successfully`);
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshingSaveChanges = false;
          this.profileImage = null;
        }
      )
      );
  }

  public onUpdateProfileImage(): void {
    const formData = new FormData();
    formData.append('username', this.user.username);
    formData.append('profileImage', this.profileImage);

    this.subs.add(
      this.userService.updateProfileImage(formData).subscribe(
        (event: HttpEvent<any>) => {
          this.reportUploadProgress(event);
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.fileStatus.status = 'done';
        }
      )
    );
  }

  private reportUploadProgress(event: HttpEvent<any>): void {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        this.fileStatus.percentage = Math.round(100 * event.loaded / event.total);
        this.fileStatus.status = 'progress';
        break;
      case HttpEventType.Response:
        if (event.status === 200) {
          this.user.profileImageUrl = `${event.body.profileImageUrl}?time=${new Date().getTime()}`;
          this.sendNotification(NotificationType.SUCCESS, `${event.body.firstName}\'s profile image updated successfully`);
          this.fileStatus.status = 'done';
          break;
        } else {
          this.sendNotification(NotificationType.ERROR, `Unable to upload image. Please try again`);
          break;
        }
      default:
        `Finished all processes`;
    }
  }

  public updateProfileImage(): void {
    this.clickButton('profile-image-input');
  }

  public onLogOut(): void {
    this.authenticationService.logOut();
    this.router.navigate(['/signin']);
    this.sendNotification(NotificationType.SUCCESS, `You've been successfully Signed Out`);
  }

  public onUpdatePassword(updateForm: NgForm): void {
    this.refreshingSaveChanges = true;
    const currentUsername = this.authenticationService.getUserFromLocalCache().username;
    const oldPassword = updateForm.value['password'];
    const newPassword = updateForm.value['newPassword'];
    const formData = this.userService.updatePasswordFormData(currentUsername, oldPassword, newPassword);
    
    this.subs.add(
      this.userService.updatePassword(formData).subscribe(
        (response: User) => {
          this.authenticationService.addUserToLocalCache(response);
          this.originalUsername = this.user.username;

          this.refreshingSaveChanges = false;
          this.sendNotification(NotificationType.SUCCESS, `Password updated successfully`);
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshingSaveChanges = false;
        }
      )
      );
  }

  public get isAdmin(): boolean {
    return this.getUserRole() === Role.ADMIN || this.getUserRole() === Role.SUPER_ADMIN;
  }

  public get isManager(): boolean {
    return this.isAdmin || this.getUserRole() === Role.MANAGER;
  }

  public get isAdminOrManager(): boolean {
    return this.isAdmin || this.isManager;
  }

  private getUserRole(): string {
    return this.authenticationService.getUserFromLocalCache().role;
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  private clickButton(buttonId: string): void {
    document.getElementById(buttonId).click();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
