import { Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Airport } from 'src/app/common/airport';
import { AirportService } from 'src/app/services/airport.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Runway } from 'src/app/common/runway';
import { ThrowStmt } from '@angular/compiler';
import { AircraftConfigurator } from 'src/app/common/aircraftConfigurator';
import { stringify } from 'querystring';
import { forkJoin, merge, Observable, Subject } from 'rxjs';
import { Remark } from 'src/app/common/remark';
import { FormControl } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { mergeMap, switchMap } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { User } from 'src/app/model/user';
import { ChangeContext, Options } from "@angular-slider/ngx-slider";
import * as L from 'leaflet';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import 'rxjs/add/operator/filter';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-airport-details',
  templateUrl: './airport-details.component.html',
  styleUrls: ['./airport-details.component.css']
})

export class AirportDetailsComponent implements OnInit {
  testString = "testTest";
  airport: Airport;
  allRunways: Runway[];
  allRemarks: Remark[];
  numbersOfRemarks: number;
  runways: Runway[];
  numbersOfRunways: number;
  helipads: Runway[];
  numbersOfHelipads: number;
  aircraftConfigurators$: Observable<AircraftConfigurator[]>;
  aircraftConfigurators: AircraftConfigurator[];

  switchConfiguratorStatus = true;

  showMainWindow = true;
  showHandlingWindow = false;
  showFuelingWindow = false;
  showAircraftConfiguratorWindow = false;

  showRWYwindow = true;
  showHelipadsWindow = false;
  showRemarksWindow = false;

  showOverview = true;
  showDeclaredDistances = false;
  showMarkingAndLighs = false;

  /* Begining of Constructor*/
  temp = 'E';
  aircraftConfiguratorID = 1;
  previousAircraftConfiguratorID = 3;

  tmpAircraftConfigurators: AircraftConfigurator[];
  aircraftConfigurator = new AircraftConfigurator(
    0, null, null, null, null, null, null, null, null, null, null, null, null, 
    null, null, null, null, 
    false, false, false, false, 
    null, null, null, null, null, null, null, null, null, null, null, null,
    false, false, false, false,
    null, null, null, null, null, null, null, null, null, null, null, null,
    false, false, false, false,
    null, null, null, null, null, null, null, null, null, null, null, 
    false, false, false, false, false, 
    null, null, null, null, null, 
    false, false, false, false,
    null, null, null, null, null, null, null, null, 
    false, false, false, false,
    null, null, null, null, null, null,
    null, null, null, null, null, null, null, null, 
    false, false, false, false,
    null, null, null, null, null, null, 
    null, null, null, null, null, null, null, null, null, null, null, null,
    null, null, null, null, null, null, null, null, null, null);

  rwySurfaceSuitableAsphaltAndConcrete = false;
  rwySurfaceSuitableASPH = false;
  rwySurfaceSuitableASPHTRTD = false;
  rwySurfaceSuitableCONC = false;
  rwySurfaceSuitableCONCTRTD = false;
  rwySurfaceSuitableASPHCONC = false;

  rwySurfaceSuitableGravel = false;
  rwySurfaceSuitableGRVL = false;
  rwySurfaceSuitableGRVLTRTD = false;
  rwySurfaceSuitableASPHGRVL = false;
  rwySurfaceSuitableCONCGRVL = false;
  rwySurfaceSuitableGRVLDIRT = false;
  rwySurfaceSuitableGRVLTURF = false;
  rwySurfaceSuitableCORAL = false;

  rwySurfaceSuitableALLDIRT = false;
  rwySurfaceSuitableDIRT = false;
  rwySurfaceSuitableDIRTTRTD = false;
  rwySurfaceSuitableASPHDIRT = false;
  rwySurfaceSuitableDIRTGRVL = false;
  rwySurfaceSuitableDIRTTURF = false;
  rwySurfaceSuitableCALICHE = false;

  rwySurfaceSuitableALLTURF = false;
  rwySurfaceSuitableTURF = false;
  rwySurfaceSuitableASPHTURF = false;
  rwySurfaceSuitableCONCTURF = false;
  rwySurfaceSuitableTURFGRVL = false;
  rwySurfaceSuitableTURFDIRT = false;
  rwySurfaceSuitableTURFSAND = false;
  rwySurfaceSuitableOILCHIPT = false;
  rwySurfaceSuitableSOD = false;
  rwySurfaceSuitableGRASS = false;

  rwySurfaceSuitableSnowAndIce = false;
  rwySurfaceSuitableSNOW = false;
  rwySurfaceSuitableICE = false;

  rwySurfaceSuitableAllWater = false;
  rwySurfaceSuitableWATER = false;

  rwySurfaceSuitableOther = false;

  rwySurfaceSuitableALUMINUM = false;
  rwySurfaceSuitableBRICK = false;
  rwySurfaceSuitableDECK = false;
  rwySurfaceSuitableMATS = false;
  rwySurfaceSuitableMETAL = false;

  rwySurfaceSuitableROOFTOP = false;
  rwySurfaceSuitableSAND = false;
  rwySurfaceSuitableSTEEL = false;
  rwySurfaceSuitableSTEELCONC = false;
  rwySurfaceSuitableWOOD = false;

  rwySurfaceSuitableRestrAsphaltAndConcrete = false;
  rwySurfaceSuitableRestrASPH = false;
  rwySurfaceSuitableRestrASPHTRTD = false;
  rwySurfaceSuitableRestrCONC = false;
  rwySurfaceSuitableRestrCONCTRTD = false;
  rwySurfaceSuitableRestrASPHCONC = false;

  rwySurfaceSuitableRestrGravel = false;
  rwySurfaceSuitableRestrGRVL = false;
  rwySurfaceSuitableRestrGRVLTRTD = false;
  rwySurfaceSuitableRestrASPHGRVL = false;
  rwySurfaceSuitableRestrCONCGRVL = false;
  rwySurfaceSuitableRestrGRVLDIRT = false;
  rwySurfaceSuitableRestrGRVLTURF = false;
  rwySurfaceSuitableRestrCORAL = false;

  rwySurfaceSuitableRestrALLDIRT = false;
  rwySurfaceSuitableRestrDIRT = false;
  rwySurfaceSuitableRestrDIRTTRTD = false;
  rwySurfaceSuitableRestrASPHDIRT = false;
  rwySurfaceSuitableRestrDIRTGRVL = false;
  rwySurfaceSuitableRestrDIRTTURF = false;
  rwySurfaceSuitableRestrCALICHE = false;

  rwySurfaceSuitableRestrALLTURF = false;
  rwySurfaceSuitableRestrTURF = false;
  rwySurfaceSuitableRestrASPHTURF = false;
  rwySurfaceSuitableRestrCONCTURF = false;
  rwySurfaceSuitableRestrTURFGRVL = false;
  rwySurfaceSuitableRestrTURFDIRT = false;
  rwySurfaceSuitableRestrTURFSAND = false;
  rwySurfaceSuitableRestrOILCHIPT = false;
  rwySurfaceSuitableRestrSOD = false;
  rwySurfaceSuitableRestrGRASS = false;

  rwySurfaceSuitableRestrSnowAndIce = false;
  rwySurfaceSuitableRestrSNOW = false;
  rwySurfaceSuitableRestrICE = false;

  rwySurfaceSuitableRestrAllWater = false;
  rwySurfaceSuitableRestrWATER = false;

  rwySurfaceSuitableRestrOther = false;

  rwySurfaceSuitableRestrALUMINUM = false;
  rwySurfaceSuitableRestrBRICK = false;
  rwySurfaceSuitableRestrDECK = false;
  rwySurfaceSuitableRestrMATS = false;
  rwySurfaceSuitableRestrMETAL = false;

  rwySurfaceSuitableRestrROOFTOP = false;
  rwySurfaceSuitableRestrSAND = false;
  rwySurfaceSuitableRestrSTEEL = false;
  rwySurfaceSuitableRestrSTEELCONC = false;
  rwySurfaceSuitableRestrWOOD = false;

  FormSuccessfullySubmitted = false;
  isFieldDisabledForModifying = true;

  editButtonClicked = false;
  editError = false;
  addNewAircraftClicked = false;
  clearButtonClicked = false;
  clearError = false;
  deleteButtonClicked = false;
  duplicateButtonClicked = false;
  aircraftConfiguratorDeleted = false;
  applyButtonClicked = false;
  canceledButtonClicked = false;

  formErrors = false;
  closedForModifyingArray = [1, 2];
  showAircraftConfiguratorDeleteWindow = false;

  showAircraftElevationParams = false;
  showAircraftMaxElevation = false;
  showAircraftMaxElevationRestr = false;
  showAircraftMaxElevationUnSuitable = false;

  showAircraftRunwayLengthParams = false;
  showAircraftMinRunwayLength = false;
  showAircraftMinRunwayLengthRestr = false;
  showAircraftMinRunwayLengthUnSuitable = false;

  showAircraftRunwayWidthParams = false;
  showAircraftMinRunwayWidth = false;
  showAircraftMinRunwayWidthRestr = false;
  showAircraftMinRunwayWidthUnSuitable = false;

  showAircraftSlopeParams = false;
  showAircraftMaxSlope = false;
  showAircraftMaxSlopeFirstRestr = false;
  showAircraftMaxSlopeSecondRestr = false;
  showAircraftMaxSlopeUnSuitable = false;

  showAircraftSurfaceSuitableParams = false;
  showAircraftSurfaceSuitable = false;
  showAircraftSurfaceSuitableRestr = false;
  showAircraftSurfaceSuitableUnSuitable = false;

  showAllSurfaceSuitableAsphaltAndConcrete = false;
  showAllSurfaceSuitableGravel = false;
  showAllSurfaceSuitableDirt = false;
  showAllSurfaceSuitableTurf = false;
  showAllSurfaceSuitableSnowAndIce = false;
  showAllSurfaceSuitableWater = false;
  showAllSurfaceSuitableOther = false;

  showAllSurfaceSuitableRestrAsphaltAndConcrete = false;
  showAllSurfaceSuitableRestrGravel = false;
  showAllSurfaceSuitableRestrDirt = false;
  showAllSurfaceSuitableRestrTurf = false;
  showAllSurfaceSuitableRestrSnowAndIce = false;
  showAllSurfaceSuitableRestrWater = false;
  showAllSurfaceSuitableRestrOther = false;

  showAircraftRunwayStrengthParams = false;
  showAircraftMinRunwayStrength = false;
  showAircraftMinRunwayStrengthRestr = false;
  showAircraftMinRunwayStrengthUnSuitable = false;
  
  showAircraftARFFParams = false;
  showAircraftMinARFF = false;
  showAircraftMinARFFRestr = false;
  showAircraftMinARFFUnSuitable = false;

  optionsElevationFeet: Options = {
    floor: 0,
    ceil: 29032,
    step: 1,
    disabled: true,
    translate: (value: number): string => {
      return value + ' ft';
    },
    showTicksValues: true,
    tickStep: 4000,
    tickValueStep: 4000,
    enforceRange: false
  };
  optionsElevationMeters: Options = {
    floor: 0,
    ceil: 8849,
    step: 1,
    disabled: true,
    translate: (value: number): string => {
      return value + ' m';
    },
    showTicksValues: true,
    tickStep: 1000,
    tickValueStep: 1000,
    enforceRange: false
  };
  optionsRunwayLengthFeet: Options = {
    floor: 0,
    ceil: 20000,
    step: 1,
    disabled: true,
    translate: (value: number): string => {
      return value + ' ft';
    },
    showTicksValues: true,
    tickStep: 3000,
    tickValueStep: 3000,
    enforceRange: false
  };
  optionsRunwayLengthMeters: Options = {
    floor: 0,
    ceil: 6096,
    step: 1,
    disabled: true,
    translate: (value: number): string => {
      return value + ' m';
    },
    showTicksValues: true,
    tickStep: 1000,
    tickValueStep: 1000,
    enforceRange: false
  };
  optionsRunwayWidthFeet: Options = {
    floor: 0,
    ceil: 10000,
    step: 1,
    disabled: true,
    translate: (value: number): string => {
      return value + ' ft';
    },
    showTicksValues: true,
    tickStep: 3000,
    tickValueStep: 3000,
    enforceRange: false
  };
  optionsRunwayWidthMeters: Options = {
    floor: 0,
    ceil: 3048,
    step: 1,
    disabled: true,
    translate: (value: number): string => {
      return value + ' m';
    },
    showTicksValues: true,
    tickStep: 1000,
    tickValueStep: 1000,
    enforceRange: false
  };
  optionsRunwaySlope: Options = {
    floor: -5,
    ceil: 5,
    step: 0.01,
    disabled: true,
    translate: (value: number): string => {
      return value + ' °';
    },
    showTicksValues: true,
    tickStep: 1,
    tickValueStep: 1,
    enforceRange: false
  };

  optionsRunwayStrength: Options = {
    floor: 0,
    ceil: 100,
    step: 1,
    disabled: true,
    translate: (value: number): string => {
      return value + ' %';
    },
    showTicksValues: true,
    tickStep: 10,
    tickValueStep: 10,
    enforceRange: false
  };

  optionsRunwayARFF: Options = {
    showTicksValues: true,
    stepsArray: [
      {value: 1, legend: 'A'},
      {value: 2, legend: 'A'},
      {value: 3, legend: 'A'},
      {value: 4, legend: 'A'},
      {value: 5, legend: 'A'},
      {value: 6, legend: 'B'},
      {value: 7, legend: 'C'},
      {value: 8, legend: 'D'},
      {value: 9, legend: 'E'},
      {value: 10, legend: 'E'}
    ]
  };
  
  /* END of Constructor*/

  public user: User;
  isFeetsChoosen = true;

  airportIATACode = 'airport';
  labelOptions = {
    color: 'white',
    fontFamily: '',
    fontSize: '14px',
    fontWeight: 'bold',
    text: this.airportIATACode 
  };
  isUserLoggedIn = false;

  colorOfRunwayMap = 'green';
  zoom = 14;

  aircraftConfiguratorChanged = 0;

  constructor(private airportService: AirportService,
              private route: ActivatedRoute, private authenticationService: AuthenticationService,
              private notificationService: NotificationService, private router: Router) { }

  aircraftConfiguratorWasChanged(): void {
    this.aircraftConfiguratorChanged = this.aircraftConfiguratorChanged+1;
  }  

  ngOnInit() {
    this.route.paramMap.subscribe(() => {
      this.handleAirportDetails();
      this.getTheLatestAirportListANDupdateConf(this.aircraftConfiguratorID);
      if (this.authenticationService.isUserLoggedIn()){
        this.user = this.authenticationService.getUserFromLocalCache();
        this.isUserLoggedIn = this.authenticationService.isUserLoggedIn();
      }
    });
      
    this.route.queryParams.subscribe(params => {
      if (params.configurator != null) {
        this.switchConfiguratorStatus = JSON.parse(params.configurator);
      }

      if (params.feet != null) {
        this.isFeetsChoosen = JSON.parse(params.feet);
      }
    });
  }

  /*Aircraft Settings*/
  onChangeDisabled(): void {
    this.optionsElevationFeet = Object.assign({}, this.optionsElevationFeet, {disabled: this.isFieldDisabledForModifying});
    this.optionsElevationMeters = Object.assign({}, this.optionsElevationMeters, {disabled: this.isFieldDisabledForModifying});

    this.optionsRunwayLengthFeet = Object.assign({}, this.optionsRunwayLengthFeet, {disabled: this.isFieldDisabledForModifying});
    this.optionsRunwayLengthMeters = Object.assign({}, this.optionsRunwayLengthMeters, {disabled: this.isFieldDisabledForModifying});

    this.optionsRunwayWidthFeet = Object.assign({}, this.optionsRunwayWidthFeet, {disabled: this.isFieldDisabledForModifying});
    this.optionsRunwayWidthMeters = Object.assign({}, this.optionsRunwayWidthMeters, {disabled: this.isFieldDisabledForModifying});

    this.optionsRunwaySlope = Object.assign({}, this.optionsRunwaySlope, {disabled: this.isFieldDisabledForModifying});

    this.optionsRunwayStrength = Object.assign({}, this.optionsRunwayStrength, {disabled: this.isFieldDisabledForModifying});
  }

  isValueNull(value: any): Number {
    return value === null ? 0 : value;
  }
  
  // Elevation
  onUserChangeElevSuiFeet(changeContext: ChangeContext): void {
    this.calculateElevationSuitableMinMeters();
    this.calculateElevationSuitableMaxMeters();
  }
  onUserChangeElevSuiMeters(changeContext: ChangeContext): void {
    this.calculateElevationSuitableMinFeet();
    this.calculateElevationSuitableMaxFeet();
  }
  onUserChangeElevSuiRestrFeet(changeContext: ChangeContext): void {
    this.calculateElevationSuitableRestrMinMeters();
    this.calculateElevationSuitableRestrMaxMeters();
  }
  onUserChangeElevSuiRestrMeters(changeContext: ChangeContext): void {
    this.calculateElevationSuitableMinFeet();
    this.calculateElevationSuitableMaxFeet();
  }
  
  onClickShowElevationParams() {
    this.showAircraftElevationParams = this.showAircraftElevationParams == true ? false : true;
  }
  onClickShowMaxElevation() {
    this.showAircraftMaxElevation = this.showAircraftMaxElevation == true ? false : true;
  }
  onClickShowMaxElevationRestr() {
    this.showAircraftMaxElevationRestr = this.showAircraftMaxElevationRestr == true ? false : true;
  }
  onClickShowMaxElevationUnSuitable() {
    this.showAircraftMaxElevationUnSuitable = this.showAircraftMaxElevationUnSuitable == true ? false : true;
  }

  onClickTurnOnOffMaxElevationAll() {
    this.aircraftConfigurator.turnOnOffMaxElevationAll = this.aircraftConfigurator.turnOnOffMaxElevationAll === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMaxElevationSuitable() {
    this.aircraftConfigurator.turnOnOffMaxElevationSuitable = this.aircraftConfigurator.turnOnOffMaxElevationSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMaxElevationRestr() {
    this.aircraftConfigurator.turnOnOffMaxElevationRestr = this.aircraftConfigurator.turnOnOffMaxElevationRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMaxElevationUnSuitable() {
    this.aircraftConfigurator.turnOnOffMaxElevationUnSuitable = this.aircraftConfigurator.turnOnOffMaxElevationUnSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  // Elevation. END

  // RunwayLength
  onUserChangeRunwayLengthSuiFeet(changeContext: ChangeContext): void {
    this.calculateRWYLengthSuitableMinMeters();
    this.calculateRWYLengthSuitableMaxMeters();
  }
  onUserChangeRunwayLengthSuiMeters(changeContext: ChangeContext): void {
    this.calculateRWYLengthSuitableMinFeet();
    this.calculateRWYLengthSuitableMaxFeet();
  }
  onUserChangeRunwayLengthSuiRestrFeet(changeContext: ChangeContext): void {
    this.calculateRWYLengthSuitableRestrMinMeters();
    this.calculateRWYLengthSuitableRestrMaxMeters();
  }
  onUserChangeRunwayLengthSuiRestrMeters(changeContext: ChangeContext): void {
    this.calculateRWYLengthSuitableRestrMinFeet();
    this.calculateRWYLengthSuitableRestrMaxFeet();
  }

  onClickShowRunwayLengthParams() {
    this.showAircraftRunwayLengthParams = this.showAircraftRunwayLengthParams == true ? false : true;
  }
  onClickShowMinRunwayLength() {
    this.showAircraftMinRunwayLength = this.showAircraftMinRunwayLength == true ? false : true;
  }
  onClickShowMinRunwayLengthRestr() {
    this.showAircraftMinRunwayLengthRestr = this.showAircraftMinRunwayLengthRestr == true ? false : true;
  }
  onClickShowMinRunwayLengthUnSuitable() {
    this.showAircraftMinRunwayLengthUnSuitable = this.showAircraftMinRunwayLengthUnSuitable == true ? false : true;
  }

  onClickTurnOnOffMinRunwayLengthAll() {
    this.aircraftConfigurator.turnOnOffMinRunwayLengthAll = this.aircraftConfigurator.turnOnOffMinRunwayLengthAll === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayLengthSuitable() {
    this.aircraftConfigurator.turnOnOffMinRunwayLengthSuitable = this.aircraftConfigurator.turnOnOffMinRunwayLengthSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayLengthRestr() {
    this.aircraftConfigurator.turnOnOffMinRunwayLengthRestr = this.aircraftConfigurator.turnOnOffMinRunwayLengthRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayLengthUnSuitable() {
    this.aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable = this.aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  // RunwayLength. END

  // RunwayWidth
  onUserChangeRunwayWidthSuiFeet(changeContext: ChangeContext): void {
    this.calculateRWYWidthSuitableMinMeters();
    this.calculateRWYWidthSuitableMaxMeters();
  }
  onUserChangeRunwayWidthSuiMeters(changeContext: ChangeContext): void {
    this.calculateRWYWidthSuitableMinFeet();
    this.calculateRWYWidthSuitableMaxFeet();
  }
  onUserChangeRunwayWidthSuiRestrFeet(changeContext: ChangeContext): void {
    this.calculateRWYWidthSuitableRestrMinMeters();
    this.calculateRWYWidthSuitableRestrMaxMeters();
  }
  onUserChangeRunwayWidthSuiRestrMeters(changeContext: ChangeContext): void {
    this.calculateRWYWidthSuitableRestrMinFeet();
    this.calculateRWYWidthSuitableRestrMaxFeet();
  }

  onClickShowRunwayWidthParams() {
    this.showAircraftRunwayWidthParams = this.showAircraftRunwayWidthParams == true ? false : true;
  }
  onClickShowMinRunwayWidth() {
    this.showAircraftMinRunwayWidth = this.showAircraftMinRunwayWidth == true ? false : true;
  }
  onClickShowMinRunwayWidthRestr() {
    this.showAircraftMinRunwayWidthRestr = this.showAircraftMinRunwayWidthRestr == true ? false : true;
  }
  onClickShowMinRunwayWidthUnSuitable() {
    this.showAircraftMinRunwayWidthUnSuitable = this.showAircraftMinRunwayWidthUnSuitable == true ? false : true;
  }

  onClickTurnOnOffMinRunwayWidthAll() {
    this.aircraftConfigurator.turnOnOffMinRunwayWidthAll = this.aircraftConfigurator.turnOnOffMinRunwayWidthAll === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayWidthSuitable() {
    this.aircraftConfigurator.turnOnOffMinRunwayWidthSuitable = this.aircraftConfigurator.turnOnOffMinRunwayWidthSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayWidthRestr() {
    this.aircraftConfigurator.turnOnOffMinRunwayWidthRestr = this.aircraftConfigurator.turnOnOffMinRunwayWidthRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayWidthUnSuitable() {
    this.aircraftConfigurator.turnOnOffMinRunwayWidthUnSuitable = this.aircraftConfigurator.turnOnOffMinRunwayWidthUnSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  // RunwayWidth. END

  // RunwaySlope
  onClickShowSlopeParams() {
    this.showAircraftSlopeParams = this.showAircraftSlopeParams == true ? false : true;
  }
  onClickShowMaxSlope() {
    this.showAircraftMaxSlope = this.showAircraftMaxSlope == true ? false : true;
  }
  onClickShowMaxSlopeFirstRestr() {
    this.showAircraftMaxSlopeFirstRestr = this.showAircraftMaxSlopeFirstRestr == true ? false : true;
  }
  onClickShowMaxSlopeSecondRestr() {
    this.showAircraftMaxSlopeSecondRestr = this.showAircraftMaxSlopeSecondRestr == true ? false : true;
  }
  onClickShowMaxSlopeUnSuitable() {
    this.showAircraftMaxSlopeUnSuitable = this.showAircraftMaxSlopeUnSuitable == true ? false : true;
  }

  onClickTurnOnOffMaxSlopeAll() {
    this.aircraftConfigurator.turnOnOffMaxSlopeAll = this.aircraftConfigurator.turnOnOffMaxSlopeAll === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMaxSlopeSuitable() {
    this.aircraftConfigurator.turnOnOffMaxSlopeSuitable = this.aircraftConfigurator.turnOnOffMaxSlopeSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMaxSlopeFirstRestr() {
    this.aircraftConfigurator.turnOnOffMaxSlopeFirstRestr = this.aircraftConfigurator.turnOnOffMaxSlopeFirstRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
	onClickTurnOnOffMaxSlopeSecondRestr() {
    this.aircraftConfigurator.turnOnOffMaxSlopeSecondRestr = this.aircraftConfigurator.turnOnOffMaxSlopeSecondRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMaxSlopeUnSuitable() {
    this.aircraftConfigurator.turnOnOffMaxSlopeUnSuitable = this.aircraftConfigurator.turnOnOffMaxSlopeUnSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  // RunwaySlope. END

  // RunwaySurface
  onClickShowSurfaceSuitableParams() {
    this.showAircraftSurfaceSuitableParams = this.showAircraftSurfaceSuitableParams == true ? false : true;
  }
  onClickShowSurfaceSuitable() {
    this.showAircraftSurfaceSuitable = this.showAircraftSurfaceSuitable == true ? false : true;
  }
  onClickShowSurfaceSuitableRestr() {
    this.showAircraftSurfaceSuitableRestr = this.showAircraftSurfaceSuitableRestr == true ? false : true;
  }
  onClickShowSurfaceSuitableUnSuitable() {
    this.showAircraftSurfaceSuitableUnSuitable = this.showAircraftSurfaceSuitableUnSuitable == true ? false : true;
  }

  onClickTurnOnOffSurfaceSuitableAll() {
    this.aircraftConfigurator.turnOnOffSurfaceSuitableAll = this.aircraftConfigurator.turnOnOffSurfaceSuitableAll === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffSurfaceSuitable() {
    this.aircraftConfigurator.turnOnOffSurfaceSuitable = this.aircraftConfigurator.turnOnOffSurfaceSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffSurfaceSuitableRestr() {
    this.aircraftConfigurator.turnOnOffSurfaceSuitableRestr = this.aircraftConfigurator.turnOnOffSurfaceSuitableRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffSurfaceSuitableUnSuitable() {
    this.aircraftConfigurator.turnOnOffSurfaceUnSuitable = this.aircraftConfigurator.turnOnOffSurfaceUnSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }

  onClickShowAllSurfaceSuitableAsphaltAndConcrete() {
    this.showAllSurfaceSuitableAsphaltAndConcrete = this.showAllSurfaceSuitableAsphaltAndConcrete === true ? false : true;
  }
  onClickShowAllSurfaceSuitableGravel() {
    this.showAllSurfaceSuitableGravel = this.showAllSurfaceSuitableGravel === true ? false : true;
  }
  onClickShowAllSurfaceSuitableDirt() {
    this.showAllSurfaceSuitableDirt = this.showAllSurfaceSuitableDirt === true ? false : true;
  }
  onClickShowAllSurfaceSuitableTurf() {
    this.showAllSurfaceSuitableTurf = this.showAllSurfaceSuitableTurf === true ? false : true;
  }
  onClickShowAllSurfaceSuitableSnowAndIce() {
    this.showAllSurfaceSuitableSnowAndIce = this.showAllSurfaceSuitableSnowAndIce === true ? false : true;
  }
  onClickShowAllSurfaceSuitableWater() {
    this.showAllSurfaceSuitableWater = this.showAllSurfaceSuitableWater === true ? false : true;
  }
  onClickShowAllSurfaceSuitableOther() {
    this.showAllSurfaceSuitableOther = this.showAllSurfaceSuitableOther === true ? false : true;
  }

  onClickShowAllSurfaceSuitableRestrAsphaltAndConcrete() {
    this.showAllSurfaceSuitableRestrAsphaltAndConcrete = this.showAllSurfaceSuitableRestrAsphaltAndConcrete === true ? false : true;
  }
  onClickShowAllSurfaceSuitableRestrGravel() {
    this.showAllSurfaceSuitableRestrGravel = this.showAllSurfaceSuitableRestrGravel === true ? false : true;
  }
  onClickShowAllSurfaceSuitableRestrDirt() {
    this.showAllSurfaceSuitableRestrDirt = this.showAllSurfaceSuitableRestrDirt === true ? false : true;
  }
  onClickShowAllSurfaceSuitableRestrTurf() {
    this.showAllSurfaceSuitableRestrTurf = this.showAllSurfaceSuitableRestrTurf === true ? false : true;
  }
  onClickShowAllSurfaceSuitableRestrSnowAndIce() {
    this.showAllSurfaceSuitableRestrSnowAndIce = this.showAllSurfaceSuitableRestrSnowAndIce === true ? false : true;
  }
  onClickShowAllSurfaceSuitableRestrWater() {
    this.showAllSurfaceSuitableRestrWater = this.showAllSurfaceSuitableRestrWater === true ? false : true;
  }
  onClickShowAllSurfaceSuitableRestrOther() {
    this.showAllSurfaceSuitableRestrOther = this.showAllSurfaceSuitableRestrOther === true ? false : true;
  }
  // RunwaySurface. END

  // RunwayStrength
  onClickShowRunwayStrengthParams() {
    this.showAircraftRunwayStrengthParams = this.showAircraftRunwayStrengthParams == true ? false : true;
  }
  onClickShowMinRunwayStrength() {
    this.showAircraftMinRunwayStrength = this.showAircraftMinRunwayStrength == true ? false : true;
  }
  onClickShowMinRunwayStrengthRestr() {
    this.showAircraftMinRunwayStrengthRestr = this.showAircraftMinRunwayStrengthRestr == true ? false : true;
  }
  onClickShowMinRunwayStrengthUnSuitable() {
    this.showAircraftMinRunwayStrengthUnSuitable = this.showAircraftMinRunwayStrengthUnSuitable == true ? false : true;
  }

  onClickTurnOnOffMinRunwayStrengthAll() {
    this.aircraftConfigurator.turnOnOffMinRunwayStrengthAll = this.aircraftConfigurator.turnOnOffMinRunwayStrengthAll === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayStrengthSuitable() {
    this.aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable = this.aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayStrengthRestr() {
    this.aircraftConfigurator.turnOnOffMinRunwayStrengthRestr = this.aircraftConfigurator.turnOnOffMinRunwayStrengthRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinRunwayStrengthUnSuitable() {
    this.aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable = this.aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  // RunwayStrength. END

  // ARFF
  onClickShowARFFParams() {
    this.showAircraftARFFParams = this.showAircraftARFFParams == true ? false : true;
  }
  onClickShowMinARFF() {
    this.showAircraftMinARFF = this.showAircraftMinARFF == true ? false : true;
  }
  onClickShowMinARFFRestr() {
    this.showAircraftMinARFFRestr = this.showAircraftMinARFFRestr == true ? false : true;
  }
  onClickShowMinARFFUnSuitable() {
    this.showAircraftMinARFFUnSuitable = this.showAircraftMinARFFUnSuitable == true ? false : true;
  }

  onClickTurnOnOffMinARFFAll() {
    this.aircraftConfigurator.turnOnOffMinARFFAll = this.aircraftConfigurator.turnOnOffMinARFFAll === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinARFFSuitable() {
    this.aircraftConfigurator.turnOnOffMinARFFSuitable = this.aircraftConfigurator.turnOnOffMinARFFSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinARFFRestr() {
    this.aircraftConfigurator.turnOnOffMinARFFRestr = this.aircraftConfigurator.turnOnOffMinARFFRestr === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  onClickTurnOnOffMinARFFUnSuitable() {
    this.aircraftConfigurator.turnOnOffMinARFFUnSuitable = this.aircraftConfigurator.turnOnOffMinARFFUnSuitable === true ? false : true;
    this.aircraftConfiguratorWasChanged();
  }
  // ARFF. END
  /*Aircraft Settings*/

  /*Map*/
  getTheLabelForRunway(runway: Runway): string{
    if (this.isFeetsChoosen) {
      return (((runway.runwayLength.toString()).concat(' x ')).concat(runway.runwayWidth.toString())).concat(' ft');
    } else {
      return ((((runway.runwayLength*0.3048).toFixed(1)).concat(' x ')).concat((runway.runwayWidth*0.3048).toFixed(1))).concat(' m');
    }
  }

  zoomChange(event){
    this.zoom = event;
  }

  calculateMidleLatitude(runway: Runway): number {
    return ((runway.baseEndPhysicalLatitudeMap + runway.reciprocalEndPhysicalLatitudeMap)/2);
  }

  calculateMidleLongitude(runway: Runway): number {
    return ((runway.baseEndPhysicalLongitudeMap + runway.reciprocalEndPhysicalLongitudeMap)/2);
  }

  clickOnButtonFeetMeters(tmp: boolean) {
    this.isFeetsChoosen = tmp;

    let queryParams: Params;
  
    if (!this.isFeetsChoosen) {
      queryParams = { feet: 'false'};
    } else if (this.isFeetsChoosen) {
      queryParams = { feet: 'true'};
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: queryParams, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  onSubmit(): void {
    if (this.editButtonClicked === true) {
      this.airportService.updateAircraftConfigurator(this.aircraftConfigurator).subscribe();
      this.sendNotification(NotificationType.SUCCESS, `${this.aircraftConfigurator.aircraftName} was successfully updated.`);
    }
    if (this.addNewAircraftClicked === true || this.duplicateButtonClicked === true) {
      this.airportService.postAircraftConfigurator(this.aircraftConfigurator)
      .subscribe(() => {
        if (this.authenticationService.isUserLoggedIn()) {
        const defaultAircraftConfigurators$ = this.airportService.getAircraftConfiguratorByUserName('default');
        const userAircraftConfigurators$ = this.airportService.getAircraftConfiguratorByUserName(this.authenticationService.getUserFromLocalCache().username);
        forkJoin([defaultAircraftConfigurators$, userAircraftConfigurators$]).subscribe(
          data => {
            this.aircraftConfigurators = data[0].concat(data[1]);
            for (let id = 0; id < this.aircraftConfigurators.length; id++) {
              let tmp1: string;
              tmp1 = formatDate(this.aircraftConfigurator.created_date, 'full', 'en');
              let tmp2: string;
              tmp2 = formatDate(this.aircraftConfigurators[id].created_date, 'full', 'en');
              if (this.aircraftConfigurator.aircraftName === this.aircraftConfigurators[id].aircraftName && tmp1 === tmp2) {
                this.updateAircraftConfById(this.aircraftConfigurators[id].idConfigurator);
              }
            }
          }
        )
        } else if (!this.authenticationService.isUserLoggedIn()) {
          this.airportService.getAircraftConfiguratorByUserName('default').subscribe(
            data => {
              this.aircraftConfigurators = data;
              for (let id = 0; id < this.aircraftConfigurators.length; id++) {
                if (this.aircraftConfigurator.aircraftName === this.aircraftConfigurators[id].aircraftName) {
                  this.updateAircraftConfById(this.aircraftConfigurators[id].idConfigurator);
                }
              }
            }
          )
        }
      })
      this.sendNotification(NotificationType.SUCCESS, `${this.aircraftConfigurator.aircraftName} was successfully created.`);
    }
    this.FormSuccessfullySubmitted = true;
    this.addNewAircraftClicked = false;
    this.duplicateButtonClicked = false;
    this.editButtonClicked = false;
    this.isFieldDisabledForModifying = true;
    this.onChangeDisabled();
    this.clearButtonClicked = false;
  }

  getTheLatestAirportList() {
    if (this.authenticationService.isUserLoggedIn()) {
    const defaultAircraftConfigurators$ = this.airportService.getAircraftConfiguratorByUserName('default');
    const userAircraftConfigurators$ = this.airportService.getAircraftConfiguratorByUserName(this.authenticationService.getUserFromLocalCache().username);
    forkJoin([defaultAircraftConfigurators$, userAircraftConfigurators$]).subscribe(
      data => {
        this.aircraftConfigurators = data[0].concat(data[1]);
      }
    )
    } else if (!this.authenticationService.isUserLoggedIn()) {
      this.airportService.getAircraftConfiguratorByUserName('default').subscribe(
        data => {
          this.aircraftConfigurators = data;
        }
      )
    }
  }

  getTheLatestAirportListANDupdateConf(id: number) {
    if (this.authenticationService.isUserLoggedIn()) {
    const defaultAircraftConfigurators$ = this.airportService.getAircraftConfiguratorByUserName('default');
    const userAircraftConfigurators$ = this.airportService.getAircraftConfiguratorByUserName(this.authenticationService.getUserFromLocalCache().username);
    forkJoin([defaultAircraftConfigurators$, userAircraftConfigurators$]).subscribe(
      data => {
        this.aircraftConfigurators = data[0].concat(data[1]);
        this.updateAircraftConfById(id);
      }
    )
    } else if (!this.authenticationService.isUserLoggedIn()) {
      this.airportService.getAircraftConfiguratorByUserName('default').subscribe(
        data => {
          this.aircraftConfigurators = data;
          this.updateAircraftConfById(id);
        }
      )
    }
  }
  
  showDeleteWindow() {
    if (this.canItoDeleteTheConfigurator(this.aircraftConfigurator.idConfigurator)) {
      this.showAircraftConfiguratorDeleteWindow = true;
      this.deleteButtonClicked = true;
      this.isFieldDisabledForModifying = true;
      this.onChangeDisabled();
    }
    this.FormSuccessfullySubmitted = false;
  }

  onClickButtonCloseDeleteWindow() {
    this.showAircraftConfiguratorDeleteWindow = false;
    this.deleteButtonClicked = false;
    this.isFieldDisabledForModifying = true;
    this.onChangeDisabled();
  }

  canItoDeleteTheConfigurator(id: number): boolean {
    let result = true;
    this.isFieldDisabledForModifying = false;
    this.onChangeDisabled();

    for (let tempId of this.closedForModifyingArray) {
        if (tempId === id) {
          result = false;
          this.isFieldDisabledForModifying = true;
          this.onChangeDisabled();
          this.editError = true;
          this.deleteButtonClicked = false;
        }
      }
    return result;
  }

  deleteAircraftConfigurator() {
    let previous = null;

    for (let id = 0; id < this.aircraftConfigurators.length; id++) {
      if (this.aircraftConfiguratorID === this.aircraftConfigurators[id].idConfigurator) {
        previous = id - 1;
      }
    }
    this.sendNotification(NotificationType.ERROR, `${this.aircraftConfigurator.aircraftName} was successfully deleted.`);

    this.airportService.deleteThisAicraftConfigurator(this.aircraftConfigurator.idConfigurator).subscribe(
      () => {
        this.getTheLatestAirportList();
        this.updateAircraftConfByNumberFromArray(previous);
        this.showAircraftConfiguratorDeleteWindow = false;
        this.deleteButtonClicked = false;
        this.isFieldDisabledForModifying = true;
        this.onChangeDisabled();
      }
    );
  }

  createNewAircraftConfigurator() {
    this.addNewAircraftClicked = true;
    this.isFieldDisabledForModifying = false;
    this.onChangeDisabled();
    this.FormSuccessfullySubmitted = false;
    this.aircraftConfiguratorDeleted = false;
    this.editError = false;

    this.previousAircraftConfiguratorID = this.aircraftConfiguratorID;
    this.makeAllSurfaceParamsFalse();

    let newAircraftConfigurator = new AircraftConfigurator(
      null, this.authenticationService.getUserFromLocalCache().username, new Date(), 
      null, null, null, null, null, null, null, null, null, null, 
      null, null, null, null, 
      false, false, false, false, 
      null, null, null, null, null, null, null, null, null, null, null, null,
      false, false, false, false,
      null, null, null, null, null, null, null, null, null, null, null, null,
      false, false, false, false,
      null, null, null, null, null, null, null, null, null, null, null, 
      false, false, false, false, false, 
      null, null, null, null, null, 
      false, false, false, false,
      null, null, null, null, null, null, null, null, 
      false, false, false, false,
      null, null, null, null, null, null, 
      null, null, null, null, null, null, null, null, 
      false, false, false, false,
      null, null, null, null, null, null,
      null, null, null, null, null, null, null, null, null, null, null, null,
      null, null, null, null, null, null, null, null, null, null);
      

    this.aircraftConfigurators.push(newAircraftConfigurator);

    if (this.aircraftConfigurators) {
      for (let id = 0; id < this.aircraftConfigurators.length; id++) {
        if (this.aircraftConfigurators[id].username === newAircraftConfigurator.username &&
            this.aircraftConfigurators[id].created_date === newAircraftConfigurator.created_date) {
          this.updateAircraftConfByNumberFromArray(id);
        }
      }
    }

  }

  duplicateAircraftConfigurator() {
    this.duplicateButtonClicked = true;
    this.addNewAircraftClicked = false;
    this.isFieldDisabledForModifying = false;
    this.onChangeDisabled();
    this.FormSuccessfullySubmitted = false;
    this.aircraftConfiguratorDeleted = false;
    this.editError = false;

    this.previousAircraftConfiguratorID = this.aircraftConfiguratorID;

    let newAircraftConfigurator = this.aircraftConfigurator;

    newAircraftConfigurator.idConfigurator = null;
    newAircraftConfigurator.username = this.authenticationService.getUserFromLocalCache().username;
    newAircraftConfigurator.created_date = new Date();
    newAircraftConfigurator.last_updated_date = null;

    this.aircraftConfigurators.push(newAircraftConfigurator);

    if (this.aircraftConfigurators) {
      for (let id = 0; id < this.aircraftConfigurators.length; id++) {
        if (this.aircraftConfigurators[id].username === newAircraftConfigurator.username &&
            this.aircraftConfigurators[id].created_date === newAircraftConfigurator.created_date) {
          this.updateAircraftConfByNumberFromArray(id);
        }
      }
    }

  }

  onClickButtonsClose() {
    if (!this.showAircraftConfiguratorWindow) {
      this.showAircraftConfiguratorWindow = true;
    } else if (this.showAircraftConfiguratorWindow) {
      this.showAircraftConfiguratorWindow = false;
    }
  }

  makeAllSurfaceParamsFalse() {
    /* Make all param-s are false */
    this.aircraftConfigurator.rwySurfaceSuitableArray = [];
    this.rwySurfaceSuitableAsphaltAndConcrete = false;
    this.rwySurfaceSuitableASPH = false;
    this.rwySurfaceSuitableASPHTRTD = false;
    this.rwySurfaceSuitableCONC = false;
    this.rwySurfaceSuitableCONCTRTD = false;
    this.rwySurfaceSuitableASPHCONC = false;

    this.rwySurfaceSuitableGravel = false;
    this.rwySurfaceSuitableGRVL = false;
    this.rwySurfaceSuitableGRVLTRTD = false;
    this.rwySurfaceSuitableASPHGRVL = false;
    this.rwySurfaceSuitableCONCGRVL = false;
    this.rwySurfaceSuitableGRVLDIRT = false;
    this.rwySurfaceSuitableGRVLTURF = false;
    this.rwySurfaceSuitableCORAL = false;

    this.rwySurfaceSuitableALLDIRT = false;
    this.rwySurfaceSuitableDIRT = false;
    this.rwySurfaceSuitableDIRTTRTD = false;
    this.rwySurfaceSuitableASPHDIRT = false;
    this.rwySurfaceSuitableDIRTGRVL = false;
    this.rwySurfaceSuitableDIRTTURF = false;
    this.rwySurfaceSuitableCALICHE = false;

    this.rwySurfaceSuitableALLTURF = false;
    this.rwySurfaceSuitableTURF = false;
    this.rwySurfaceSuitableASPHTURF = false;
    this.rwySurfaceSuitableCONCTURF = false;
    this.rwySurfaceSuitableTURFGRVL = false;
    this.rwySurfaceSuitableTURFDIRT = false;
    this.rwySurfaceSuitableTURFSAND = false;
    this.rwySurfaceSuitableOILCHIPT = false;
    this.rwySurfaceSuitableSOD = false;
    this.rwySurfaceSuitableGRASS = false;

    this.rwySurfaceSuitableSnowAndIce = false;
    this.rwySurfaceSuitableSNOW = false;
    this.rwySurfaceSuitableICE = false;

    this.rwySurfaceSuitableAllWater = false;
    this.rwySurfaceSuitableWATER = false;

    this.rwySurfaceSuitableOther = false;

    this.rwySurfaceSuitableALUMINUM = false;
    this.rwySurfaceSuitableBRICK = false;
    this.rwySurfaceSuitableDECK = false;
    this.rwySurfaceSuitableMATS = false;
    this.rwySurfaceSuitableMETAL = false;

    this.rwySurfaceSuitableROOFTOP = false;
    this.rwySurfaceSuitableSAND = false;
    this.rwySurfaceSuitableSTEEL = false;
    this.rwySurfaceSuitableSTEELCONC = false;
    this.rwySurfaceSuitableWOOD = false;
    /* END */

    /* Make all param-s are false */
    this.aircraftConfigurator.rwySurfaceSuitableRestrArray = [];
    this.rwySurfaceSuitableRestrAsphaltAndConcrete = false;
    this.rwySurfaceSuitableRestrASPH = false;
    this.rwySurfaceSuitableRestrASPHTRTD = false;
    this.rwySurfaceSuitableRestrCONC = false;
    this.rwySurfaceSuitableRestrCONCTRTD = false;
    this.rwySurfaceSuitableRestrASPHCONC = false;

    this.rwySurfaceSuitableRestrGravel = false;
    this.rwySurfaceSuitableRestrGRVL = false;
    this.rwySurfaceSuitableRestrGRVLTRTD = false;
    this.rwySurfaceSuitableRestrASPHGRVL = false;
    this.rwySurfaceSuitableRestrCONCGRVL = false;
    this.rwySurfaceSuitableRestrGRVLDIRT = false;
    this.rwySurfaceSuitableRestrGRVLTURF = false;
    this.rwySurfaceSuitableRestrCORAL = false;

    this.rwySurfaceSuitableRestrALLDIRT = false;
    this.rwySurfaceSuitableRestrDIRT = false;
    this.rwySurfaceSuitableRestrDIRTTRTD = false;
    this.rwySurfaceSuitableRestrASPHDIRT = false;
    this.rwySurfaceSuitableRestrDIRTGRVL = false;
    this.rwySurfaceSuitableRestrDIRTTURF = false;
    this.rwySurfaceSuitableRestrCALICHE = false;

    this.rwySurfaceSuitableRestrALLTURF = false;
    this.rwySurfaceSuitableRestrTURF = false;
    this.rwySurfaceSuitableRestrASPHTURF = false;
    this.rwySurfaceSuitableRestrCONCTURF = false;
    this.rwySurfaceSuitableRestrTURFGRVL = false;
    this.rwySurfaceSuitableRestrTURFDIRT = false;
    this.rwySurfaceSuitableRestrTURFSAND = false;
    this.rwySurfaceSuitableRestrOILCHIPT = false;
    this.rwySurfaceSuitableRestrSOD = false;
    this.rwySurfaceSuitableRestrGRASS = false;

    this.rwySurfaceSuitableRestrSnowAndIce = false;
    this.rwySurfaceSuitableRestrSNOW = false;
    this.rwySurfaceSuitableRestrICE = false;

    this.rwySurfaceSuitableRestrAllWater = false;
    this.rwySurfaceSuitableRestrWATER = false;

    this.rwySurfaceSuitableRestrOther = false;

    this.rwySurfaceSuitableRestrALUMINUM = false;
    this.rwySurfaceSuitableRestrBRICK = false;
    this.rwySurfaceSuitableRestrDECK = false;
    this.rwySurfaceSuitableRestrMATS = false;
    this.rwySurfaceSuitableRestrMETAL = false;

    this.rwySurfaceSuitableRestrROOFTOP = false;
    this.rwySurfaceSuitableRestrSAND = false;
    this.rwySurfaceSuitableRestrSTEEL = false;
    this.rwySurfaceSuitableRestrSTEELCONC = false;
    this.rwySurfaceSuitableRestrWOOD = false;
    /* END */
  }

  canItoModifyTheConfigurator(id: number): boolean {
    this.editButtonClicked = true;
    let result = true;
    this.isFieldDisabledForModifying = false;
    this.onChangeDisabled();
    this.editError = false;
    this.FormSuccessfullySubmitted = false;

    for (let tempId of this.closedForModifyingArray) {
        if (tempId === id) {
          result = false;
          this.isFieldDisabledForModifying = true;
          this.onChangeDisabled();
          this.editError = true;
          this.editButtonClicked = false;
        }
      }
    return result;
  }

  cancelButton(id: number) {
    this.canceledButtonClicked = true;
    if (!this.addNewAircraftClicked && !this.duplicateButtonClicked) {
      this.updateView(id);
    } else if (this.addNewAircraftClicked  || this.duplicateButtonClicked) {
      this.updateView(this.previousAircraftConfiguratorID);
      this.addNewAircraftClicked = false;
      this.duplicateButtonClicked = false;
    }
  }

  clearAllParamsInConfigurator() {
    if (this.isFieldDisabledForModifying === false) {
      this.clearButtonClicked = true;
      this.aircraftConfigurator.aircraftName = null;

      this.aircraftConfigurator.username = null;
      this.aircraftConfigurator.last_updated_date = null;

      this.aircraftConfigurator.elevationSuitableMin = null;
      this.aircraftConfigurator.elevationSuitableMinMeters = null;

      this.aircraftConfigurator.elevationSuitableMax = null;
      this.aircraftConfigurator.elevationSuitableMaxMeters = null;

      this.aircraftConfigurator.elevationSuitableRestrMin = null;
      this.aircraftConfigurator.elevationSuitableRestrMinMeters = null;

      this.aircraftConfigurator.elevationSuitableRestrMax = null;
      this.aircraftConfigurator.elevationSuitableRestrMaxMeters = null;

      this.aircraftConfigurator.elevationNotSuitableMin = null;
      this.aircraftConfigurator.elevationNotSuitableMax = null;

      this.aircraftConfigurator.elevationSuitableRemark = null;
      this.aircraftConfigurator.elevationSuitableRestrRemark = null;

      this.aircraftConfigurator.turnOnOffMaxElevationAll = false;
      this.aircraftConfigurator.turnOnOffMaxElevationSuitable = false;
      this.aircraftConfigurator.turnOnOffMaxElevationRestr = false;
      this.aircraftConfigurator.turnOnOffMaxElevationUnSuitable = false;

      this.aircraftConfigurator.rwyLengthSuitableMin = null;
      this.aircraftConfigurator.rwyLengthSuitableMinMeters = null;

      this.aircraftConfigurator.rwyLengthSuitableMax = null;
      this.aircraftConfigurator.rwyLengthSuitableMaxMeters = null;

      this.aircraftConfigurator.rwyLengthSuitableRestrMin = null;
      this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters = null;

      this.aircraftConfigurator.rwyLengthSuitableRestrMax = null;
      this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters = null;

      this.aircraftConfigurator.rwyLengthNotSuitableMin = null;
      this.aircraftConfigurator.rwyLengthNotSuitableMax = null;

      this.aircraftConfigurator.rwyLengthSuitableRemark = null;
      this.aircraftConfigurator.rwyLengthSuitableRestrRemark = null;

      this.aircraftConfigurator.turnOnOffMinRunwayLengthAll = false;
      this.aircraftConfigurator.turnOnOffMinRunwayLengthSuitable = false;
      this.aircraftConfigurator.turnOnOffMinRunwayLengthRestr = false;
      this.aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable = false;

      this.aircraftConfigurator.rwyWidthSuitableMin = null;
      this.aircraftConfigurator.rwyWidthSuitableMinMeters = null;

      this.aircraftConfigurator.rwyWidthSuitableMax = null;
      this.aircraftConfigurator.rwyWidthSuitableMaxMeters = null;

      this.aircraftConfigurator.rwyWidthSuitableRestrMin = null;
      this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters = null;

      this.aircraftConfigurator.rwyWidthSuitableRestrMax = null;
      this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters = null;

      this.aircraftConfigurator.rwyWidthNotSuitableMin = null;
      this.aircraftConfigurator.rwyWidthNotSuitableMax = null;

      this.aircraftConfigurator.rwyWidthSuitableRemark = null;
      this.aircraftConfigurator.rwyWidthSuitableRestrRemark = null;
   
      this.aircraftConfigurator.turnOnOffMinRunwayWidthAll = false;
      this.aircraftConfigurator.turnOnOffMinRunwayWidthSuitable = false;
      this.aircraftConfigurator.turnOnOffMinRunwayWidthRestr = false;
      this.aircraftConfigurator.turnOnOffMinRunwayWidthUnSuitable = false;

      this.aircraftConfigurator.rwySlopeSuitableMin = null;
      this.aircraftConfigurator.rwySlopeSuitableMax = null;
      this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin = null;
      this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax = null;
      this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin = null;
      this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax = null;
      this.aircraftConfigurator.rwySlopeNotSuitableMin = null;
      this.aircraftConfigurator.rwySlopeNotSuitableMax = null;

      this.aircraftConfigurator.rwySlopeSuitableRemark = null;
      this.aircraftConfigurator.rwySlopeSuitableFirstRestrRemark = null;
      this.aircraftConfigurator.rwySlopeSuitableSecondRestrRemark = null;

      this.aircraftConfigurator.turnOnOffMaxSlopeAll = false;
      this.aircraftConfigurator.turnOnOffMaxSlopeSuitable = false;
      this.aircraftConfigurator.turnOnOffMaxSlopeFirstRestr = false;
      this.aircraftConfigurator.turnOnOffMaxSlopeSecondRestr = false;
      this.aircraftConfigurator.turnOnOffMaxSlopeUnSuitable = false;

      this.aircraftConfigurator.rwySurfaceSuitable = null;
      this.aircraftConfigurator.rwySurfaceSuitableRestr = null;
      this.aircraftConfigurator.rwySurfaceNotSuitable = null;

      this.aircraftConfigurator.rwySurfaceSuitableRemark = null;
      this.aircraftConfigurator.rwySurfaceSuitableRestrRemark = null;
  
      this.aircraftConfigurator.turnOnOffSurfaceSuitableAll = false;
      this.aircraftConfigurator.turnOnOffSurfaceSuitable = false;
      this.aircraftConfigurator.turnOnOffSurfaceSuitableRestr = false;
      this.aircraftConfigurator.turnOnOffSurfaceUnSuitable = false;

      /* Make all param-s are false */
      this.rwySurfaceSuitableAsphaltAndConcrete = false;
      this.rwySurfaceSuitableASPH = false;
      this.rwySurfaceSuitableASPHTRTD = false;
      this.rwySurfaceSuitableCONC = false;
      this.rwySurfaceSuitableCONCTRTD = false;
      this.rwySurfaceSuitableASPHCONC = false;

      this.rwySurfaceSuitableGravel = false;
      this.rwySurfaceSuitableGRVL = false;
      this.rwySurfaceSuitableGRVLTRTD = false;
      this.rwySurfaceSuitableASPHGRVL = false;
      this.rwySurfaceSuitableCONCGRVL = false;
      this.rwySurfaceSuitableGRVLDIRT = false;
      this.rwySurfaceSuitableGRVLTURF = false;
      this.rwySurfaceSuitableCORAL = false;

      this.rwySurfaceSuitableALLDIRT = false;
      this.rwySurfaceSuitableDIRT = false;
      this.rwySurfaceSuitableDIRTTRTD = false;
      this.rwySurfaceSuitableASPHDIRT = false;
      this.rwySurfaceSuitableDIRTGRVL = false;
      this.rwySurfaceSuitableDIRTTURF = false;
      this.rwySurfaceSuitableCALICHE = false;

      this.rwySurfaceSuitableALLTURF = false;
      this.rwySurfaceSuitableTURF = false;
      this.rwySurfaceSuitableASPHTURF = false;
      this.rwySurfaceSuitableCONCTURF = false;
      this.rwySurfaceSuitableTURFGRVL = false;
      this.rwySurfaceSuitableTURFDIRT = false;
      this.rwySurfaceSuitableTURFSAND = false;
      this.rwySurfaceSuitableOILCHIPT = false;
      this.rwySurfaceSuitableSOD = false;
      this.rwySurfaceSuitableGRASS = false;

      this.rwySurfaceSuitableSnowAndIce = false;
      this.rwySurfaceSuitableSNOW = false;
      this.rwySurfaceSuitableICE = false;

      this.rwySurfaceSuitableAllWater = false;
      this.rwySurfaceSuitableWATER = false;

      this.rwySurfaceSuitableOther = false;

      this.rwySurfaceSuitableALUMINUM = false;
      this.rwySurfaceSuitableBRICK = false;
      this.rwySurfaceSuitableDECK = false;
      this.rwySurfaceSuitableMATS = false;
      this.rwySurfaceSuitableMETAL = false;

      this.rwySurfaceSuitableROOFTOP = false;
      this.rwySurfaceSuitableSAND = false;
      this.rwySurfaceSuitableSTEEL = false;
      this.rwySurfaceSuitableSTEELCONC = false;
      this.rwySurfaceSuitableWOOD = false;
      /* END */

      /* Make all param-s are false */
      this.rwySurfaceSuitableRestrAsphaltAndConcrete = false;
      this.rwySurfaceSuitableRestrASPH = false;
      this.rwySurfaceSuitableRestrASPHTRTD = false;
      this.rwySurfaceSuitableRestrCONC = false;
      this.rwySurfaceSuitableRestrCONCTRTD = false;
      this.rwySurfaceSuitableRestrASPHCONC = false;

      this.rwySurfaceSuitableRestrGravel = false;
      this.rwySurfaceSuitableRestrGRVL = false;
      this.rwySurfaceSuitableRestrGRVLTRTD = false;
      this.rwySurfaceSuitableRestrASPHGRVL = false;
      this.rwySurfaceSuitableRestrCONCGRVL = false;
      this.rwySurfaceSuitableRestrGRVLDIRT = false;
      this.rwySurfaceSuitableRestrGRVLTURF = false;
      this.rwySurfaceSuitableRestrCORAL = false;

      this.rwySurfaceSuitableRestrALLDIRT = false;
      this.rwySurfaceSuitableRestrDIRT = false;
      this.rwySurfaceSuitableRestrDIRTTRTD = false;
      this.rwySurfaceSuitableRestrASPHDIRT = false;
      this.rwySurfaceSuitableRestrDIRTGRVL = false;
      this.rwySurfaceSuitableRestrDIRTTURF = false;
      this.rwySurfaceSuitableRestrCALICHE = false;

      this.rwySurfaceSuitableRestrALLTURF = false;
      this.rwySurfaceSuitableRestrTURF = false;
      this.rwySurfaceSuitableRestrASPHTURF = false;
      this.rwySurfaceSuitableRestrCONCTURF = false;
      this.rwySurfaceSuitableRestrTURFGRVL = false;
      this.rwySurfaceSuitableRestrTURFDIRT = false;
      this.rwySurfaceSuitableRestrTURFSAND = false;
      this.rwySurfaceSuitableRestrOILCHIPT = false;
      this.rwySurfaceSuitableRestrSOD = false;
      this.rwySurfaceSuitableRestrGRASS = false;

      this.rwySurfaceSuitableRestrSnowAndIce = false;
      this.rwySurfaceSuitableRestrSNOW = false;
      this.rwySurfaceSuitableRestrICE = false;

      this.rwySurfaceSuitableRestrAllWater = false;
      this.rwySurfaceSuitableRestrWATER = false;

      this.rwySurfaceSuitableRestrOther = false;

      this.rwySurfaceSuitableRestrALUMINUM = false;
      this.rwySurfaceSuitableRestrBRICK = false;
      this.rwySurfaceSuitableRestrDECK = false;
      this.rwySurfaceSuitableRestrMATS = false;
      this.rwySurfaceSuitableRestrMETAL = false;

      this.rwySurfaceSuitableRestrROOFTOP = false;
      this.rwySurfaceSuitableRestrSAND = false;
      this.rwySurfaceSuitableRestrSTEEL = false;
      this.rwySurfaceSuitableRestrSTEELCONC = false;
      this.rwySurfaceSuitableRestrWOOD = false;
      /* END */

      this.aircraftConfigurator.rwyStrengthSuitableMin = null;
      this.aircraftConfigurator.rwyStrengthSuitableMax = null;
      this.aircraftConfigurator.rwyStrengthSuitableRestrMin = null;
      this.aircraftConfigurator.rwyStrengthSuitableRestrMax = null;
      this.aircraftConfigurator.rwyStrengthNotSuitableMin = null;
      this.aircraftConfigurator.rwyStrengthNotSuitableMax = null;

      this.aircraftConfigurator.rwyStrengthSuitableRemark = null;
      this.aircraftConfigurator.rwyStrengthSuitableRestRemark = null;

      this.aircraftConfigurator.turnOnOffMinRunwayStrengthAll = false;
      this.aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable = false;
      this.aircraftConfigurator.turnOnOffMinRunwayStrengthRestr = false;
      this.aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable = false;

      this.aircraftConfigurator.refCodeSuitableMin = null;
      this.aircraftConfigurator.refCodeSuitableMax = null;
      this.aircraftConfigurator.refCodeSuitableRestrMin = null;
      this.aircraftConfigurator.refCodeSuitableRestrMax = null;
      this.aircraftConfigurator.refCodeNotSuitableMin = null;
      this.aircraftConfigurator.refCodeNotSuitableMax = null;

      this.aircraftConfigurator.arffSuitableMin = null;
      this.aircraftConfigurator.arffSuitableMax = null;
      this.aircraftConfigurator.arffSuitableRestrMin = null;
      this.aircraftConfigurator.arffSuitableRestrMax = null;
      this.aircraftConfigurator.arffNotSuitableMin = null;
      this.aircraftConfigurator.arffNotSuitableMax = null;

      this.aircraftConfigurator.arffsuitableremark = null;
      this.aircraftConfigurator.arffsuitablerestrremark = null;

      this.aircraftConfigurator.turnOnOffMinARFFAll = false;
      this.aircraftConfigurator.turnOnOffMinARFFSuitable = false;
      this.aircraftConfigurator.turnOnOffMinARFFRestr = false;
      this.aircraftConfigurator.turnOnOffMinARFFUnSuitable = false;

      this.aircraftConfigurator.aircraftMTOW = null;
      this.aircraftConfigurator.aircraftMTOW_KG = null;

      this.aircraftConfigurator.aircraftDOW = null;
      this.aircraftConfigurator.aircraftDOW_KG = null;

      this.aircraftConfigurator.acn_MTOW_F_A = null;
      this.aircraftConfigurator.acn_MTOW_F_B = null;
      this.aircraftConfigurator.acn_MTOW_F_C = null;
      this.aircraftConfigurator.acn_MTOW_F_D = null;

      this.aircraftConfigurator.acn_MTOW_R_A = null;
      this.aircraftConfigurator.acn_MTOW_R_B = null;
      this.aircraftConfigurator.acn_MTOW_R_C = null;
      this.aircraftConfigurator.acn_MTOW_R_D = null;

      this.aircraftConfigurator.acn_DOW_F_A = null;
      this.aircraftConfigurator.acn_DOW_F_B = null;
      this.aircraftConfigurator.acn_DOW_F_C = null;
      this.aircraftConfigurator.acn_DOW_F_D = null;

      this.aircraftConfigurator.acn_DOW_R_A = null;
      this.aircraftConfigurator.acn_DOW_R_B = null;
      this.aircraftConfigurator.acn_DOW_R_C = null;
      this.aircraftConfigurator.acn_DOW_R_D = null;

      this.aircraftConfigurator.mtow_SW = null;
      this.aircraftConfigurator.mtow_DW = null;
      this.aircraftConfigurator.mtow_DT = null;
      this.aircraftConfigurator.mtow_DDT = null;

      this.aircraftConfigurator.dow_SW = null;
      this.aircraftConfigurator.dow_DW = null;
      this.aircraftConfigurator.dow_DT = null;
      this.aircraftConfigurator.dow_DDT = null;
    } else {
      this.clearError = true;
    }
  }

  buttonApplyDisabled(): boolean {
    let result = true;
    if (this.editButtonClicked === true && this.isFieldDisabledForModifying === false && this.iSformIncorret() === false ||
        this.addNewAircraftClicked === true && this.isFieldDisabledForModifying === false && this.iSformIncorret() === false ||
        this.duplicateButtonClicked === true && this.isFieldDisabledForModifying === false && this.iSformIncorret() === false) {
      result = false;
    } else if (this.editButtonClicked === true && this.isFieldDisabledForModifying === false && this.iSformIncorret() === true || 
              this.addNewAircraftClicked === true && this.isFieldDisabledForModifying === false && this.iSformIncorret() === true ||
              this.duplicateButtonClicked === true && this.isFieldDisabledForModifying === false && this.iSformIncorret() === true) {
      result = true;
    }
    return result;
  }
  
  iSformIncorret(): boolean {
    let result = false;
    if (this.aircraftConfigurator.aircraftName === null || this.aircraftConfigurator.aircraftName === '' ) {
      this.formErrors = true;
      result = true;
    }

    if (
      this.aircraftConfigurator.elevationSuitableMin >= this.aircraftConfigurator.elevationSuitableMax &&
      this.aircraftConfigurator.elevationSuitableMin !== null && this.aircraftConfigurator.elevationSuitableMax !== null
      ||
      this.aircraftConfigurator.elevationSuitableMin === null && this.aircraftConfigurator.elevationSuitableMax !== null
      ||
      this.aircraftConfigurator.elevationSuitableMax === null && this.aircraftConfigurator.elevationSuitableMin !== null
      ||
      this.aircraftConfigurator.elevationSuitableRestrMin >= this.aircraftConfigurator.elevationSuitableRestrMax &&
      this.aircraftConfigurator.elevationSuitableRestrMin !== null && this.aircraftConfigurator.elevationSuitableRestrMax !== null
      ||
      this.aircraftConfigurator.elevationSuitableRestrMin === null && this.aircraftConfigurator.elevationSuitableRestrMax !== null
      ||
      this.aircraftConfigurator.elevationSuitableRestrMax === null && this.aircraftConfigurator.elevationSuitableRestrMin !== null
    ) {
      this.formErrors = true;
      result = true;
    }

    if (
      this.aircraftConfigurator.rwyLengthSuitableMin >= this.aircraftConfigurator.rwyLengthSuitableMax &&
      this.aircraftConfigurator.rwyLengthSuitableMin !== null && this.aircraftConfigurator.rwyLengthSuitableMax !== null
      ||
      this.aircraftConfigurator.rwyLengthSuitableMin === null && this.aircraftConfigurator.rwyLengthSuitableMax !== null
      ||
      this.aircraftConfigurator.rwyLengthSuitableMax === null && this.aircraftConfigurator.rwyLengthSuitableMin !== null
      ||
      this.aircraftConfigurator.rwyLengthSuitableRestrMin >= this.aircraftConfigurator.rwyLengthSuitableRestrMax &&
      this.aircraftConfigurator.rwyLengthSuitableRestrMin !== null && this.aircraftConfigurator.rwyLengthSuitableRestrMax !== null
      ||
      this.aircraftConfigurator.rwyLengthSuitableRestrMin === null && this.aircraftConfigurator.rwyLengthSuitableRestrMax !== null
      ||
      this.aircraftConfigurator.rwyLengthSuitableRestrMax === null && this.aircraftConfigurator.rwyLengthSuitableRestrMin !== null
      ) {
      this.formErrors = true;
      result = true;
    }

    if (
      this.aircraftConfigurator.rwyWidthSuitableMin >= this.aircraftConfigurator.rwyWidthSuitableMax &&
      this.aircraftConfigurator.rwyWidthSuitableMin !== null && this.aircraftConfigurator.rwyWidthSuitableMax !== null
      ||
      this.aircraftConfigurator.rwyWidthSuitableMin === null && this.aircraftConfigurator.rwyWidthSuitableMax !== null
      ||
      this.aircraftConfigurator.rwyWidthSuitableMax === null && this.aircraftConfigurator.rwyWidthSuitableMin !== null
      ||
      this.aircraftConfigurator.rwyWidthSuitableRestrMin >= this.aircraftConfigurator.rwyWidthSuitableRestrMax &&
      this.aircraftConfigurator.rwyWidthSuitableRestrMin !== null && this.aircraftConfigurator.rwyWidthSuitableRestrMax !== null
      ||
      this.aircraftConfigurator.rwyWidthSuitableRestrMin === null && this.aircraftConfigurator.rwyWidthSuitableRestrMax !== null
      ||
      this.aircraftConfigurator.rwyWidthSuitableRestrMax === null && this.aircraftConfigurator.rwyWidthSuitableRestrMin !== null
      ) {
      this.formErrors = true;
      result = true;
    }

    if (
      this.aircraftConfigurator.rwySlopeSuitableMin >= this.aircraftConfigurator.rwySlopeSuitableMax &&
      this.aircraftConfigurator.rwySlopeSuitableMin !== null && this.aircraftConfigurator.rwySlopeSuitableMax !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableMin === null && this.aircraftConfigurator.rwySlopeSuitableMax !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableMax === null && this.aircraftConfigurator.rwySlopeSuitableMin !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin >= this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax &&
      this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin !== null && this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin === null && this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax === null && this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin >= this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax &&
      this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin !== null && this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin === null && this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax !== null
      ||
      this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax === null && this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin !== null
      ) {
      this.formErrors = true;
      result = true;
    }

    if (
        (this.aircraftConfigurator.aircraftDOW >= this.aircraftConfigurator.aircraftMTOW &&
        this.aircraftConfigurator.aircraftMTOW !== null && this.aircraftConfigurator.aircraftDOW !== null) ||
        (this.aircraftConfigurator.aircraftMTOW === null && this.aircraftConfigurator.aircraftDOW !== null) ||
        (this.aircraftConfigurator.aircraftDOW === null && this.aircraftConfigurator.aircraftMTOW !== null) ||
        (0 >= this.aircraftConfigurator.aircraftMTOW && this.aircraftConfigurator.aircraftMTOW !== null) ||
        (0 >= this.aircraftConfigurator.aircraftDOW && this.aircraftConfigurator.aircraftDOW !== null) ||

        (this.aircraftConfigurator.acn_DOW_R_A >= this.aircraftConfigurator.acn_MTOW_R_A &&
        this.aircraftConfigurator.acn_DOW_R_A !== null && this.aircraftConfigurator.acn_MTOW_R_A !== null) ||
        (this.aircraftConfigurator.acn_MTOW_R_A === null && this.aircraftConfigurator.acn_DOW_R_A !== null) ||
        (this.aircraftConfigurator.acn_DOW_R_A === null && this.aircraftConfigurator.acn_MTOW_R_A !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_R_A && this.aircraftConfigurator.acn_MTOW_R_A !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_R_A && this.aircraftConfigurator.acn_DOW_R_A !== null) ||
        
        (this.aircraftConfigurator.acn_DOW_R_B >= this.aircraftConfigurator.acn_MTOW_R_B &&
        this.aircraftConfigurator.acn_DOW_R_B !== null && this.aircraftConfigurator.acn_MTOW_R_B !== null) ||
        (this.aircraftConfigurator.acn_MTOW_R_B === null && this.aircraftConfigurator.acn_DOW_R_B !== null) ||
        (this.aircraftConfigurator.acn_DOW_R_B === null && this.aircraftConfigurator.acn_MTOW_R_B !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_R_B && this.aircraftConfigurator.acn_MTOW_R_B !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_R_B && this.aircraftConfigurator.acn_DOW_R_B !== null) ||

        (this.aircraftConfigurator.acn_DOW_R_C >= this.aircraftConfigurator.acn_MTOW_R_C &&
        this.aircraftConfigurator.acn_DOW_R_C !== null && this.aircraftConfigurator.acn_MTOW_R_C !== null) ||
        (this.aircraftConfigurator.acn_MTOW_R_C === null && this.aircraftConfigurator.acn_DOW_R_C !== null) ||
        (this.aircraftConfigurator.acn_DOW_R_C === null && this.aircraftConfigurator.acn_MTOW_R_C !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_R_C && this.aircraftConfigurator.acn_MTOW_R_C !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_R_C && this.aircraftConfigurator.acn_DOW_R_C !== null) ||

        (this.aircraftConfigurator.acn_DOW_R_D >= this.aircraftConfigurator.acn_MTOW_R_D &&
        this.aircraftConfigurator.acn_DOW_R_D !== null && this.aircraftConfigurator.acn_MTOW_R_D !== null) ||
        (this.aircraftConfigurator.acn_MTOW_R_D === null && this.aircraftConfigurator.acn_DOW_R_D !== null) ||
        (this.aircraftConfigurator.acn_DOW_R_D === null && this.aircraftConfigurator.acn_MTOW_R_D !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_R_D && this.aircraftConfigurator.acn_MTOW_R_D !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_R_D && this.aircraftConfigurator.acn_DOW_R_D !== null) ||

        (this.aircraftConfigurator.acn_DOW_F_A >= this.aircraftConfigurator.acn_MTOW_F_A &&
        this.aircraftConfigurator.acn_DOW_F_A !== null && this.aircraftConfigurator.acn_MTOW_F_A !== null) ||
        (this.aircraftConfigurator.acn_MTOW_F_A === null && this.aircraftConfigurator.acn_DOW_F_A !== null) ||
        (this.aircraftConfigurator.acn_DOW_F_A === null && this.aircraftConfigurator.acn_MTOW_F_A !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_F_A && this.aircraftConfigurator.acn_MTOW_F_A !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_F_A && this.aircraftConfigurator.acn_DOW_F_A !== null) ||

        (this.aircraftConfigurator.acn_DOW_F_B >= this.aircraftConfigurator.acn_MTOW_F_B &&
        this.aircraftConfigurator.acn_DOW_F_B !== null && this.aircraftConfigurator.acn_MTOW_F_B !== null) ||
        (this.aircraftConfigurator.acn_MTOW_F_B === null && this.aircraftConfigurator.acn_DOW_F_B !== null) ||
        (this.aircraftConfigurator.acn_DOW_F_B === null && this.aircraftConfigurator.acn_MTOW_F_B !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_F_B && this.aircraftConfigurator.acn_MTOW_F_B !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_F_B && this.aircraftConfigurator.acn_DOW_F_B !== null) ||

        (this.aircraftConfigurator.acn_DOW_F_C >= this.aircraftConfigurator.acn_MTOW_F_C &&
        this.aircraftConfigurator.acn_DOW_F_C !== null && this.aircraftConfigurator.acn_MTOW_F_C !== null) ||
        (this.aircraftConfigurator.acn_MTOW_F_C === null && this.aircraftConfigurator.acn_DOW_F_C !== null) ||
        (this.aircraftConfigurator.acn_DOW_F_C === null && this.aircraftConfigurator.acn_MTOW_F_C !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_F_C && this.aircraftConfigurator.acn_MTOW_F_C !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_F_C && this.aircraftConfigurator.acn_DOW_F_C !== null) ||

        (this.aircraftConfigurator.acn_DOW_F_D >= this.aircraftConfigurator.acn_MTOW_F_D &&
        this.aircraftConfigurator.acn_DOW_F_D !== null && this.aircraftConfigurator.acn_MTOW_F_D !== null) ||
        (this.aircraftConfigurator.acn_MTOW_F_D === null && this.aircraftConfigurator.acn_DOW_F_D !== null) ||
        (this.aircraftConfigurator.acn_DOW_F_D === null && this.aircraftConfigurator.acn_MTOW_F_D !== null) ||
        (0 >= this.aircraftConfigurator.acn_MTOW_F_D && this.aircraftConfigurator.acn_MTOW_F_D !== null) ||
        (0 >= this.aircraftConfigurator.acn_DOW_F_D && this.aircraftConfigurator.acn_DOW_F_D !== null) ||

        (this.aircraftConfigurator.dow_SW >= this.aircraftConfigurator.mtow_SW &&
        this.aircraftConfigurator.dow_SW !== null && this.aircraftConfigurator.mtow_SW !== null) ||
        (this.aircraftConfigurator.mtow_SW === null && this.aircraftConfigurator.dow_SW !== null) ||
        (this.aircraftConfigurator.dow_SW === null && this.aircraftConfigurator.mtow_SW !== null) ||
        (0 >= this.aircraftConfigurator.mtow_SW && this.aircraftConfigurator.mtow_SW !== null) ||
        (0 >= this.aircraftConfigurator.dow_SW && this.aircraftConfigurator.dow_SW !== null) ||

        (this.aircraftConfigurator.dow_DW >= this.aircraftConfigurator.mtow_DW &&
        this.aircraftConfigurator.dow_DW !== null && this.aircraftConfigurator.mtow_DW !== null) ||
        (this.aircraftConfigurator.mtow_DW === null && this.aircraftConfigurator.dow_DW !== null) ||
        (this.aircraftConfigurator.dow_DW === null && this.aircraftConfigurator.mtow_DW !== null) ||
        (0 >= this.aircraftConfigurator.mtow_DW && this.aircraftConfigurator.mtow_DW !== null) ||
        (0 >= this.aircraftConfigurator.dow_DW && this.aircraftConfigurator.dow_DW !== null) ||

        (this.aircraftConfigurator.dow_DT >= this.aircraftConfigurator.mtow_DT &&
        this.aircraftConfigurator.dow_DT !== null && this.aircraftConfigurator.mtow_DT !== null) ||
        (this.aircraftConfigurator.mtow_DT === null && this.aircraftConfigurator.dow_DT !== null) ||
        (this.aircraftConfigurator.dow_DT === null && this.aircraftConfigurator.mtow_DT !== null) ||
        (0 >= this.aircraftConfigurator.mtow_DT && this.aircraftConfigurator.mtow_DT !== null) ||
        (0 >= this.aircraftConfigurator.dow_DT && this.aircraftConfigurator.dow_DT !== null) ||

        (this.aircraftConfigurator.dow_DDT >= this.aircraftConfigurator.mtow_DDT &&
        this.aircraftConfigurator.dow_DDT !== null && this.aircraftConfigurator.mtow_DDT !== null) ||
        (this.aircraftConfigurator.mtow_DDT === null && this.aircraftConfigurator.dow_DDT !== null) ||
        (this.aircraftConfigurator.dow_DDT === null && this.aircraftConfigurator.mtow_DDT !== null) ||
        (0 >= this.aircraftConfigurator.mtow_DDT && this.aircraftConfigurator.mtow_DDT !== null) ||
        (0 >= this.aircraftConfigurator.dow_DDT && this.aircraftConfigurator.dow_DDT !== null) ||

        (this.aircraftConfigurator.rwyStrengthSuitableMin >= this.aircraftConfigurator.rwyStrengthSuitableMax && 
        this.aircraftConfigurator.rwyStrengthSuitableMin !== null && this.aircraftConfigurator.rwyStrengthSuitableMax !== null) ||
  
        (this.aircraftConfigurator.rwyStrengthSuitableMin === null && this.aircraftConfigurator.rwyStrengthSuitableMax !== null) ||
        (0 > this.aircraftConfigurator.rwyStrengthSuitableMin && this.aircraftConfigurator.rwyStrengthSuitableMin !== null) ||

        (this.aircraftConfigurator.rwyStrengthSuitableMax === null && this.aircraftConfigurator.rwyStrengthSuitableMin !== null) ||
        (0 > this.aircraftConfigurator.rwyStrengthSuitableMax && this.aircraftConfigurator.rwyStrengthSuitableMax !== null) ||

        (this.aircraftConfigurator.rwyStrengthSuitableRestrMin >= this.aircraftConfigurator.rwyStrengthSuitableRestrMax &&
        this.aircraftConfigurator.rwyStrengthSuitableRestrMin !== null && this.aircraftConfigurator.rwyStrengthSuitableRestrMax !== null) ||
        
        (this.aircraftConfigurator.rwyStrengthSuitableRestrMin === null && this.aircraftConfigurator.rwyStrengthSuitableRestrMax !== null) ||
        (0 > this.aircraftConfigurator.rwyStrengthSuitableRestrMin && this.aircraftConfigurator.rwyStrengthSuitableRestrMin !== null) ||

        (this.aircraftConfigurator.rwyStrengthSuitableRestrMax === null && this.aircraftConfigurator.rwyStrengthSuitableRestrMin !== null) ||
        (0 > this.aircraftConfigurator.rwyStrengthSuitableRestrMax && this.aircraftConfigurator.rwyStrengthSuitableRestrMax !== null)
        ) {
          this.formErrors = true;
          result = true;
  }

    if (
      this.aircraftConfigurator.arffSuitableMin > this.aircraftConfigurator.arffSuitableMax &&
      this.aircraftConfigurator.arffSuitableMin && this.aircraftConfigurator.arffSuitableMax ||
      !this.aircraftConfigurator.arffSuitableMin && this.aircraftConfigurator.arffSuitableMax ||
      !this.aircraftConfigurator.arffSuitableMax && this.aircraftConfigurator.arffSuitableMin ||

      this.aircraftConfigurator.arffSuitableRestrMin > this.aircraftConfigurator.arffSuitableRestrMax &&
      this.aircraftConfigurator.arffSuitableRestrMin && this.aircraftConfigurator.arffSuitableRestrMax ||
      !this.aircraftConfigurator.arffSuitableRestrMin && this.aircraftConfigurator.arffSuitableRestrMax ||
      !this.aircraftConfigurator.arffSuitableRestrMax && this.aircraftConfigurator.arffSuitableRestrMin
      ) {
      this.formErrors = true;
      result = true;
    }
    return result;
  }

  additionalOpsBeforeSubmitting() {
    this.applyButtonClicked = true;
    this.formErrors = false;

    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
      let tmpArray: string[];
      tmpArray = [];
      for (let i of this.aircraftConfigurator.rwySurfaceSuitableArray) {
        tmpArray.push(i);
      }
      this.aircraftConfigurator.rwySurfaceSuitable = tmpArray.toString();
    }
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
      let tmpArray: string[];
      tmpArray = [];
      for (let i of this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        tmpArray.push(i);
      }
      this.aircraftConfigurator.rwySurfaceSuitableRestr = tmpArray.toString();
    }

    this.aircraftConfigurator.last_updated_date = new Date();

    if (this.aircraftConfigurators) {
    for (let i = 0; i < this.aircraftConfigurators.length; i++) {
      if (this.aircraftConfigurator.idConfigurator === this.aircraftConfigurators[i].idConfigurator) {
        this.aircraftConfigurators[i].idConfigurator = this.aircraftConfigurator.idConfigurator;
        
        if (this.aircraftConfigurators[i].elevationSuitableMin !== this.aircraftConfigurator.elevationSuitableMin ||
            this.aircraftConfigurators[i].elevationSuitableMinMeters !== this.aircraftConfigurator.elevationSuitableMinMeters ||
            
            this.aircraftConfigurators[i].elevationSuitableMax !== this.aircraftConfigurator.elevationSuitableMax ||
            this.aircraftConfigurators[i].elevationSuitableMaxMeters !== this.aircraftConfigurator.elevationSuitableMaxMeters ||
            
            this.aircraftConfigurators[i].elevationSuitableRestrMin !== this.aircraftConfigurator.elevationSuitableRestrMin ||
            this.aircraftConfigurators[i].elevationSuitableRestrMinMeters !== this.aircraftConfigurator.elevationSuitableRestrMinMeters ||
            
            this.aircraftConfigurators[i].elevationSuitableRestrMax !== this.aircraftConfigurator.elevationSuitableRestrMax ||
            this.aircraftConfigurators[i].elevationSuitableRestrMaxMeters !== this.aircraftConfigurator.elevationSuitableRestrMaxMeters ||
            
            this.aircraftConfigurators[i].elevationNotSuitableMin !== this.aircraftConfigurator.elevationNotSuitableMin ||
            this.aircraftConfigurators[i].elevationNotSuitableMax !== this.aircraftConfigurator.elevationNotSuitableMax ||
            
            this.aircraftConfigurators[i].elevationSuitableRemark !== this.aircraftConfigurator.elevationSuitableRemark ||
            this.aircraftConfigurators[i].elevationSuitableRestrRemark !== this.aircraftConfigurator.elevationSuitableRestrRemark ||
            
            this.aircraftConfigurators[i].turnOnOffMaxElevationAll !== this.aircraftConfigurator.turnOnOffMaxElevationAll ||
            this.aircraftConfigurators[i].turnOnOffMaxElevationSuitable !== this.aircraftConfigurator.turnOnOffMaxElevationSuitable ||
            this.aircraftConfigurators[i].turnOnOffMaxElevationRestr !== this.aircraftConfigurator.turnOnOffMaxElevationRestr ||
            this.aircraftConfigurators[i].turnOnOffMaxElevationUnSuitable !== this.aircraftConfigurator.turnOnOffMaxElevationUnSuitable ||
            
            this.aircraftConfigurators[i].rwyLengthSuitableMin !== this.aircraftConfigurator.rwyLengthSuitableMin ||
            this.aircraftConfigurators[i].rwyLengthSuitableMinMeters !== this.aircraftConfigurator.rwyLengthSuitableMinMeters ||
            
            this.aircraftConfigurators[i].rwyLengthSuitableMax !== this.aircraftConfigurator.rwyLengthSuitableMax ||
            this.aircraftConfigurators[i].rwyLengthSuitableMaxMeters !== this.aircraftConfigurator.rwyLengthSuitableMaxMeters ||
            
            this.aircraftConfigurators[i].rwyLengthSuitableRestrMin !== this.aircraftConfigurator.rwyLengthSuitableRestrMin ||
            this.aircraftConfigurators[i].rwyLengthSuitableRestrMinMeters !== this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters ||
            
            this.aircraftConfigurators[i].rwyLengthSuitableRestrMax !== this.aircraftConfigurator.rwyLengthSuitableRestrMax ||
            this.aircraftConfigurators[i].rwyLengthSuitableRestrMaxMeters !== this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters ||
            
            this.aircraftConfigurators[i].rwyLengthNotSuitableMin !== this.aircraftConfigurator.rwyLengthNotSuitableMin ||
            this.aircraftConfigurators[i].rwyLengthNotSuitableMax !== this.aircraftConfigurator.rwyLengthNotSuitableMax ||
            
            this.aircraftConfigurators[i].rwyLengthSuitableRemark !== this.aircraftConfigurator.rwyLengthSuitableRemark ||
            this.aircraftConfigurators[i].rwyLengthSuitableRestrRemark !== this.aircraftConfigurator.rwyLengthSuitableRestrRemark ||
            
            this.aircraftConfigurators[i].turnOnOffMinRunwayLengthAll !== this.aircraftConfigurator.turnOnOffMinRunwayLengthAll ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayLengthSuitable !== this.aircraftConfigurator.turnOnOffMinRunwayLengthSuitable ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayLengthRestr !== this.aircraftConfigurator.turnOnOffMinRunwayLengthRestr ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayLengthUnSuitable !== this.aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable ||
            
            this.aircraftConfigurators[i].rwyWidthSuitableMin !== this.aircraftConfigurator.rwyWidthSuitableMin ||
            this.aircraftConfigurators[i].rwyWidthSuitableMinMeters !== this.aircraftConfigurator.rwyWidthSuitableMinMeters ||
            
            this.aircraftConfigurators[i].rwyWidthSuitableMax !== this.aircraftConfigurator.rwyWidthSuitableMax ||
            this.aircraftConfigurators[i].rwyWidthSuitableMaxMeters !== this.aircraftConfigurator.rwyWidthSuitableMaxMeters ||
            
            this.aircraftConfigurators[i].rwyWidthSuitableRestrMin !== this.aircraftConfigurator.rwyWidthSuitableRestrMin ||
            this.aircraftConfigurators[i].rwyWidthSuitableRestrMinMeters !== this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters ||
            
            this.aircraftConfigurators[i].rwyWidthSuitableRestrMax !== this.aircraftConfigurator.rwyWidthSuitableRestrMax ||
            this.aircraftConfigurators[i].rwyWidthSuitableRestrMaxMeters !== this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters ||
            
            this.aircraftConfigurators[i].rwyWidthNotSuitableMin !== this.aircraftConfigurator.rwyWidthNotSuitableMin ||
            this.aircraftConfigurators[i].rwyWidthNotSuitableMax !== this.aircraftConfigurator.rwyWidthNotSuitableMax ||
            
            this.aircraftConfigurators[i].rwyWidthSuitableRemark !== this.aircraftConfigurator.rwyWidthSuitableRemark ||
            this.aircraftConfigurators[i].rwyWidthSuitableRestrRemark !== this.aircraftConfigurator.rwyWidthSuitableRestrRemark ||
            
            this.aircraftConfigurators[i].turnOnOffMinRunwayWidthAll !== this.aircraftConfigurator.turnOnOffMinRunwayWidthAll ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayWidthSuitable !== this.aircraftConfigurator.turnOnOffMinRunwayWidthSuitable ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayWidthRestr !== this.aircraftConfigurator.turnOnOffMinRunwayWidthRestr ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayWidthUnSuitable !== this.aircraftConfigurator.turnOnOffMinRunwayWidthUnSuitable ||
            
            this.aircraftConfigurators[i].rwySlopeSuitableMin !== this.aircraftConfigurator.rwySlopeSuitableMin ||
            this.aircraftConfigurators[i].rwySlopeSuitableMax !== this.aircraftConfigurator.rwySlopeSuitableMax ||
            this.aircraftConfigurators[i].rwySlopeSuitableMin !== this.aircraftConfigurator.rwySlopeSuitableMin ||
            this.aircraftConfigurators[i].rwySlopeSuitableMax !== this.aircraftConfigurator.rwySlopeSuitableMax ||
            this.aircraftConfigurators[i].rwySlopeSuitableFirstRestrMin !== this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin ||
            this.aircraftConfigurators[i].rwySlopeSuitableFirstRestrMax !== this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax ||
            this.aircraftConfigurators[i].rwySlopeSuitableSecondRestrMin !== this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin ||
            this.aircraftConfigurators[i].rwySlopeSuitableSecondRestrMax !== this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax ||
            this.aircraftConfigurators[i].rwySlopeNotSuitableMin !== this.aircraftConfigurator.rwySlopeNotSuitableMin ||
            this.aircraftConfigurators[i].rwySlopeNotSuitableMax !== this.aircraftConfigurator.rwySlopeNotSuitableMax ||
            
            this.aircraftConfigurators[i].rwySlopeSuitableRemark !== this.aircraftConfigurator.rwySlopeSuitableRemark ||
            this.aircraftConfigurators[i].rwySlopeSuitableFirstRestrRemark !== this.aircraftConfigurator.rwySlopeSuitableFirstRestrRemark ||
            this.aircraftConfigurators[i].rwySlopeSuitableSecondRestrRemark !== this.aircraftConfigurator.rwySlopeSuitableSecondRestrRemark ||
            
            this.aircraftConfigurators[i].turnOnOffMaxSlopeAll !== this.aircraftConfigurator.turnOnOffMaxSlopeAll ||
            this.aircraftConfigurators[i].turnOnOffMaxSlopeSuitable !== this.aircraftConfigurator.turnOnOffMaxSlopeSuitable ||
            this.aircraftConfigurators[i].turnOnOffMaxSlopeFirstRestr !== this.aircraftConfigurator.turnOnOffMaxSlopeFirstRestr ||
            this.aircraftConfigurators[i].turnOnOffMaxSlopeSecondRestr !== this.aircraftConfigurator.turnOnOffMaxSlopeSecondRestr ||
            this.aircraftConfigurators[i].turnOnOffMaxSlopeUnSuitable !== this.aircraftConfigurator.turnOnOffMaxSlopeUnSuitable ||
            
            this.aircraftConfigurators[i].rwySurfaceSuitable !== this.aircraftConfigurator.rwySurfaceSuitable ||
            this.aircraftConfigurators[i].rwySurfaceSuitableRestr !== this.aircraftConfigurator.rwySurfaceSuitableRestr ||
            this.aircraftConfigurators[i].rwySurfaceNotSuitable !== this.aircraftConfigurator.rwySurfaceNotSuitable ||
            
            this.aircraftConfigurators[i].rwySurfaceSuitableRemark !== this.aircraftConfigurator.rwySurfaceSuitableRemark ||
            this.aircraftConfigurators[i].rwySurfaceSuitableRestrRemark !== this.aircraftConfigurator.rwySurfaceSuitableRestrRemark ||
            
            this.aircraftConfigurators[i].turnOnOffSurfaceSuitableAll !== this.aircraftConfigurator.turnOnOffSurfaceSuitableAll ||
            this.aircraftConfigurators[i].turnOnOffSurfaceSuitable !== this.aircraftConfigurator.turnOnOffSurfaceSuitable ||
            this.aircraftConfigurators[i].turnOnOffSurfaceSuitableRestr !== this.aircraftConfigurator.turnOnOffSurfaceSuitableRestr ||
            this.aircraftConfigurators[i].turnOnOffSurfaceUnSuitable !== this.aircraftConfigurator.turnOnOffSurfaceUnSuitable ||
            
            this.aircraftConfigurators[i].rwyStrengthSuitableMin !== this.aircraftConfigurator.rwyStrengthSuitableMin ||
            this.aircraftConfigurators[i].rwyStrengthSuitableMax !== this.aircraftConfigurator.rwyStrengthSuitableMax ||
            this.aircraftConfigurators[i].rwyStrengthSuitableRestrMin !== this.aircraftConfigurator.rwyStrengthSuitableRestrMin ||
            this.aircraftConfigurators[i].rwyStrengthSuitableRestrMax !== this.aircraftConfigurator.rwyStrengthSuitableRestrMax ||
            this.aircraftConfigurators[i].rwyStrengthNotSuitableMin !== this.aircraftConfigurator.rwyStrengthNotSuitableMin ||
            this.aircraftConfigurators[i].rwyStrengthNotSuitableMax !== this.aircraftConfigurator.rwyStrengthNotSuitableMax ||
            
            this.aircraftConfigurators[i].rwyStrengthSuitableRemark !== this.aircraftConfigurator.rwyStrengthSuitableRemark ||
            this.aircraftConfigurators[i].rwyStrengthSuitableRestRemark !== this.aircraftConfigurator.rwyStrengthSuitableRestRemark ||
            
            this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthAll !== this.aircraftConfigurator.turnOnOffMinRunwayStrengthAll ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthSuitable !== this.aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthRestr !== this.aircraftConfigurator.turnOnOffMinRunwayStrengthRestr ||
            this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthUnSuitable !== this.aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable ||
            
            this.aircraftConfigurators[i].refCodeSuitableMin !== this.aircraftConfigurator.refCodeSuitableMin ||
            this.aircraftConfigurators[i].refCodeSuitableMax !== this.aircraftConfigurator.refCodeSuitableMax ||
            this.aircraftConfigurators[i].refCodeSuitableRestrMin !== this.aircraftConfigurator.refCodeSuitableRestrMin ||
            this.aircraftConfigurators[i].refCodeSuitableRestrMax !== this.aircraftConfigurator.refCodeSuitableRestrMax ||
            this.aircraftConfigurators[i].refCodeNotSuitableMin !== this.aircraftConfigurator.refCodeNotSuitableMin ||
            this.aircraftConfigurators[i].refCodeNotSuitableMax !== this.aircraftConfigurator.refCodeNotSuitableMax ||
            
            this.aircraftConfigurators[i].arffSuitableMin !== this.aircraftConfigurator.arffSuitableMin ||
            this.aircraftConfigurators[i].arffSuitableMax !== this.aircraftConfigurator.arffSuitableMax ||
            this.aircraftConfigurators[i].arffSuitableRestrMin !== this.aircraftConfigurator.arffSuitableRestrMin ||
            this.aircraftConfigurators[i].arffSuitableRestrMax !== this.aircraftConfigurator.arffSuitableRestrMax ||
            this.aircraftConfigurators[i].arffNotSuitableMin !== this.aircraftConfigurator.arffNotSuitableMin ||
            this.aircraftConfigurators[i].arffNotSuitableMax !== this.aircraftConfigurator.arffNotSuitableMax ||
            
            this.aircraftConfigurators[i].arffsuitableremark !== this.aircraftConfigurator.arffsuitableremark ||
            this.aircraftConfigurators[i].arffsuitablerestrremark !== this.aircraftConfigurator.arffsuitablerestrremark ||
            
            this.aircraftConfigurators[i].turnOnOffMinARFFAll !== this.aircraftConfigurator.turnOnOffMinARFFAll ||
            this.aircraftConfigurators[i].turnOnOffMinARFFSuitable !== this.aircraftConfigurator.turnOnOffMinARFFSuitable ||
            this.aircraftConfigurators[i].turnOnOffMinARFFRestr !== this.aircraftConfigurator.turnOnOffMinARFFRestr ||
            this.aircraftConfigurators[i].turnOnOffMinARFFUnSuitable !== this.aircraftConfigurator.turnOnOffMinARFFUnSuitable ||
            
            this.aircraftConfigurators[i].aircraftMTOW !== this.aircraftConfigurator.aircraftMTOW ||
            this.aircraftConfigurators[i].aircraftMTOW_KG !== this.aircraftConfigurator.aircraftMTOW_KG ||
            
            this.aircraftConfigurators[i].aircraftDOW !== this.aircraftConfigurator.aircraftDOW ||
            this.aircraftConfigurators[i].aircraftDOW_KG !== this.aircraftConfigurator.aircraftDOW_KG ||
            
            this.aircraftConfigurators[i].acn_MTOW_F_A !== this.aircraftConfigurator.acn_MTOW_F_A ||
            this.aircraftConfigurators[i].acn_MTOW_F_B !== this.aircraftConfigurator.acn_MTOW_F_B ||
            this.aircraftConfigurators[i].acn_MTOW_F_C !== this.aircraftConfigurator.acn_MTOW_F_C ||
            this.aircraftConfigurators[i].acn_MTOW_F_D !== this.aircraftConfigurator.acn_MTOW_F_D ||
            this.aircraftConfigurators[i].acn_MTOW_R_A !== this.aircraftConfigurator.acn_MTOW_R_A ||
            this.aircraftConfigurators[i].acn_MTOW_R_B !== this.aircraftConfigurator.acn_MTOW_R_B ||
            this.aircraftConfigurators[i].acn_MTOW_R_C !== this.aircraftConfigurator.acn_MTOW_R_C ||
            this.aircraftConfigurators[i].acn_MTOW_R_D !== this.aircraftConfigurator.acn_MTOW_R_D ||
            this.aircraftConfigurators[i].acn_DOW_F_A !== this.aircraftConfigurator.acn_DOW_F_A ||
            this.aircraftConfigurators[i].acn_DOW_F_B !== this.aircraftConfigurator.acn_DOW_F_B ||
            this.aircraftConfigurators[i].acn_DOW_F_C !== this.aircraftConfigurator.acn_DOW_F_C ||
            this.aircraftConfigurators[i].acn_DOW_F_D !== this.aircraftConfigurator.acn_DOW_F_D ||
            this.aircraftConfigurators[i].acn_DOW_R_A !== this.aircraftConfigurator.acn_DOW_R_A ||
            this.aircraftConfigurators[i].acn_DOW_R_B !== this.aircraftConfigurator.acn_DOW_R_B ||
            this.aircraftConfigurators[i].acn_DOW_R_C !== this.aircraftConfigurator.acn_DOW_R_C ||
            this.aircraftConfigurators[i].acn_DOW_R_D !== this.aircraftConfigurator.acn_DOW_R_D ||
            this.aircraftConfigurators[i].mtow_SW !== this.aircraftConfigurator.mtow_SW ||
            this.aircraftConfigurators[i].mtow_DW !== this.aircraftConfigurator.mtow_DW ||
            this.aircraftConfigurators[i].mtow_DT !== this.aircraftConfigurator.mtow_DT ||
            this.aircraftConfigurators[i].mtow_DDT !== this.aircraftConfigurator.mtow_DDT ||
            this.aircraftConfigurators[i].dow_SW !== this.aircraftConfigurator.dow_SW ||
            this.aircraftConfigurators[i].dow_DW !== this.aircraftConfigurator.dow_DW ||
            this.aircraftConfigurators[i].dow_DT !== this.aircraftConfigurator.dow_DT ||
            this.aircraftConfigurators[i].dow_DDT !== this.aircraftConfigurator.dow_DDT) {
          this.aircraftConfiguratorWasChanged();
        }

        this.aircraftConfigurators[i].username = this.aircraftConfigurator.username;
        this.aircraftConfigurators[i].created_date = this.aircraftConfigurator.created_date;
        this.aircraftConfigurators[i].last_updated_date = this.aircraftConfigurator.last_updated_date;
      
        this.aircraftConfigurators[i].aircraftName = this.aircraftConfigurator.aircraftName;

        this.aircraftConfigurators[i].elevationSuitableMin = this.aircraftConfigurator.elevationSuitableMin;
        this.aircraftConfigurators[i].elevationSuitableMinMeters = this.aircraftConfigurator.elevationSuitableMinMeters;

        this.aircraftConfigurators[i].elevationSuitableMax = this.aircraftConfigurator.elevationSuitableMax;
        this.aircraftConfigurators[i].elevationSuitableMaxMeters = this.aircraftConfigurator.elevationSuitableMaxMeters;

        this.aircraftConfigurators[i].elevationSuitableRestrMin = this.aircraftConfigurator.elevationSuitableRestrMin;
        this.aircraftConfigurators[i].elevationSuitableRestrMinMeters = this.aircraftConfigurator.elevationSuitableRestrMinMeters;

        this.aircraftConfigurators[i].elevationSuitableRestrMax = this.aircraftConfigurator.elevationSuitableRestrMax;
        this.aircraftConfigurators[i].elevationSuitableRestrMaxMeters = this.aircraftConfigurator.elevationSuitableRestrMaxMeters;
      
        this.aircraftConfigurators[i].elevationNotSuitableMin = this.aircraftConfigurator.elevationNotSuitableMin;
        this.aircraftConfigurators[i].elevationNotSuitableMax = this.aircraftConfigurator.elevationNotSuitableMax;

        this.aircraftConfigurators[i].elevationSuitableRemark = this.aircraftConfigurator.elevationSuitableRemark;
        this.aircraftConfigurators[i].elevationSuitableRestrRemark = this.aircraftConfigurator.elevationSuitableRestrRemark;

        this.aircraftConfigurators[i].turnOnOffMaxElevationAll = this.aircraftConfigurator.turnOnOffMaxElevationAll;
        this.aircraftConfigurators[i].turnOnOffMaxElevationSuitable = this.aircraftConfigurator.turnOnOffMaxElevationSuitable;
        this.aircraftConfigurators[i].turnOnOffMaxElevationRestr = this.aircraftConfigurator.turnOnOffMaxElevationRestr;
        this.aircraftConfigurators[i].turnOnOffMaxElevationUnSuitable = this.aircraftConfigurator.turnOnOffMaxElevationUnSuitable;

        this.aircraftConfigurators[i].rwyLengthSuitableMin = this.aircraftConfigurator.rwyLengthSuitableMin;
        this.aircraftConfigurators[i].rwyLengthSuitableMinMeters = this.aircraftConfigurator.rwyLengthSuitableMinMeters;

        this.aircraftConfigurators[i].rwyLengthSuitableMax = this.aircraftConfigurator.rwyLengthSuitableMax;
        this.aircraftConfigurators[i].rwyLengthSuitableMaxMeters = this.aircraftConfigurator.rwyLengthSuitableMaxMeters;

        this.aircraftConfigurators[i].rwyLengthSuitableRestrMin = this.aircraftConfigurator.rwyLengthSuitableRestrMin;
        this.aircraftConfigurators[i].rwyLengthSuitableRestrMinMeters = this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters;

        this.aircraftConfigurators[i].rwyLengthSuitableRestrMax = this.aircraftConfigurator.rwyLengthSuitableRestrMax;
        this.aircraftConfigurators[i].rwyLengthSuitableRestrMaxMeters = this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters;

        this.aircraftConfigurators[i].rwyLengthNotSuitableMin = this.aircraftConfigurator.rwyLengthNotSuitableMin;
        this.aircraftConfigurators[i].rwyLengthNotSuitableMax = this.aircraftConfigurator.rwyLengthNotSuitableMax;
        
        this.aircraftConfigurators[i].rwyLengthSuitableRemark = this.aircraftConfigurator.rwyLengthSuitableRemark;
        this.aircraftConfigurators[i].rwyLengthSuitableRestrRemark = this.aircraftConfigurator.rwyLengthSuitableRestrRemark;

        this.aircraftConfigurators[i].turnOnOffMinRunwayLengthAll = this.aircraftConfigurator.turnOnOffMinRunwayLengthAll;
        this.aircraftConfigurators[i].turnOnOffMinRunwayLengthSuitable = this.aircraftConfigurator.turnOnOffMinRunwayLengthSuitable;
        this.aircraftConfigurators[i].turnOnOffMinRunwayLengthRestr = this.aircraftConfigurator.turnOnOffMinRunwayLengthRestr;
        this.aircraftConfigurators[i].turnOnOffMinRunwayLengthUnSuitable = this.aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable;

        this.aircraftConfigurators[i].rwyWidthSuitableMin = this.aircraftConfigurator.rwyWidthSuitableMin;
        this.aircraftConfigurators[i].rwyWidthSuitableMinMeters = this.aircraftConfigurator.rwyWidthSuitableMinMeters;

        this.aircraftConfigurators[i].rwyWidthSuitableMax = this.aircraftConfigurator.rwyWidthSuitableMax;
        this.aircraftConfigurators[i].rwyWidthSuitableMaxMeters = this.aircraftConfigurator.rwyWidthSuitableMaxMeters;

        this.aircraftConfigurators[i].rwyWidthSuitableRestrMin = this.aircraftConfigurator.rwyWidthSuitableRestrMin;
        this.aircraftConfigurators[i].rwyWidthSuitableRestrMinMeters = this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters;

        this.aircraftConfigurators[i].rwyWidthSuitableRestrMax = this.aircraftConfigurator.rwyWidthSuitableRestrMax;
        this.aircraftConfigurators[i].rwyWidthSuitableRestrMaxMeters = this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters;

        this.aircraftConfigurators[i].rwyWidthNotSuitableMin = this.aircraftConfigurator.rwyWidthNotSuitableMin;
        this.aircraftConfigurators[i].rwyWidthNotSuitableMax = this.aircraftConfigurator.rwyWidthNotSuitableMax;

        this.aircraftConfigurators[i].rwyWidthSuitableRemark = this.aircraftConfigurator.rwyWidthSuitableRemark;
        this.aircraftConfigurators[i].rwyWidthSuitableRestrRemark = this.aircraftConfigurator.rwyWidthSuitableRestrRemark;

        this.aircraftConfigurators[i].turnOnOffMinRunwayWidthAll = this.aircraftConfigurator.turnOnOffMinRunwayWidthAll;
        this.aircraftConfigurators[i].turnOnOffMinRunwayWidthSuitable = this.aircraftConfigurator.turnOnOffMinRunwayWidthSuitable;
        this.aircraftConfigurators[i].turnOnOffMinRunwayWidthRestr = this.aircraftConfigurator.turnOnOffMinRunwayWidthRestr;
        this.aircraftConfigurators[i].turnOnOffMinRunwayWidthUnSuitable = this.aircraftConfigurator.turnOnOffMinRunwayWidthUnSuitable;

        this.aircraftConfigurators[i].rwySlopeSuitableMin = this.aircraftConfigurator.rwySlopeSuitableMin;
        this.aircraftConfigurators[i].rwySlopeSuitableMax = this.aircraftConfigurator.rwySlopeSuitableMax;
        this.aircraftConfigurators[i].rwySlopeSuitableMin = this.aircraftConfigurator.rwySlopeSuitableMin;
        this.aircraftConfigurators[i].rwySlopeSuitableMax = this.aircraftConfigurator.rwySlopeSuitableMax;
        this.aircraftConfigurators[i].rwySlopeSuitableFirstRestrMin = this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin;
        this.aircraftConfigurators[i].rwySlopeSuitableFirstRestrMax = this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax;
        this.aircraftConfigurators[i].rwySlopeSuitableSecondRestrMin = this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin;
        this.aircraftConfigurators[i].rwySlopeSuitableSecondRestrMax = this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax;
        this.aircraftConfigurators[i].rwySlopeNotSuitableMin = this.aircraftConfigurator.rwySlopeNotSuitableMin;
        this.aircraftConfigurators[i].rwySlopeNotSuitableMax = this.aircraftConfigurator.rwySlopeNotSuitableMax;

        this.aircraftConfigurators[i].rwySlopeSuitableRemark = this.aircraftConfigurator.rwySlopeSuitableRemark;
        this.aircraftConfigurators[i].rwySlopeSuitableFirstRestrRemark = this.aircraftConfigurator.rwySlopeSuitableFirstRestrRemark;
        this.aircraftConfigurators[i].rwySlopeSuitableSecondRestrRemark = this.aircraftConfigurator.rwySlopeSuitableSecondRestrRemark;

        this.aircraftConfigurators[i].turnOnOffMaxSlopeAll = this.aircraftConfigurator.turnOnOffMaxSlopeAll;
        this.aircraftConfigurators[i].turnOnOffMaxSlopeSuitable = this.aircraftConfigurator.turnOnOffMaxSlopeSuitable;
        this.aircraftConfigurators[i].turnOnOffMaxSlopeFirstRestr = this.aircraftConfigurator.turnOnOffMaxSlopeFirstRestr;
        this.aircraftConfigurators[i].turnOnOffMaxSlopeSecondRestr = this.aircraftConfigurator.turnOnOffMaxSlopeSecondRestr;
        this.aircraftConfigurators[i].turnOnOffMaxSlopeUnSuitable = this.aircraftConfigurator.turnOnOffMaxSlopeUnSuitable;

        this.aircraftConfigurators[i].rwySurfaceSuitable = this.aircraftConfigurator.rwySurfaceSuitable;
        this.aircraftConfigurators[i].rwySurfaceSuitableRestr = this.aircraftConfigurator.rwySurfaceSuitableRestr;
        this.aircraftConfigurators[i].rwySurfaceNotSuitable = this.aircraftConfigurator.rwySurfaceNotSuitable;

        this.aircraftConfigurators[i].rwySurfaceSuitableRemark = this.aircraftConfigurator.rwySurfaceSuitableRemark;
        this.aircraftConfigurators[i].rwySurfaceSuitableRestrRemark = this.aircraftConfigurator.rwySurfaceSuitableRestrRemark;

        this.aircraftConfigurators[i].turnOnOffSurfaceSuitableAll = this.aircraftConfigurator.turnOnOffSurfaceSuitableAll;
        this.aircraftConfigurators[i].turnOnOffSurfaceSuitable = this.aircraftConfigurator.turnOnOffSurfaceSuitable;
        this.aircraftConfigurators[i].turnOnOffSurfaceSuitableRestr = this.aircraftConfigurator.turnOnOffSurfaceSuitableRestr;
        this.aircraftConfigurators[i].turnOnOffSurfaceUnSuitable = this.aircraftConfigurator.turnOnOffSurfaceUnSuitable;

        this.aircraftConfigurators[i].rwyStrengthSuitableMin = this.aircraftConfigurator.rwyStrengthSuitableMin;
        this.aircraftConfigurators[i].rwyStrengthSuitableMax = this.aircraftConfigurator.rwyStrengthSuitableMax;
        this.aircraftConfigurators[i].rwyStrengthSuitableRestrMin = this.aircraftConfigurator.rwyStrengthSuitableRestrMin;
        this.aircraftConfigurators[i].rwyStrengthSuitableRestrMax = this.aircraftConfigurator.rwyStrengthSuitableRestrMax;
        this.aircraftConfigurators[i].rwyStrengthNotSuitableMin = this.aircraftConfigurator.rwyStrengthNotSuitableMin;
        this.aircraftConfigurators[i].rwyStrengthNotSuitableMax = this.aircraftConfigurator.rwyStrengthNotSuitableMax;

        this.aircraftConfigurators[i].rwyStrengthSuitableRemark = this.aircraftConfigurator.rwyStrengthSuitableRemark;
        this.aircraftConfigurators[i].rwyStrengthSuitableRestRemark = this.aircraftConfigurator.rwyStrengthSuitableRestRemark;

        this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthAll = this.aircraftConfigurator.turnOnOffMinRunwayStrengthAll;
        this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthSuitable = this.aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable;
        this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthRestr = this.aircraftConfigurator.turnOnOffMinRunwayStrengthRestr;
        this.aircraftConfigurators[i].turnOnOffMinRunwayStrengthUnSuitable = this.aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable;

        this.aircraftConfigurators[i].refCodeSuitableMin = this.aircraftConfigurator.refCodeSuitableMin;
        this.aircraftConfigurators[i].refCodeSuitableMax = this.aircraftConfigurator.refCodeSuitableMax;
        this.aircraftConfigurators[i].refCodeSuitableRestrMin = this.aircraftConfigurator.refCodeSuitableRestrMin;
        this.aircraftConfigurators[i].refCodeSuitableRestrMax = this.aircraftConfigurator.refCodeSuitableRestrMax;
        this.aircraftConfigurators[i].refCodeNotSuitableMin = this.aircraftConfigurator.refCodeNotSuitableMin;
        this.aircraftConfigurators[i].refCodeNotSuitableMax = this.aircraftConfigurator.refCodeNotSuitableMax;

        this.aircraftConfigurators[i].arffSuitableMin = this.aircraftConfigurator.arffSuitableMin;
        this.aircraftConfigurators[i].arffSuitableMax = this.aircraftConfigurator.arffSuitableMax;
        this.aircraftConfigurators[i].arffSuitableRestrMin = this.aircraftConfigurator.arffSuitableRestrMin;
        this.aircraftConfigurators[i].arffSuitableRestrMax = this.aircraftConfigurator.arffSuitableRestrMax;
        this.aircraftConfigurators[i].arffNotSuitableMin = this.aircraftConfigurator.arffNotSuitableMin;
        this.aircraftConfigurators[i].arffNotSuitableMax = this.aircraftConfigurator.arffNotSuitableMax;

        this.aircraftConfigurators[i].arffsuitableremark = this.aircraftConfigurator.arffsuitableremark;
        this.aircraftConfigurators[i].arffsuitablerestrremark = this.aircraftConfigurator.arffsuitablerestrremark;

        this.aircraftConfigurators[i].turnOnOffMinARFFAll = this.aircraftConfigurator.turnOnOffMinARFFAll;
        this.aircraftConfigurators[i].turnOnOffMinARFFSuitable = this.aircraftConfigurator.turnOnOffMinARFFSuitable;
        this.aircraftConfigurators[i].turnOnOffMinARFFRestr = this.aircraftConfigurator.turnOnOffMinARFFRestr;
        this.aircraftConfigurators[i].turnOnOffMinARFFUnSuitable = this.aircraftConfigurator.turnOnOffMinARFFUnSuitable;

        this.aircraftConfigurators[i].aircraftMTOW = this.aircraftConfigurator.aircraftMTOW;
        this.aircraftConfigurators[i].aircraftMTOW_KG = this.aircraftConfigurator.aircraftMTOW_KG;

        this.aircraftConfigurators[i].aircraftDOW = this.aircraftConfigurator.aircraftDOW;
        this.aircraftConfigurators[i].aircraftDOW_KG = this.aircraftConfigurator.aircraftDOW_KG;

        this.aircraftConfigurators[i].acn_MTOW_F_A = this.aircraftConfigurator.acn_MTOW_F_A;
        this.aircraftConfigurators[i].acn_MTOW_F_B = this.aircraftConfigurator.acn_MTOW_F_B;
        this.aircraftConfigurators[i].acn_MTOW_F_C = this.aircraftConfigurator.acn_MTOW_F_C;
        this.aircraftConfigurators[i].acn_MTOW_F_D = this.aircraftConfigurator.acn_MTOW_F_D;
        this.aircraftConfigurators[i].acn_MTOW_R_A = this.aircraftConfigurator.acn_MTOW_R_A;
        this.aircraftConfigurators[i].acn_MTOW_R_B = this.aircraftConfigurator.acn_MTOW_R_B;
        this.aircraftConfigurators[i].acn_MTOW_R_C = this.aircraftConfigurator.acn_MTOW_R_C;
        this.aircraftConfigurators[i].acn_MTOW_R_D = this.aircraftConfigurator.acn_MTOW_R_D;
        this.aircraftConfigurators[i].acn_DOW_F_A = this.aircraftConfigurator.acn_DOW_F_A;
        this.aircraftConfigurators[i].acn_DOW_F_B = this.aircraftConfigurator.acn_DOW_F_B;
        this.aircraftConfigurators[i].acn_DOW_F_C = this.aircraftConfigurator.acn_DOW_F_C;
        this.aircraftConfigurators[i].acn_DOW_F_D = this.aircraftConfigurator.acn_DOW_F_D;
        this.aircraftConfigurators[i].acn_DOW_R_A = this.aircraftConfigurator.acn_DOW_R_A;
        this.aircraftConfigurators[i].acn_DOW_R_B = this.aircraftConfigurator.acn_DOW_R_B;
        this.aircraftConfigurators[i].acn_DOW_R_C = this.aircraftConfigurator.acn_DOW_R_C;
        this.aircraftConfigurators[i].acn_DOW_R_D = this.aircraftConfigurator.acn_DOW_R_D;
        this.aircraftConfigurators[i].mtow_SW = this.aircraftConfigurator.mtow_SW;
        this.aircraftConfigurators[i].mtow_DW = this.aircraftConfigurator.mtow_DW;
        this.aircraftConfigurators[i].mtow_DT = this.aircraftConfigurator.mtow_DT;
        this.aircraftConfigurators[i].mtow_DDT = this.aircraftConfigurator.mtow_DDT;
        this.aircraftConfigurators[i].dow_SW = this.aircraftConfigurator.dow_SW;
        this.aircraftConfigurators[i].dow_DW = this.aircraftConfigurator.dow_DW;
        this.aircraftConfigurators[i].dow_DT = this.aircraftConfigurator.dow_DT;
        this.aircraftConfigurators[i].dow_DDT = this.aircraftConfigurator.dow_DDT;
      }
    }
    }
  }

  changeStatusOfSwitchSuiSurfAsphaltAndConcrete() {
      if (!this.rwySurfaceSuitableAsphaltAndConcrete) {
        this.rwySurfaceSuitableAsphaltAndConcrete = true;

        if (this.rwySurfaceSuitableASPH === false) {
          this.rwySurfaceSuitableASPH = true;
          this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH');
        }
        if (this.rwySurfaceSuitableASPHTRTD === false) {
          this.rwySurfaceSuitableASPHTRTD = true;
          this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-TRTD');
        }
        if (this.rwySurfaceSuitableCONC === false) {
          this.rwySurfaceSuitableCONC = true;
          this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC');
        }
        if (this.rwySurfaceSuitableCONCTRTD === false) {
          this.rwySurfaceSuitableCONCTRTD = true;
          this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC-TRTD');
        }
        if (this.rwySurfaceSuitableASPHCONC === false) {
          this.rwySurfaceSuitableASPHCONC = true;
          this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-CONC');
        }
      } else if (this.rwySurfaceSuitableAsphaltAndConcrete) {
        this.rwySurfaceSuitableAsphaltAndConcrete = false;

        this.rwySurfaceSuitableASPH = false;
        if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
          for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
            if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH') {
              this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
            }
          }
        }

        this.rwySurfaceSuitableASPHTRTD = false;
        if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
          for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
            if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-TRTD') {
              this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
            }
          }
        }

        this.rwySurfaceSuitableCONC = false;
        if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
          for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
            if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC') {
              this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
            }
          }
        }

        this.rwySurfaceSuitableCONCTRTD = false;
        if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
          for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
            if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-TRTD') {
              this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
            }
          }
        }

        this.rwySurfaceSuitableASPHCONC = false;
        if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
          for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
            if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-CONC') {
              this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
            }
          }
        }
      }
  }

  changeStatusOfSwitchSuiRestrSurfAsphaltAndConcrete() {
    if (!this.rwySurfaceSuitableRestrAsphaltAndConcrete) {
      this.rwySurfaceSuitableRestrAsphaltAndConcrete = true;

      if (this.rwySurfaceSuitableRestrASPH === false) {
        this.rwySurfaceSuitableRestrASPH = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH');
      }
      if (this.rwySurfaceSuitableRestrASPHTRTD === false) {
        this.rwySurfaceSuitableRestrASPHTRTD = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-TRTD');
      }
      if (this.rwySurfaceSuitableRestrCONC === false) {
        this.rwySurfaceSuitableRestrCONC = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC');
      }
      if (this.rwySurfaceSuitableRestrCONCTRTD === false) {
        this.rwySurfaceSuitableRestrCONCTRTD = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC-TRTD');
      }
      if (this.rwySurfaceSuitableRestrASPHCONC === false) {
        this.rwySurfaceSuitableRestrASPHCONC = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-CONC');
      }
    } else if (this.rwySurfaceSuitableRestrAsphaltAndConcrete) {
      this.rwySurfaceSuitableRestrAsphaltAndConcrete = false;

      this.rwySurfaceSuitableRestrASPH = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrASPHTRTD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-TRTD') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrCONC = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrCONCTRTD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-TRTD') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrASPHCONC = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-CONC') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiSurfGravel() {
    if (!this.rwySurfaceSuitableGravel) {
      this.rwySurfaceSuitableGravel = true;

      if (this.rwySurfaceSuitableGRVL === false) {
        this.rwySurfaceSuitableGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL');
      }
      if (this.rwySurfaceSuitableGRVLTRTD === false) {
        this.rwySurfaceSuitableGRVLTRTD = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL-TRTD');
      }
      if (this.rwySurfaceSuitableASPHGRVL === false) {
        this.rwySurfaceSuitableASPHGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-GRVL');
      }
      if (this.rwySurfaceSuitableCONCGRVL === false) {
        this.rwySurfaceSuitableCONCGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC-GRVL');
      }
      if (this.rwySurfaceSuitableGRVLDIRT === false) {
        this.rwySurfaceSuitableGRVLDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL-DIRT');
      }
      if (this.rwySurfaceSuitableGRVLTURF === false) {
        this.rwySurfaceSuitableGRVLTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL-TURF');
      }
      if (this.rwySurfaceSuitableCORAL === false) {
        this.rwySurfaceSuitableCORAL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('CORAL');
      }
    } else if (this.rwySurfaceSuitableGravel) {
      this.rwySurfaceSuitableGravel = false;

      this.rwySurfaceSuitableGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableGRVLTRTD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableASPHGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableCONCGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableGRVLDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableGRVLTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableCORAL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CORAL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiRestrSurfGravel() {
    if (!this.rwySurfaceSuitableRestrGravel) {
      this.rwySurfaceSuitableRestrGravel = true;

      if (this.rwySurfaceSuitableRestrGRVL === false) {
        this.rwySurfaceSuitableRestrGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL');
      }
      if (this.rwySurfaceSuitableRestrGRVLTRTD === false) {
        this.rwySurfaceSuitableRestrGRVLTRTD = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL-TRTD');
      }
      if (this.rwySurfaceSuitableRestrASPHGRVL === false) {
        this.rwySurfaceSuitableRestrASPHGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-GRVL');
      }
      if (this.rwySurfaceSuitableRestrCONCGRVL === false) {
        this.rwySurfaceSuitableRestrCONCGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC-GRVL');
      }
      if (this.rwySurfaceSuitableRestrGRVLDIRT === false) {
        this.rwySurfaceSuitableRestrGRVLDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL-DIRT');
      }
      if (this.rwySurfaceSuitableRestrGRVLTURF === false) {
        this.rwySurfaceSuitableRestrGRVLTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL-TURF');
      }
      if (this.rwySurfaceSuitableRestrCORAL === false) {
        this.rwySurfaceSuitableRestrCORAL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CORAL');
      }
    } else if (this.rwySurfaceSuitableRestrGravel) {
      this.rwySurfaceSuitableRestrGravel = false;

      this.rwySurfaceSuitableRestrGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrGRVLTRTD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrASPHGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrCONCGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrGRVLDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrGRVLTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrCORAL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CORAL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiSurfDIRT() {
    if (!this.rwySurfaceSuitableALLDIRT) {
      this.rwySurfaceSuitableALLDIRT = true;

      if (this.rwySurfaceSuitableDIRT === false) {
        this.rwySurfaceSuitableDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT');
      }

      if (this.rwySurfaceSuitableDIRTTRTD === false) {
        this.rwySurfaceSuitableDIRTTRTD = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT-TRTD');
      }

      if (this.rwySurfaceSuitableASPHDIRT === false) {
        this.rwySurfaceSuitableASPHDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-DIRT');
      }

      if (this.rwySurfaceSuitableDIRTGRVL === false) {
        this.rwySurfaceSuitableDIRTGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT-GRVL');
      }

      if (this.rwySurfaceSuitableDIRTTURF === false) {
        this.rwySurfaceSuitableDIRTTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT-TURF');
      }

      if (this.rwySurfaceSuitableCALICHE === false) {
        this.rwySurfaceSuitableCALICHE = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('CALICHE');
      }
    } else if (this.rwySurfaceSuitableALLDIRT) {
      this.rwySurfaceSuitableALLDIRT = false;

      this.rwySurfaceSuitableDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableDIRTTRTD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-TRTD') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableASPHDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-DIRT') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableDIRTGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-GRVL') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableDIRTTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableCALICHE = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CALICHE') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiRestrSurfDIRT() {
    if (!this.rwySurfaceSuitableRestrALLDIRT) {
      this.rwySurfaceSuitableRestrALLDIRT = true;

      if (this.rwySurfaceSuitableRestrDIRT === false) {
        this.rwySurfaceSuitableRestrDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT');
      }

      if (this.rwySurfaceSuitableRestrDIRTTRTD === false) {
        this.rwySurfaceSuitableRestrDIRTTRTD = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT-TRTD');
      }

      if (this.rwySurfaceSuitableRestrASPHDIRT === false) {
        this.rwySurfaceSuitableRestrASPHDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-DIRT');
      }

      if (this.rwySurfaceSuitableRestrDIRTGRVL === false) {
        this.rwySurfaceSuitableRestrDIRTGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT-GRVL');
      }

      if (this.rwySurfaceSuitableRestrDIRTTURF === false) {
        this.rwySurfaceSuitableRestrDIRTTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT-TURF');
      }

      if (this.rwySurfaceSuitableRestrCALICHE === false) {
        this.rwySurfaceSuitableRestrCALICHE = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CALICHE');
      }
    } else if (this.rwySurfaceSuitableRestrALLDIRT) {
      this.rwySurfaceSuitableRestrALLDIRT = false;

      this.rwySurfaceSuitableRestrDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrDIRTTRTD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-TRTD') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrASPHDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-DIRT') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrDIRTGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-GRVL') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrDIRTTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrCALICHE = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CALICHE') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiSurfTURF() {
    if (!this.rwySurfaceSuitableALLTURF) {
      this.rwySurfaceSuitableALLTURF = true;

      if (this.rwySurfaceSuitableTURF === false) {
        this.rwySurfaceSuitableTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF');
      }

      if (this.rwySurfaceSuitableASPHTURF === false) {
        this.rwySurfaceSuitableASPHTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-TURF');
      }

      if (this.rwySurfaceSuitableCONCTURF === false) {
        this.rwySurfaceSuitableCONCTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC-TURF');
      }

      if (this.rwySurfaceSuitableTURFGRVL === false) {
        this.rwySurfaceSuitableTURFGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF-GRVL');
      }

      if (this.rwySurfaceSuitableTURFDIRT === false) {
        this.rwySurfaceSuitableTURFDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF-DIRT');
      }

      if (this.rwySurfaceSuitableTURFSAND === false) {
        this.rwySurfaceSuitableTURFSAND = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF-SAND');
      }

      if (this.rwySurfaceSuitableOILCHIPT === false) {
        this.rwySurfaceSuitableOILCHIPT = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('OIL&CHIP-T');
      }

      if (this.rwySurfaceSuitableSOD === false) {
        this.rwySurfaceSuitableSOD = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('SOD');
      }

      if (this.rwySurfaceSuitableGRASS === false) {
        this.rwySurfaceSuitableGRASS = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRASS');
      }

    } else if (this.rwySurfaceSuitableALLTURF) {
      this.rwySurfaceSuitableALLTURF = false;

      this.rwySurfaceSuitableTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableASPHTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableCONCTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableTURFGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-GRVL') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableTURFDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-DIRT') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableTURFSAND = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-SAND') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableOILCHIPT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'OIL&CHIP-T') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableSOD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SOD') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableGRASS = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRASS') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiRestrSurfTURF() {
    if (!this.rwySurfaceSuitableRestrALLTURF) {
      this.rwySurfaceSuitableRestrALLTURF = true;

      if (this.rwySurfaceSuitableRestrTURF === false) {
        this.rwySurfaceSuitableRestrTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF');
      }

      if (this.rwySurfaceSuitableRestrASPHTURF === false) {
        this.rwySurfaceSuitableRestrASPHTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-TURF');
      }

      if (this.rwySurfaceSuitableRestrCONCTURF === false) {
        this.rwySurfaceSuitableRestrCONCTURF = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC-TURF');
      }

      if (this.rwySurfaceSuitableRestrTURFGRVL === false) {
        this.rwySurfaceSuitableRestrTURFGRVL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF-GRVL');
      }

      if (this.rwySurfaceSuitableRestrTURFDIRT === false) {
        this.rwySurfaceSuitableRestrTURFDIRT = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF-DIRT');
      }

      if (this.rwySurfaceSuitableRestrTURFSAND === false) {
        this.rwySurfaceSuitableRestrTURFSAND = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF-SAND');
      }

      if (this.rwySurfaceSuitableRestrOILCHIPT === false) {
        this.rwySurfaceSuitableRestrOILCHIPT = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('OIL&CHIP-T');
      }

      if (this.rwySurfaceSuitableRestrSOD === false) {
        this.rwySurfaceSuitableRestrSOD = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('SOD');
      }

      if (this.rwySurfaceSuitableRestrGRASS === false) {
        this.rwySurfaceSuitableRestrGRASS = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRASS');
      }

    } else if (this.rwySurfaceSuitableRestrALLTURF) {
      this.rwySurfaceSuitableRestrALLTURF = false;

      this.rwySurfaceSuitableRestrTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrASPHTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrCONCTURF = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-TURF') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrTURFGRVL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-GRVL') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrTURFDIRT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-DIRT') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrTURFSAND = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-SAND') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrOILCHIPT = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'OIL&CHIP-T') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrSOD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SOD') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrGRASS = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRASS') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiSurfSnowAndIce() {
    if (!this.rwySurfaceSuitableSnowAndIce) {
      this.rwySurfaceSuitableSnowAndIce = true;

      if (this.rwySurfaceSuitableSNOW === false) {
        this.rwySurfaceSuitableSNOW = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('SNOW');
      }

      if (this.rwySurfaceSuitableICE === false) {
        this.rwySurfaceSuitableICE = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('ICE');
      }
    } else if (this.rwySurfaceSuitableSnowAndIce) {
      this.rwySurfaceSuitableSnowAndIce = false;

      this.rwySurfaceSuitableSNOW = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SNOW') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableICE = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ICE') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiRestrSurfSnowAndIce() {
    if (!this.rwySurfaceSuitableRestrSnowAndIce) {
      this.rwySurfaceSuitableRestrSnowAndIce = true;

      if (this.rwySurfaceSuitableRestrSNOW === false) {
        this.rwySurfaceSuitableRestrSNOW = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('SNOW');
      }

      if (this.rwySurfaceSuitableRestrICE === false) {
        this.rwySurfaceSuitableRestrICE = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ICE');
      }
    } else if (this.rwySurfaceSuitableRestrSnowAndIce) {
      this.rwySurfaceSuitableRestrSnowAndIce = false;

      this.rwySurfaceSuitableRestrSNOW = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SNOW') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrICE = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ICE') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiSurfWater() {
    if (!this.rwySurfaceSuitableAllWater) {
      this.rwySurfaceSuitableAllWater = true;

      if (this.rwySurfaceSuitableWATER === false) {
        this.rwySurfaceSuitableWATER = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('WATER');
      }
    } else if (this.rwySurfaceSuitableAllWater) {
      this.rwySurfaceSuitableAllWater = false;

      this.rwySurfaceSuitableWATER = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'WATER') {
            this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiRestrSurfWater() {
    if (!this.rwySurfaceSuitableRestrAllWater) {
      this.rwySurfaceSuitableRestrAllWater = true;

      if (this.rwySurfaceSuitableRestrWATER === false) {
        this.rwySurfaceSuitableRestrWATER = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('WATER');
      }
    } else if (this.rwySurfaceSuitableRestrAllWater) {
      this.rwySurfaceSuitableRestrAllWater = false;

      this.rwySurfaceSuitableRestrWATER = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'WATER') {
            this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiSurfOther() {
    if (!this.rwySurfaceSuitableOther) {
      this.rwySurfaceSuitableOther = true;

      if (this.rwySurfaceSuitableALUMINUM === false) {
        this.rwySurfaceSuitableALUMINUM = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('ALUMINUM');
      }

      if (this.rwySurfaceSuitableBRICK === false) {
        this.rwySurfaceSuitableBRICK = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('BRICK');
      }

      if (this.rwySurfaceSuitableDECK === false) {
        this.rwySurfaceSuitableDECK = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('DECK');
      }

      if (this.rwySurfaceSuitableMATS === false) {
        this.rwySurfaceSuitableMATS = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('MATS');
      }

      if (this.rwySurfaceSuitableMETAL === false) {
        this.rwySurfaceSuitableMETAL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('METAL');
      }

      if (this.rwySurfaceSuitableROOFTOP === false) {
        this.rwySurfaceSuitableROOFTOP = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('ROOFTOP');
      }

      if (this.rwySurfaceSuitableSAND === false) {
        this.rwySurfaceSuitableSAND = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('SAND');
      }

      if (this.rwySurfaceSuitableSTEEL === false) {
        this.rwySurfaceSuitableSTEEL = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('STEEL');
      }

      if (this.rwySurfaceSuitableSTEELCONC === false) {
        this.rwySurfaceSuitableSTEELCONC = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('STEEL-CONC');
      }

      if (this.rwySurfaceSuitableWOOD === false) {
        this.rwySurfaceSuitableWOOD = true;
        this.aircraftConfigurator.rwySurfaceSuitableArray.push('WOOD');
      }
    } else if (this.rwySurfaceSuitableOther) {
      this.rwySurfaceSuitableOther = false;

      this.rwySurfaceSuitableALUMINUM = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ALUMINUM') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableBRICK = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'BRICK') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableDECK = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DECK') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableMATS = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'MATS') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableMETAL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'METAL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableROOFTOP = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ROOFTOP') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableSAND = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SAND') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableSTEEL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'STEEL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableSTEELCONC = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'STEEL-CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableWOOD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'WOOD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchSuiRestrSurfOther() {
    if (!this.rwySurfaceSuitableRestrOther) {
      this.rwySurfaceSuitableRestrOther = true;

      if (this.rwySurfaceSuitableRestrALUMINUM === false) {
        this.rwySurfaceSuitableRestrALUMINUM = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ALUMINUM');
      }

      if (this.rwySurfaceSuitableRestrBRICK === false) {
        this.rwySurfaceSuitableRestrBRICK = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('BRICK');
      }

      if (this.rwySurfaceSuitableRestrDECK === false) {
        this.rwySurfaceSuitableRestrDECK = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DECK');
      }

      if (this.rwySurfaceSuitableRestrMATS === false) {
        this.rwySurfaceSuitableRestrMATS = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('MATS');
      }

      if (this.rwySurfaceSuitableRestrMETAL === false) {
        this.rwySurfaceSuitableRestrMETAL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('METAL');
      }

      if (this.rwySurfaceSuitableRestrROOFTOP === false) {
        this.rwySurfaceSuitableRestrROOFTOP = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ROOFTOP');
      }

      if (this.rwySurfaceSuitableRestrSAND === false) {
        this.rwySurfaceSuitableRestrSAND = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('SAND');
      }

      if (this.rwySurfaceSuitableRestrSTEEL === false) {
        this.rwySurfaceSuitableRestrSTEEL = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('STEEL');
      }

      if (this.rwySurfaceSuitableRestrSTEELCONC === false) {
        this.rwySurfaceSuitableRestrSTEELCONC = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('STEEL-CONC');
      }

      if (this.rwySurfaceSuitableRestrWOOD === false) {
        this.rwySurfaceSuitableRestrWOOD = true;
        this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('WOOD');
      }
    } else if (this.rwySurfaceSuitableRestrOther) {
      this.rwySurfaceSuitableRestrOther = false;

      this.rwySurfaceSuitableRestrALUMINUM = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ALUMINUM') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrBRICK = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'BRICK') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrDECK = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DECK') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrMATS = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'MATS') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrMETAL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'METAL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrROOFTOP = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ROOFTOP') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrSAND = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SAND') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrSTEEL = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'STEEL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrSTEELCONC = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'STEEL-CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }

      this.rwySurfaceSuitableRestrWOOD = false;
      if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
        for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
          if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'WOOD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
          }
        }
      }
    }
  }

  changeStatusOfSwitchConfigurator() {
    let queryParams: Params;
  
    if (!this.switchConfiguratorStatus) {
      this.switchConfiguratorStatus = true;
      queryParams = { configurator: 'true'};
    } else if (this.switchConfiguratorStatus) {
      this.switchConfiguratorStatus = false;
      queryParams = { configurator: 'false'};
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: queryParams, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  calculateMTOWfromLBtoKG(): void {
    if (this.aircraftConfigurator.aircraftMTOW != null) {
      this.aircraftConfigurator.aircraftMTOW_KG = +(this.aircraftConfigurator.aircraftMTOW * 0.45359237).toFixed(1);
    } else {
      this.aircraftConfigurator.aircraftMTOW_KG = null;
    } 
  }

  calculateMTOWfromKGtoLB(): void {
    if (this.aircraftConfigurator.aircraftMTOW_KG != null) {
      this.aircraftConfigurator.aircraftMTOW = +(this.aircraftConfigurator.aircraftMTOW_KG / 0.45359237).toFixed(1);
    } else {
      this.aircraftConfigurator.aircraftMTOW = null;
    } 
  }

  calculateDOWfromLBtoKG(): void {
    if (this.aircraftConfigurator.aircraftDOW != null) {
      this.aircraftConfigurator.aircraftDOW_KG = +(this.aircraftConfigurator.aircraftDOW * 0.45359237).toFixed(1);
    } else {
      this.aircraftConfigurator.aircraftDOW_KG = null;
    } 
  }

  calculateDOWfromKGtoLB(): void {
    if (this.aircraftConfigurator.aircraftDOW_KG != null) {
      this.aircraftConfigurator.aircraftDOW = +(this.aircraftConfigurator.aircraftDOW_KG / 0.45359237).toFixed(1);
    } else {
      this.aircraftConfigurator.aircraftDOW = null;
    } 
  }
  
  compareSuitableMTOWwithConfiguratorMINandMAX(tmp: number): boolean {
    let result = false;
    let MTOWinPercents = tmp;
    
    if (this.aircraftConfigurator.rwyStrengthSuitableMin !== null &&
        this.aircraftConfigurator.rwyStrengthSuitableMax !== null &&
        MTOWinPercents !== null) {
      if (this.aircraftConfigurator.rwyStrengthSuitableMax === 100 && 
        MTOWinPercents > this.aircraftConfigurator.rwyStrengthSuitableMax) {
        result = true;
      }
      if (MTOWinPercents >= this.aircraftConfigurator.rwyStrengthSuitableMin &&
        MTOWinPercents <= this.aircraftConfigurator.rwyStrengthSuitableMax) {
        result = true;
    }
    }
    return result;
  }

  compareRestrMTOWwithConfiguratorMINandMAX(tmp: number): boolean {
    let result = false;
    let MTOWinPercents = tmp;
    
    if (this.aircraftConfigurator.rwyStrengthSuitableRestrMin !== null &&
        this.aircraftConfigurator.rwyStrengthSuitableRestrMax !== null &&
        MTOWinPercents !== null) {
      if (this.aircraftConfigurator.rwyStrengthSuitableRestrMax === 100 && 
        MTOWinPercents > this.aircraftConfigurator.rwyStrengthSuitableRestrMax) {
        result = true;
      }
      if (MTOWinPercents >= this.aircraftConfigurator.rwyStrengthSuitableRestrMin &&
        MTOWinPercents <= this.aircraftConfigurator.rwyStrengthSuitableRestrMax) {
        result = true;
    }
    }
    return result;
  }

  /*BEGINNING. PCN*/
  checkSuiRunwayPCN(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayPcn !== null) {
      result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayPCN(tempRunway));
    }
    return result;
  }

  checkRestrRunwayPCN(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayPcn !== null) {
      result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayPCN(tempRunway));
    }
    return result;
  }

  calcPercentsFromRunwayPCN(tempRunway: Runway): number {
    let result = null;
    if (tempRunway != null && tempRunway.runwayPcn != null) {
      
      let tempRunwayPcnArray = tempRunway.runwayPcn.split('/');

      for (let i = 0; i < tempRunwayPcnArray.length; i++) {
        if (tempRunwayPcnArray.length >= 3) {
          let PCN = +tempRunwayPcnArray[0].trim();
          if (tempRunwayPcnArray[1] === 'F') {
            if (tempRunwayPcnArray[2] === 'A') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_F_A, this.aircraftConfigurator.acn_DOW_F_A);
            }
            if (tempRunwayPcnArray[2] === 'B') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_F_B, this.aircraftConfigurator.acn_DOW_F_B);
            }
            if (tempRunwayPcnArray[2] === 'C') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_F_C, this.aircraftConfigurator.acn_DOW_F_C);
            }
            if (tempRunwayPcnArray[2] === 'D') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_F_D, this.aircraftConfigurator.acn_DOW_F_D);
            }
          }
          if (tempRunwayPcnArray[1] === 'R') {
            if (tempRunwayPcnArray[2] === 'A') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_R_A, this.aircraftConfigurator.acn_DOW_R_A);
            }
            if (tempRunwayPcnArray[2] === 'B') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_R_B, this.aircraftConfigurator.acn_DOW_R_B);
            }
            if (tempRunwayPcnArray[2] === 'C') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_R_C, this.aircraftConfigurator.acn_DOW_R_C);
            }
            if (tempRunwayPcnArray[2] === 'D') {
              result = this.calculateMTOWfromACNinPercents(PCN, this.aircraftConfigurator.acn_MTOW_R_D, this.aircraftConfigurator.acn_DOW_R_D);
            }
          }
    }
    }
    }
    return result;
  }

  calculateMTOWfromACNinPercents(PCN: number, ACN_MTOW: number, ACN_DOW: number): number {
    let MTOWfromACNinPercents = null;
    if (PCN != null && ACN_MTOW != null && ACN_DOW !=null &&
        this.aircraftConfigurator.aircraftMTOW != null && this.aircraftConfigurator.aircraftDOW != null) {
      if (PCN < ACN_DOW) {
        let MTOWfromACN = (this.aircraftConfigurator.aircraftDOW / ACN_DOW) * PCN;
        MTOWfromACNinPercents = +((MTOWfromACN / this.aircraftConfigurator.aircraftMTOW) * 100).toFixed();
      } else if (PCN > ACN_DOW && this.aircraftConfigurator.aircraftMTOW !== this.aircraftConfigurator.aircraftDOW &&
        ACN_MTOW !== ACN_DOW) {
        let MTOWfromACN = (((this.aircraftConfigurator.aircraftMTOW - this.aircraftConfigurator.aircraftDOW) /
        (ACN_MTOW - ACN_DOW)) * (PCN - ACN_DOW)) + this.aircraftConfigurator.aircraftDOW;
        
        MTOWfromACNinPercents = +((MTOWfromACN / this.aircraftConfigurator.aircraftMTOW) * 100).toFixed();
      }
    }
    return MTOWfromACNinPercents;
	}
  
  checkACNexist(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway != null && tempRunway.runwayPcn != null) {
      
      let tempRunwayPcnArray = tempRunway.runwayPcn.split('/');

      for (let i = 0; i < tempRunwayPcnArray.length; i++) {
        if (tempRunwayPcnArray.length >= 3) {
          let PCN = +tempRunwayPcnArray[0].trim();
          if (tempRunwayPcnArray[1] === 'F') {
            if (tempRunwayPcnArray[2] === 'A' && this.aircraftConfigurator.acn_MTOW_F_A != null && this.aircraftConfigurator.acn_DOW_F_A != null) {
              result = true;
            }
            if (tempRunwayPcnArray[2] === 'B' && this.aircraftConfigurator.acn_MTOW_F_B != null && this.aircraftConfigurator.acn_DOW_F_B != null) {
              result = true;
            }
            if (tempRunwayPcnArray[2] === 'C' && this.aircraftConfigurator.acn_MTOW_F_C != null && this.aircraftConfigurator.acn_DOW_F_C != null) {
              result = true;
            }
            if (tempRunwayPcnArray[2] === 'D' && this.aircraftConfigurator.acn_MTOW_F_D != null && this.aircraftConfigurator.acn_DOW_F_D != null) {
              result = true;
            }
          }
          if (tempRunwayPcnArray[1] === 'R') {
            if (tempRunwayPcnArray[2] === 'A' && this.aircraftConfigurator.acn_MTOW_R_A != null && this.aircraftConfigurator.acn_DOW_R_A != null) {
              result = true;
            }
            if (tempRunwayPcnArray[2] === 'B' && this.aircraftConfigurator.acn_MTOW_R_B != null && this.aircraftConfigurator.acn_DOW_R_B != null) {
              result = true;
            }
            if (tempRunwayPcnArray[2] === 'C' && this.aircraftConfigurator.acn_MTOW_R_C != null && this.aircraftConfigurator.acn_DOW_R_C != null) {
              result = true;
            }
            if (tempRunwayPcnArray[2] === 'D' && this.aircraftConfigurator.acn_MTOW_R_D != null && this.aircraftConfigurator.acn_DOW_R_D != null) {
              result = true;
            }
          }
    }
    }
    }
    return result;
  }
  /*END. PCN*/

  /*BEGINNING. SW/DW/DT/DDT*/
  /* SW */
  checkSuiRunwaySW(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacitySW !== null) {
      result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwaySW(tempRunway));
    }
    return result;
  }

  checkRestrRunwaySW(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacitySW !== null) {
      result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwaySW(tempRunway));
    }
    return result;
  }

  calcPercentsFromRunwaySW(tempRunway: Runway): number {
    return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacitySW, 
                                              this.aircraftConfigurator.mtow_SW, this.aircraftConfigurator.dow_SW); 
  }
  /* END. SW */

  /* DW */
  checkSuiRunwayDW(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacityDW !== null) {
      result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDW(tempRunway));
    }
    return result;
  }

  checkRestrRunwayDW(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacityDW !== null) {
      result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDW(tempRunway));
    }
    return result;
  }

  calcPercentsFromRunwayDW(tempRunway: Runway): number {
    return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacityDW, 
                                              this.aircraftConfigurator.mtow_DW, this.aircraftConfigurator.dow_DW); 
  }
  /* END. DW */

  /* DT */
  checkSuiRunwayDT(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacityDT !== null) {
      result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDT(tempRunway));
    }
    return result;
  }

  checkRestrRunwayDT(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacityDT !== null) {
      result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDT(tempRunway));
    }
    return result;
  }

  calcPercentsFromRunwayDT(tempRunway: Runway): number {
    return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacityDT, 
                                              this.aircraftConfigurator.mtow_DT, this.aircraftConfigurator.dow_DT); 
  }
  /* END. DT */

  /* DDT */
  checkSuiRunwayDDT(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacityDDT !== null) {
      result = this.compareSuitableMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDDT(tempRunway));
    }
    return result;
  }

  checkRestrRunwayDDT(tempRunway: Runway): boolean {
    let result = false;
    if (tempRunway.runwayWeightBearingCapacityDDT !== null) {
      result = this.compareRestrMTOWwithConfiguratorMINandMAX(this.calcPercentsFromRunwayDDT(tempRunway));
    }
    return result;
  }

  calcPercentsFromRunwayDDT(tempRunway: Runway): number {
    return this.calculateMTOWfromACNinPercents(+tempRunway.runwayWeightBearingCapacityDDT, 
                                              this.aircraftConfigurator.mtow_DDT, this.aircraftConfigurator.dow_DDT); 
  }
  /* END. DDT */
  /*END. SW/DW/DT/DDT*/

  checkSuiRunwaySurfaceType(tempRunwaySurfaceType: string): boolean {
    let result = false;
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    for (let tempSurface of this.aircraftConfigurator.rwySurfaceSuitableArray) {
      if (tempSurface === tempRunwaySurfaceType) {
        result = true;
      }
    }
    }
    return result;
  }

  checkRestrRunwaySurfaceType(tempRunwaySurfaceType: string): boolean {
    let result = false;
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    for (let tempSurface of this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
      if (tempSurface === tempRunwaySurfaceType) {
        result = true;
      }
    }
    }
    return result;
  }
  /* All add/remove param-s from SuiArray */
  /* #1 */
  checkAreALLSuitableAsphaltAndConcreteTRUE() {
    if (this.rwySurfaceSuitableASPH === true &&
        this.rwySurfaceSuitableASPHTRTD === true &&
        this.rwySurfaceSuitableCONC === true &&
        this.rwySurfaceSuitableCONCTRTD === true &&
        this.rwySurfaceSuitableASPHCONC === true
      ) {
        this.rwySurfaceSuitableAsphaltAndConcrete = true;
    } else {
      this.rwySurfaceSuitableAsphaltAndConcrete = false;
    }
  }
  addRemoveASPHfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableASPH === false) {
      this.rwySurfaceSuitableASPH = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH');
    } else {
      this.rwySurfaceSuitableASPH = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableAsphaltAndConcreteTRUE();
    }
  }
  addRemoveASPHTRTDfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableASPHTRTD === false) {
      this.rwySurfaceSuitableASPHTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-TRTD');
    } else {
      this.rwySurfaceSuitableASPHTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableAsphaltAndConcreteTRUE();
    }
  }
  addRemoveCONCfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableCONC === false) {
      this.rwySurfaceSuitableCONC = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC');
    } else {
      this.rwySurfaceSuitableCONC = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableAsphaltAndConcreteTRUE();
    }
  }
  addRemoveCONCTRTDfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableCONCTRTD === false) {
      this.rwySurfaceSuitableCONCTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC-TRTD');
    } else {
      this.rwySurfaceSuitableCONCTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableAsphaltAndConcreteTRUE();
    }
  }
  addRemoveASPHCONCfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableASPHCONC === false) {
      this.rwySurfaceSuitableASPHCONC = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-CONC');
    } else {
      this.rwySurfaceSuitableASPHCONC = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableAsphaltAndConcreteTRUE();
    }
  }
  /* END. #1 */
  /* #2 */
  checkAreALLSuitableGravelTRUE() {
    if (this.rwySurfaceSuitableGRVL === true &&
      this.rwySurfaceSuitableGRVLTRTD === true &&
      this.rwySurfaceSuitableASPHGRVL === true &&
      this.rwySurfaceSuitableCONCGRVL === true &&
      this.rwySurfaceSuitableGRVLDIRT === true &&
      this.rwySurfaceSuitableGRVLTURF === true &&
      this.rwySurfaceSuitableCORAL === true
      ) {
      this.rwySurfaceSuitableGravel = true;
    } else {
      this.rwySurfaceSuitableGravel = false;
    }
  }
  addRemoveGRVLfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableGRVL === false) {
      this.rwySurfaceSuitableGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL');
    } else {
      this.rwySurfaceSuitableGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableGravelTRUE();
    }
  }
  addRemoveGRVLTRTDfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableGRVLTRTD === false) {
      this.rwySurfaceSuitableGRVLTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL-TRTD');
    } else {
      this.rwySurfaceSuitableGRVLTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableGravelTRUE();
    }
  }
  addRemoveASPHGRVLfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableASPHGRVL === false) {
      this.rwySurfaceSuitableASPHGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-GRVL');
    } else {
      this.rwySurfaceSuitableASPHGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableGravelTRUE();
    }
  }
  addRemoveCONCGRVLfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableCONCGRVL === false) {
      this.rwySurfaceSuitableCONCGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC-GRVL');
    } else {
      this.rwySurfaceSuitableCONCGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableGravelTRUE();
    }
  }
  addRemoveGRVLDIRTfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableGRVLDIRT === false) {
      this.rwySurfaceSuitableGRVLDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL-DIRT');
    } else {
      this.rwySurfaceSuitableGRVLDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableGravelTRUE();
    }
  }
  addRemoveGRVLTURFfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableGRVLTURF === false) {
      this.rwySurfaceSuitableGRVLTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRVL-TURF');
    } else {
      this.rwySurfaceSuitableGRVLTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableGravelTRUE();
    }
  }
  addRemoveCORALfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableCORAL === false) {
      this.rwySurfaceSuitableCORAL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('CORAL');
    } else {
      this.rwySurfaceSuitableCORAL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CORAL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableGravelTRUE();
    }
  }
  /* END. #2 */
  /* #3 */
  checkAreALLSuitableALLDIRTTRUE() {
    if (this.rwySurfaceSuitableDIRT === true &&
      this.rwySurfaceSuitableDIRTTRTD === true &&
      this.rwySurfaceSuitableASPHDIRT === true &&
      this.rwySurfaceSuitableDIRTGRVL === true &&
      this.rwySurfaceSuitableDIRTTURF === true &&
     this.rwySurfaceSuitableCALICHE === true
      ) {
      this.rwySurfaceSuitableALLDIRT = true;
    } else {
      this.rwySurfaceSuitableALLDIRT = false;
    }
  }
  addRemoveDIRTfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableDIRT === false) {
      this.rwySurfaceSuitableDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT');
    } else {
      this.rwySurfaceSuitableDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLDIRTTRUE();
    }
  }
  addRemoveDIRTTRTDfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableDIRTTRTD === false) {
      this.rwySurfaceSuitableDIRTTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT-TRTD');
    } else {
      this.rwySurfaceSuitableDIRTTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLDIRTTRUE();
    }
  }
  addRemoveASPHDIRTfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableASPHDIRT === false) {
      this.rwySurfaceSuitableASPHDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-DIRT');
    } else {
      this.rwySurfaceSuitableASPHDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLDIRTTRUE();
    }
  }
  addRemoveDIRTGRVLfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableDIRTGRVL === false) {
      this.rwySurfaceSuitableDIRTGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT-GRVL');
    } else {
      this.rwySurfaceSuitableDIRTGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLDIRTTRUE();
    }
  }
  addRemoveDIRTTURFfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableDIRTTURF === false) {
      this.rwySurfaceSuitableDIRTTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('DIRT-TURF');
    } else {
      this.rwySurfaceSuitableDIRTTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLDIRTTRUE();
    }
  }
  addRemoveCALICHEfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableCALICHE === false) {
      this.rwySurfaceSuitableCALICHE = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('CALICHE');
    } else {
      this.rwySurfaceSuitableCALICHE = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CALICHE') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLDIRTTRUE();
    }
  }
  /* END. #3 */
  /* #4 */
  checkAreALLSuitableALLTURFTRUE() {
    if (this.rwySurfaceSuitableTURF === true &&
      this.rwySurfaceSuitableASPHTURF === true &&
      this.rwySurfaceSuitableCONCTURF === true &&

      this.rwySurfaceSuitableTURFGRVL === true &&
      this.rwySurfaceSuitableTURFDIRT === true &&
      this.rwySurfaceSuitableTURFSAND === true &&

      this.rwySurfaceSuitableOILCHIPT === true &&
      this.rwySurfaceSuitableSOD === true &&
      this.rwySurfaceSuitableGRASS === true) {
      this.rwySurfaceSuitableALLTURF = true;
    } else {
      this.rwySurfaceSuitableALLTURF = false;
    }
  }
  addRemoveTURFfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableTURF === false) {
      this.rwySurfaceSuitableTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF');
    } else {
      this.rwySurfaceSuitableTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveASPHTURFfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableASPHTURF === false) {
      this.rwySurfaceSuitableASPHTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ASPH-TURF');
    } else {
      this.rwySurfaceSuitableASPHTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveCONCTURFfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableCONCTURF === false) {
      this.rwySurfaceSuitableCONCTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('CONC-TURF');
    } else {
      this.rwySurfaceSuitableCONCTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveTURFGRVLfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableTURFGRVL === false) {
      this.rwySurfaceSuitableTURFGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF-GRVL');
    } else {
      this.rwySurfaceSuitableTURFGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveTURFDIRTfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableTURFDIRT === false) {
      this.rwySurfaceSuitableTURFDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF-DIRT');
    } else {
      this.rwySurfaceSuitableTURFDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveTURFSANDfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableTURFSAND === false) {
      this.rwySurfaceSuitableTURFSAND = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('TURF-SAND');
    } else {
      this.rwySurfaceSuitableTURFSAND = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-SAND') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveOILCHIPTfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableOILCHIPT === false) {
      this.rwySurfaceSuitableOILCHIPT = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('OIL&CHIP-T');
    } else {
      this.rwySurfaceSuitableOILCHIPT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'OIL&CHIP-T') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveSODfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableSOD === false) {
      this.rwySurfaceSuitableSOD = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('SOD');
    } else {
      this.rwySurfaceSuitableSOD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SOD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  addRemoveGRASSfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableGRASS === false) {
      this.rwySurfaceSuitableGRASS = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('GRASS');
    } else {
      this.rwySurfaceSuitableGRASS = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRASS') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableALLTURFTRUE();
    }
  }
  /* END. #4 */
  checkAreALLSuitableSnowAndIce() {
    if (this.rwySurfaceSuitableSNOW === true && this.rwySurfaceSuitableICE === true) {
        this.rwySurfaceSuitableSnowAndIce = true;
    } else {
      this.rwySurfaceSuitableSnowAndIce = false;
    }
  }
  addRemoveSNOWfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableSNOW === false) {
      this.rwySurfaceSuitableSNOW = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('SNOW');
    } else {
      this.rwySurfaceSuitableSNOW = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SNOW') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableSnowAndIce();
    }
  }
  addRemoveICEfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableICE === false) {
      this.rwySurfaceSuitableICE = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ICE');
    } else {
      this.rwySurfaceSuitableICE = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ICE') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableSnowAndIce();
    }
  }
  /* #5*/
  checkAreALLSuitableAllWater() {
    if (this.rwySurfaceSuitableWATER === true) {
        this.rwySurfaceSuitableAllWater = true;
    } else {
      this.rwySurfaceSuitableAllWater = false;
    }
  }
  addRemoveWATERfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableWATER === false) {
      this.rwySurfaceSuitableWATER = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('WATER');
    } else {
      this.rwySurfaceSuitableWATER = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'WATER') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableAllWater();
    }
  }
  /* END. #5 */
  /* #6 */
  checkAreALLSuitableOtherTRUE() {
    if (this.rwySurfaceSuitableALUMINUM === true &&
      this.rwySurfaceSuitableBRICK === true &&
      this.rwySurfaceSuitableDECK === true &&
      this.rwySurfaceSuitableMATS === true &&
      this.rwySurfaceSuitableMETAL === true &&

      this.rwySurfaceSuitableROOFTOP === true &&
      this.rwySurfaceSuitableSAND === true &&
      this.rwySurfaceSuitableSTEEL === true &&
      this.rwySurfaceSuitableSTEELCONC === true &&
      this.rwySurfaceSuitableWOOD === true
      ) {
      this.rwySurfaceSuitableOther = true;
    } else {
      this.rwySurfaceSuitableOther = false;
    }
  }
  addRemoveALUMINUMfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableALUMINUM === false) {
      this.rwySurfaceSuitableALUMINUM = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ALUMINUM');
    } else {
      this.rwySurfaceSuitableALUMINUM = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ALUMINUM') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveBRICKfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableBRICK === false) {
      this.rwySurfaceSuitableBRICK = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('BRICK');
    } else {
      this.rwySurfaceSuitableBRICK = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'BRICK') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveDECKfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableDECK === false) {
      this.rwySurfaceSuitableDECK = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('DECK');
    } else {
      this.rwySurfaceSuitableDECK = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DECK') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveMATSfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableMATS === false) {
      this.rwySurfaceSuitableMATS = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('MATS');
    } else {
      this.rwySurfaceSuitableMATS = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'MATS') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveMETALfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableMETAL === false) {
      this.rwySurfaceSuitableMETAL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('METAL');
    } else {
      this.rwySurfaceSuitableMETAL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'METAL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveROOFTOPfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableROOFTOP === false) {
      this.rwySurfaceSuitableROOFTOP = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('ROOFTOP');
    } else {
      this.rwySurfaceSuitableROOFTOP = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ROOFTOP') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveSANDfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableSAND === false) {
      this.rwySurfaceSuitableSAND = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('SAND');
    } else {
      this.rwySurfaceSuitableSAND = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SAND') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveSTEELfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableSTEEL === false) {
      this.rwySurfaceSuitableSTEEL = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('STEEL');
    } else {
      this.rwySurfaceSuitableSTEEL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'STEEL') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveSTEELCONCfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableSTEELCONC === false) {
      this.rwySurfaceSuitableSTEELCONC = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('STEEL-CONC');
    } else {
      this.rwySurfaceSuitableSTEELCONC = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'STEEL-CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  addRemoveWOODfromSuiArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableArray) {
    if (this.rwySurfaceSuitableWOOD === false) {
      this.rwySurfaceSuitableWOOD = true;
      this.aircraftConfigurator.rwySurfaceSuitableArray.push('WOOD');
    } else {
      this.rwySurfaceSuitableWOOD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'WOOD') {
          this.aircraftConfigurator.rwySurfaceSuitableArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableOtherTRUE();
    }
  }
  /* END. #6 */
  /* END.All add/remove param-s from SuiArray */

  /* All add/remove param-s from RestrArray */
  /* #1 */
  checkAreALLSuitableRestrAsphaltAndConcreteTRUE() {
    if (this.rwySurfaceSuitableRestrASPH === true &&
        this.rwySurfaceSuitableRestrASPHTRTD === true &&
        this.rwySurfaceSuitableRestrCONC === true &&
        this.rwySurfaceSuitableRestrCONCTRTD === true &&
        this.rwySurfaceSuitableRestrASPHCONC === true
      ) {
        this.rwySurfaceSuitableRestrAsphaltAndConcrete = true;
    } else {
      this.rwySurfaceSuitableRestrAsphaltAndConcrete = false;
    }
  }
  addRemoveASPHfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrASPH === false) {
      this.rwySurfaceSuitableRestrASPH = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH');
    } else {
      this.rwySurfaceSuitableRestrASPH = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrAsphaltAndConcreteTRUE();
    }
  }

  addRemoveASPHTRTDfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrASPHTRTD === false) {
      this.rwySurfaceSuitableRestrASPHTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-TRTD');
    } else {
      this.rwySurfaceSuitableRestrASPHTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrAsphaltAndConcreteTRUE();
    }
  }
  addRemoveCONCfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrCONC === false) {
      this.rwySurfaceSuitableRestrCONC = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC');
    } else {
      this.rwySurfaceSuitableRestrCONC = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrAsphaltAndConcreteTRUE();
    }
  }
  addRemoveCONCTRTDfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrCONCTRTD === false) {
      this.rwySurfaceSuitableRestrCONCTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC-TRTD');
    } else {
      this.rwySurfaceSuitableRestrCONCTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrAsphaltAndConcreteTRUE();
    }
  }
  addRemoveASPHCONCfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrASPHCONC === false) {
      this.rwySurfaceSuitableRestrASPHCONC = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-CONC');
    } else {
      this.rwySurfaceSuitableRestrASPHCONC = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrAsphaltAndConcreteTRUE();
    }
  }
  /* #2 */
  checkAreALLSuitableRestrGravelTRUE() {
    if (this.rwySurfaceSuitableRestrGRVL === true &&
      this.rwySurfaceSuitableRestrGRVLTRTD === true &&
      this.rwySurfaceSuitableRestrASPHGRVL === true &&
      this.rwySurfaceSuitableRestrCONCGRVL === true &&
      this.rwySurfaceSuitableRestrGRVLDIRT === true &&
      this.rwySurfaceSuitableRestrGRVLTURF === true &&
      this.rwySurfaceSuitableRestrCORAL === true
      ) {
      this.rwySurfaceSuitableRestrGravel = true;
    } else {
      this.rwySurfaceSuitableRestrGravel = false;
    }
  }
  addRemoveGRVLfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrGRVL === false) {
      this.rwySurfaceSuitableRestrGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL');
    } else {
      this.rwySurfaceSuitableRestrGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrGravelTRUE();
    }
  }
  addRemoveGRVLTRTDfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrGRVLTRTD === false) {
      this.rwySurfaceSuitableRestrGRVLTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL-TRTD');
    } else {
      this.rwySurfaceSuitableRestrGRVLTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrGravelTRUE();
    }
  }
  addRemoveASPHGRVLfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrASPHGRVL === false) {
      this.rwySurfaceSuitableRestrASPHGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-GRVL');
    } else {
      this.rwySurfaceSuitableRestrASPHGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrGravelTRUE();
    }
  }
  addRemoveCONCGRVLfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrCONCGRVL === false) {
      this.rwySurfaceSuitableRestrCONCGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC-GRVL');
    } else {
      this.rwySurfaceSuitableRestrCONCGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrGravelTRUE();
    }
  }
  addRemoveGRVLDIRTfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrGRVLDIRT === false) {
      this.rwySurfaceSuitableRestrGRVLDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL-DIRT');
    } else {
      this.rwySurfaceSuitableRestrGRVLDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrGravelTRUE();
    }
  }
  addRemoveGRVLTURFfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrGRVLTURF === false) {
      this.rwySurfaceSuitableRestrGRVLTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRVL-TURF');
    } else {
      this.rwySurfaceSuitableRestrGRVLTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrGravelTRUE();
    }
  }
  addRemoveCORALfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrCORAL === false) {
      this.rwySurfaceSuitableRestrCORAL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CORAL');
    } else {
      this.rwySurfaceSuitableRestrCORAL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CORAL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrGravelTRUE();
    }
  }
  /* END. #2 */
  /* #3 */
  checkAreALLSuitableRestrALLDIRTTRUE() {
    if (this.rwySurfaceSuitableRestrDIRT === true &&
      this.rwySurfaceSuitableRestrDIRTTRTD === true &&
      this.rwySurfaceSuitableRestrASPHDIRT === true &&
      this.rwySurfaceSuitableRestrDIRTGRVL === true &&
      this.rwySurfaceSuitableRestrDIRTTURF === true &&
      this.rwySurfaceSuitableRestrCALICHE === true
      ) {
      this.rwySurfaceSuitableRestrALLDIRT = true;
    } else {
      this.rwySurfaceSuitableRestrALLDIRT = false;
    }
  }
  addRemoveDIRTfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrDIRT === false) {
      this.rwySurfaceSuitableRestrDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT');
    } else {
      this.rwySurfaceSuitableRestrDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLDIRTTRUE();
    }
  }
  addRemoveDIRTTRTDfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrDIRTTRTD === false) {
      this.rwySurfaceSuitableRestrDIRTTRTD = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT-TRTD');
    } else {
      this.rwySurfaceSuitableRestrDIRTTRTD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-TRTD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLDIRTTRUE();
    }
  }
  addRemoveASPHDIRTfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrASPHDIRT === false) {
      this.rwySurfaceSuitableRestrASPHDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-DIRT');
    } else {
      this.rwySurfaceSuitableRestrASPHDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLDIRTTRUE();
    }
  }
  addRemoveDIRTGRVLfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrDIRTGRVL === false) {
      this.rwySurfaceSuitableRestrDIRTGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT-GRVL');
    } else {
      this.rwySurfaceSuitableRestrDIRTGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLDIRTTRUE();
    }
  }
  addRemoveDIRTTURFfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrDIRTTURF === false) {
      this.rwySurfaceSuitableRestrDIRTTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DIRT-TURF');
    } else {
      this.rwySurfaceSuitableRestrDIRTTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLDIRTTRUE();
    }
  }
  addRemoveCALICHEfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrCALICHE === false) {
      this.rwySurfaceSuitableRestrCALICHE = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CALICHE');
    } else {
      this.rwySurfaceSuitableRestrCALICHE = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CALICHE') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLDIRTTRUE();
    }
  }
  /* END. #3 */
  /* #4 */
  checkAreALLSuitableRestrALLTURFTRUE() {
    if (this.rwySurfaceSuitableRestrTURF === true &&
      this.rwySurfaceSuitableRestrASPHTURF === true &&
      this.rwySurfaceSuitableRestrCONCTURF === true &&

      this.rwySurfaceSuitableRestrTURFGRVL === true &&
      this.rwySurfaceSuitableRestrTURFDIRT === true &&
      this.rwySurfaceSuitableRestrTURFSAND === true &&

      this.rwySurfaceSuitableRestrOILCHIPT === true &&
      this.rwySurfaceSuitableRestrSOD === true &&
      this.rwySurfaceSuitableRestrGRASS === true) {
      this.rwySurfaceSuitableRestrALLTURF = true;
    } else {
      this.rwySurfaceSuitableRestrALLTURF = false;
    }
  }
  addRemoveTURFfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrTURF === false) {
      this.rwySurfaceSuitableRestrTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF');
    } else {
      this.rwySurfaceSuitableRestrTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveASPHTURFfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrASPHTURF === false) {
      this.rwySurfaceSuitableRestrASPHTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ASPH-TURF');
    } else {
      this.rwySurfaceSuitableRestrASPHTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveCONCTURFfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrCONCTURF === false) {
      this.rwySurfaceSuitableRestrCONCTURF = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('CONC-TURF');
    } else {
      this.rwySurfaceSuitableRestrCONCTURF = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-TURF') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveTURFGRVLfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrTURFGRVL === false) {
      this.rwySurfaceSuitableRestrTURFGRVL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF-GRVL');
    } else {
      this.rwySurfaceSuitableRestrTURFGRVL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-GRVL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveTURFDIRTfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrTURFDIRT === false) {
      this.rwySurfaceSuitableRestrTURFDIRT = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF-DIRT');
    } else {
      this.rwySurfaceSuitableRestrTURFDIRT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-DIRT') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveTURFSANDfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrTURFSAND === false) {
      this.rwySurfaceSuitableRestrTURFSAND = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('TURF-SAND');
    } else {
      this.rwySurfaceSuitableRestrTURFSAND = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-SAND') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveOILCHIPTfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrOILCHIPT === false) {
      this.rwySurfaceSuitableRestrOILCHIPT = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('OIL&CHIP-T');
    } else {
      this.rwySurfaceSuitableRestrOILCHIPT = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'OIL&CHIP-T') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveSODfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrSOD === false) {
      this.rwySurfaceSuitableRestrSOD = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('SOD');
    } else {
      this.rwySurfaceSuitableRestrSOD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SOD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  addRemoveGRASSfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrGRASS === false) {
      this.rwySurfaceSuitableRestrGRASS = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('GRASS');
    } else {
      this.rwySurfaceSuitableRestrGRASS = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRASS') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrALLTURFTRUE();
    }
  }
  /* END. #4 */
  /* #5 */
  checkAreALLSuitableRestrSnowAndIce()  {
    if (this.rwySurfaceSuitableRestrSNOW === true && this.rwySurfaceSuitableRestrICE === true) {
        this.rwySurfaceSuitableRestrSnowAndIce = true;
    } else {
      this.rwySurfaceSuitableRestrSnowAndIce = false;
    }
  }
  addRemoveSNOWfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrSNOW === false) {
      this.rwySurfaceSuitableRestrSNOW = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('SNOW');
    } else {
      this.rwySurfaceSuitableRestrSNOW = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SNOW') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrSnowAndIce() ;
    }
  }
  addRemoveICEfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrICE === false) {
      this.rwySurfaceSuitableRestrICE = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ICE');
    } else {
      this.rwySurfaceSuitableRestrICE = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ICE') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrSnowAndIce() ;
    }
  }
  /* END. #5 */
  /* #6 */
  checkAreALLSuitableRestrAllWater() {
    if (this.rwySurfaceSuitableRestrWATER === true) {
        this.rwySurfaceSuitableRestrAllWater = true;
    } else {
      this.rwySurfaceSuitableRestrAllWater = false;
    }
  }
  addRemoveWATERfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrWATER === false) {
      this.rwySurfaceSuitableRestrWATER = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('WATER');
    } else {
      this.rwySurfaceSuitableRestrWATER = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'WATER') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrAllWater();
    }
  }
  /* END. #6 */
  /* #7 */
  checkAreALLSuitableRestrOtherTRUE() {
    if (this.rwySurfaceSuitableRestrALUMINUM === true &&
      this.rwySurfaceSuitableRestrBRICK === true &&
      this.rwySurfaceSuitableRestrDECK === true &&
      this.rwySurfaceSuitableRestrMATS === true &&
      this.rwySurfaceSuitableRestrMETAL === true &&

      this.rwySurfaceSuitableRestrROOFTOP === true &&
      this.rwySurfaceSuitableRestrSAND === true &&
      this.rwySurfaceSuitableRestrSTEEL === true &&
      this.rwySurfaceSuitableRestrSTEELCONC === true &&
      this.rwySurfaceSuitableRestrWOOD === true
      ) {
      this.rwySurfaceSuitableRestrOther = true;
    } else {
      this.rwySurfaceSuitableRestrOther = false;
    }
  }
  addRemoveALUMINUMfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrALUMINUM === false) {
      this.rwySurfaceSuitableRestrALUMINUM = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ALUMINUM');
    } else {
      this.rwySurfaceSuitableRestrALUMINUM = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ALUMINUM') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveBRICKfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrBRICK === false) {
      this.rwySurfaceSuitableRestrBRICK = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('BRICK');
    } else {
      this.rwySurfaceSuitableRestrBRICK = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'BRICK') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveDECKfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrDECK === false) {
      this.rwySurfaceSuitableRestrDECK = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('DECK');
    } else {
      this.rwySurfaceSuitableRestrDECK = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DECK') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveMATSfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrMATS === false) {
      this.rwySurfaceSuitableRestrMATS = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('MATS');
    } else {
      this.rwySurfaceSuitableRestrMATS = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'MATS') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveMETALfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrMETAL === false) {
      this.rwySurfaceSuitableRestrMETAL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('METAL');
    } else {
      this.rwySurfaceSuitableRestrMETAL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'METAL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveROOFTOPfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrROOFTOP === false) {
      this.rwySurfaceSuitableRestrROOFTOP = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('ROOFTOP');
    } else {
      this.rwySurfaceSuitableRestrROOFTOP = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ROOFTOP') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveSANDfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrSAND === false) {
      this.rwySurfaceSuitableRestrSAND = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('SAND');
    } else {
      this.rwySurfaceSuitableRestrSAND = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SAND') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveSTEELfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrSTEEL === false) {
      this.rwySurfaceSuitableRestrSTEEL = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('STEEL');
    } else {
      this.rwySurfaceSuitableRestrSTEEL = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'STEEL') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveSTEELCONCfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrSTEELCONC === false) {
      this.rwySurfaceSuitableRestrSTEELCONC = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('STEEL-CONC');
    } else {
      this.rwySurfaceSuitableRestrSTEELCONC = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'STEEL-CONC') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  addRemoveWOODfromSuiRestrArray() {
    if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray) {
    if (this.rwySurfaceSuitableRestrWOOD === false) {
      this.rwySurfaceSuitableRestrWOOD = true;
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray.push('WOOD');
    } else {
      this.rwySurfaceSuitableRestrWOOD = false;
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'WOOD') {
          this.aircraftConfigurator.rwySurfaceSuitableRestrArray.splice(i, 1);
        }
      }
    }
    this.checkAreALLSuitableRestrOtherTRUE();
    }
  }
  /* END. #7 */
  /* END.All add/remove param-s from RestrArray */

  MTOWandDOWinPercents(tmpMTOW: number, tmpDOW: number): number {
    let result: number = null;
    if (tmpMTOW !== null && tmpDOW !== null) {
      result = +((tmpDOW / tmpMTOW) * 100).toFixed();
    }
    return result;
  }

  changePageOnSignin(){
    this.router.navigate(['/signin']);
  }

  /* for Constructor's window */
  updateView(tmpID: number) {
    this.isFieldDisabledForModifying = true;
    this.onChangeDisabled();
    this.editButtonClicked = false;
    this.editError = false;
    this.FormSuccessfullySubmitted = false;
    this.clearButtonClicked = false;
    this.aircraftConfiguratorDeleted = false;
    this.getTheLatestAirportList();
    this.updateAircraftConfById(tmpID);
    this.aircraftConfiguratorWasChanged();
  }

  updateAircraftConfById(tmpID: number) {
    for (let id = 0; id < this.aircraftConfigurators.length; id++) {
      if (tmpID == this.aircraftConfigurators[id].idConfigurator) {
        this.updateAircraftConfByNumberFromArray(id);
      }
    } 
  }

  convertFeetsToMeters (feets: number, meters: number): number {
    if (feets === null) {
      return null;
    } else {
      return +(feets * 0.3048).toFixed(1);
    }
  }

  /* Elevation*/
  calculateElevationSuitableMinMeters(): void {
    if (this.aircraftConfigurator.elevationSuitableMin !== null) {
      this.aircraftConfigurator.elevationSuitableMinMeters = +(this.aircraftConfigurator.elevationSuitableMin * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableMinMeters = null;
    }
  }

  calculateElevationSuitableMaxMeters(): void {
    if (this.aircraftConfigurator.elevationSuitableMax !== null) {
      this.aircraftConfigurator.elevationSuitableMaxMeters = +(this.aircraftConfigurator.elevationSuitableMax * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableMaxMeters = null;
    }
  }

  calculateElevationSuitableMinFeet(): void {
    if (this.aircraftConfigurator.elevationSuitableMinMeters !== null) {
      this.aircraftConfigurator.elevationSuitableMin = +(this.aircraftConfigurator.elevationSuitableMinMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableMin = null;
    }
  }

  calculateElevationSuitableMaxFeet(): void {
    if (this.aircraftConfigurator.elevationSuitableMaxMeters !== null) {
      this.aircraftConfigurator.elevationSuitableMax = +(this.aircraftConfigurator.elevationSuitableMaxMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableMax = null;
    }
  }

  calculateElevationSuitableRestrMinMeters(): void {
    if (this.aircraftConfigurator.elevationSuitableRestrMin !== null) {
      this.aircraftConfigurator.elevationSuitableRestrMinMeters = +(this.aircraftConfigurator.elevationSuitableRestrMin * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableRestrMinMeters = null;
    }
  }

  calculateElevationSuitableRestrMaxMeters(): void {
    if (this.aircraftConfigurator.elevationSuitableRestrMax !== null) {
    this.aircraftConfigurator.elevationSuitableRestrMaxMeters = +(this.aircraftConfigurator.elevationSuitableRestrMax * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableRestrMaxMeters = null;
    }
  }

  calculateElevationSuitableRestrMinFeet(): void {
    if (this.aircraftConfigurator.elevationSuitableRestrMinMeters !== null) {
      this.aircraftConfigurator.elevationSuitableRestrMin = +(this.aircraftConfigurator.elevationSuitableRestrMinMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableRestrMin = null;
    }
  }

  calculateElevationSuitableRestrMaxFeet(): void {
    if (this.aircraftConfigurator.elevationSuitableRestrMaxMeters !== null) {
      this.aircraftConfigurator.elevationSuitableRestrMax = +(this.aircraftConfigurator.elevationSuitableRestrMaxMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.elevationSuitableRestrMax = null;
    }
  }
  /* END. Elevation*/

  /* RWY Length */
  calculateRWYLengthSuitableMinMeters(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableMin !== null) {
      this.aircraftConfigurator.rwyLengthSuitableMinMeters = +(this.aircraftConfigurator.rwyLengthSuitableMin * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableMinMeters = null;
    }
  }

  calculateRWYLengthSuitableMaxMeters(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableMax !== null) {
      this.aircraftConfigurator.rwyLengthSuitableMaxMeters = +(this.aircraftConfigurator.rwyLengthSuitableMax * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableMaxMeters = null;
    }
  }

  calculateRWYLengthSuitableMinFeet(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableMinMeters !== null) {
      this.aircraftConfigurator.rwyLengthSuitableMin = +(this.aircraftConfigurator.rwyLengthSuitableMinMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableMin = null;
    }
  }

  calculateRWYLengthSuitableMaxFeet(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableMaxMeters !== null) {
      this.aircraftConfigurator.rwyLengthSuitableMax = +(this.aircraftConfigurator.rwyLengthSuitableMaxMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableMax = null;
    }
  }

  calculateRWYLengthSuitableRestrMinMeters(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableRestrMin !== null) {
      this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters = +(this.aircraftConfigurator.rwyLengthSuitableRestrMin * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters = null;
    }
  }

  calculateRWYLengthSuitableRestrMaxMeters(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableRestrMax !== null) {
    this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters = +(this.aircraftConfigurator.rwyLengthSuitableRestrMax * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters = null;
    }
  }

  calculateRWYLengthSuitableRestrMinFeet(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters !== null) {
      this.aircraftConfigurator.rwyLengthSuitableRestrMin = +(this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableRestrMin = null;
    }
  }

  calculateRWYLengthSuitableRestrMaxFeet(): void {
    if (this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters !== null) {
      this.aircraftConfigurator.rwyLengthSuitableRestrMax = +(this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyLengthSuitableRestrMax = null;
    }
  }
  /* END. RWY Length */

  /* RWY Width */
  calculateRWYWidthSuitableMinMeters(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableMin !== null) {
      this.aircraftConfigurator.rwyWidthSuitableMinMeters = +(this.aircraftConfigurator.rwyWidthSuitableMin * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableMinMeters = null;
    }
  }

  calculateRWYWidthSuitableMaxMeters(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableMax !== null) {
      this.aircraftConfigurator.rwyWidthSuitableMaxMeters = +(this.aircraftConfigurator.rwyWidthSuitableMax * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableMaxMeters = null;
    }
  }

  calculateRWYWidthSuitableMinFeet(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableMinMeters !== null) {
      this.aircraftConfigurator.rwyWidthSuitableMin = +(this.aircraftConfigurator.rwyWidthSuitableMinMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableMin = null;
    }
  }

  calculateRWYWidthSuitableMaxFeet(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableMaxMeters !== null) {
      this.aircraftConfigurator.rwyWidthSuitableMax = +(this.aircraftConfigurator.rwyWidthSuitableMaxMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableMax = null;
    }
  }

  calculateRWYWidthSuitableRestrMinMeters(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableRestrMin !== null) {
      this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters = +(this.aircraftConfigurator.rwyWidthSuitableRestrMin * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters = null;
    }
  }

  calculateRWYWidthSuitableRestrMaxMeters(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableRestrMax !== null) {
    this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters = +(this.aircraftConfigurator.rwyWidthSuitableRestrMax * 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters = null;
    }
  }

  calculateRWYWidthSuitableRestrMinFeet(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters !== null) {
      this.aircraftConfigurator.rwyWidthSuitableRestrMin = +(this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableRestrMin = null;
    }
  }

  calculateRWYWidthSuitableRestrMaxFeet(): void {
    if (this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters !== null) {
      this.aircraftConfigurator.rwyWidthSuitableRestrMax = +(this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters / 0.3048).toFixed(1);
    } else {
      this.aircraftConfigurator.rwyWidthSuitableRestrMax = null;
    }
  }
  /* END. RWY Width */

  updateAircraftConfByNumberFromArray(id: number) {
    this.aircraftConfiguratorID = this.aircraftConfigurators[id].idConfigurator;
    this.aircraftConfigurator.idConfigurator = this.aircraftConfigurators[id].idConfigurator;

    this.aircraftConfigurator.username = this.aircraftConfigurators[id].username;
    this.aircraftConfigurator.created_date = this.aircraftConfigurators[id].created_date;
    this.aircraftConfigurator.last_updated_date = this.aircraftConfigurators[id].last_updated_date;

    this.aircraftConfigurator.aircraftName = this.aircraftConfigurators[id].aircraftName;

    this.aircraftConfigurator.elevationSuitableMin = this.aircraftConfigurators[id].elevationSuitableMin;
    this.aircraftConfigurator.elevationSuitableMinMeters = this.convertFeetsToMeters(this.aircraftConfigurator.elevationSuitableMin, 
                                                            this.aircraftConfigurators[id].elevationSuitableMinMeters);
    
    this.aircraftConfigurator.elevationSuitableMax = this.aircraftConfigurators[id].elevationSuitableMax;
    this.aircraftConfigurator.elevationSuitableMaxMeters = this.convertFeetsToMeters(this.aircraftConfigurator.elevationSuitableMax, 
                                                            this.aircraftConfigurators[id].elevationSuitableMaxMeters);


    this.aircraftConfigurator.elevationSuitableRestrMin = this.aircraftConfigurators[id].elevationSuitableRestrMin;
    this.aircraftConfigurator.elevationSuitableRestrMinMeters = this.convertFeetsToMeters(this.aircraftConfigurator.elevationSuitableRestrMin, 
                                                                  this.aircraftConfigurators[id].elevationSuitableRestrMinMeters);

    this.aircraftConfigurator.elevationSuitableRestrMax = this.aircraftConfigurators[id].elevationSuitableRestrMax;
    this.aircraftConfigurator.elevationSuitableRestrMaxMeters = this.convertFeetsToMeters(this.aircraftConfigurator.elevationSuitableRestrMax, 
                                                                  this.aircraftConfigurators[id].elevationSuitableRestrMaxMeters);
    
    this.aircraftConfigurator.elevationNotSuitableMin = this.aircraftConfigurators[id].elevationNotSuitableMin;
    this.aircraftConfigurator.elevationNotSuitableMax = this.aircraftConfigurators[id].elevationNotSuitableMax;
   
    this.aircraftConfigurator.elevationSuitableRemark = this.aircraftConfigurators[id].elevationSuitableRemark;
    this.aircraftConfigurator.elevationSuitableRestrRemark = this.aircraftConfigurators[id].elevationSuitableRestrRemark;

    this.aircraftConfigurator.turnOnOffMaxElevationAll = this.aircraftConfigurators[id].turnOnOffMaxElevationAll;
    this.aircraftConfigurator.turnOnOffMaxElevationSuitable = this.aircraftConfigurators[id].turnOnOffMaxElevationSuitable;
    this.aircraftConfigurator.turnOnOffMaxElevationRestr = this.aircraftConfigurators[id].turnOnOffMaxElevationRestr;
    this.aircraftConfigurator.turnOnOffMaxElevationUnSuitable = this.aircraftConfigurators[id].turnOnOffMaxElevationUnSuitable;
 
    this.aircraftConfigurator.rwyLengthSuitableMin = this.aircraftConfigurators[id].rwyLengthSuitableMin;
    this.aircraftConfigurator.rwyLengthSuitableMinMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyLengthSuitableMin, 
      this.aircraftConfigurators[id].rwyLengthSuitableMinMeters);

    this.aircraftConfigurator.rwyLengthSuitableMax = this.aircraftConfigurators[id].rwyLengthSuitableMax;
    this.aircraftConfigurator.rwyLengthSuitableMaxMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyLengthSuitableMax, 
      this.aircraftConfigurators[id].rwyLengthSuitableMaxMeters);

    this.aircraftConfigurator.rwyLengthSuitableRestrMin = this.aircraftConfigurators[id].rwyLengthSuitableRestrMin;
    this.aircraftConfigurator.rwyLengthSuitableRestrMinMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyLengthSuitableRestrMin, 
      this.aircraftConfigurators[id].rwyLengthSuitableRestrMinMeters);

    this.aircraftConfigurator.rwyLengthSuitableRestrMax = this.aircraftConfigurators[id].rwyLengthSuitableRestrMax;
    this.aircraftConfigurator.rwyLengthSuitableRestrMaxMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyLengthSuitableRestrMax, 
      this.aircraftConfigurators[id].rwyLengthSuitableRestrMaxMeters);

    this.aircraftConfigurator.rwyLengthNotSuitableMin = this.aircraftConfigurators[id].rwyLengthNotSuitableMin;
    this.aircraftConfigurator.rwyLengthNotSuitableMax = this.aircraftConfigurators[id].rwyLengthNotSuitableMax;

    this.aircraftConfigurator.rwyLengthSuitableRemark = this.aircraftConfigurators[id].rwyLengthSuitableRemark;
    this.aircraftConfigurator.rwyLengthSuitableRestrRemark = this.aircraftConfigurators[id].rwyLengthSuitableRestrRemark;
    
    this.aircraftConfigurator.turnOnOffMinRunwayLengthAll = this.aircraftConfigurators[id].turnOnOffMinRunwayLengthAll;
    this.aircraftConfigurator.turnOnOffMinRunwayLengthSuitable = this.aircraftConfigurators[id].turnOnOffMinRunwayLengthSuitable;
    this.aircraftConfigurator.turnOnOffMinRunwayLengthRestr = this.aircraftConfigurators[id].turnOnOffMinRunwayLengthRestr;
    this.aircraftConfigurator.turnOnOffMinRunwayLengthUnSuitable = this.aircraftConfigurators[id].turnOnOffMinRunwayLengthUnSuitable;

    this.aircraftConfigurator.rwyWidthSuitableMin = this.aircraftConfigurators[id].rwyWidthSuitableMin;
    this.aircraftConfigurator.rwyWidthSuitableMinMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyWidthSuitableMin, 
      this.aircraftConfigurators[id].rwyWidthSuitableMinMeters);

    this.aircraftConfigurator.rwyWidthSuitableMax = this.aircraftConfigurators[id].rwyWidthSuitableMax;
    this.aircraftConfigurator.rwyWidthSuitableMaxMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyWidthSuitableMax, 
      this.aircraftConfigurators[id].rwyWidthSuitableMaxMeters);

    this.aircraftConfigurator.rwyWidthSuitableRestrMin = this.aircraftConfigurators[id].rwyWidthSuitableRestrMin;
    this.aircraftConfigurator.rwyWidthSuitableRestrMinMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyWidthSuitableRestrMin, 
      this.aircraftConfigurators[id].rwyWidthSuitableRestrMinMeters);

    this.aircraftConfigurator.rwyWidthSuitableRestrMax = this.aircraftConfigurators[id].rwyWidthSuitableRestrMax;
    this.aircraftConfigurator.rwyWidthSuitableRestrMaxMeters = this.convertFeetsToMeters(this.aircraftConfigurator.rwyWidthSuitableRestrMax, 
      this.aircraftConfigurators[id].rwyWidthSuitableRestrMaxMeters);

    this.aircraftConfigurator.rwyWidthNotSuitableMin = this.aircraftConfigurators[id].rwyWidthNotSuitableMin;
    this.aircraftConfigurator.rwyWidthNotSuitableMax = this.aircraftConfigurators[id].rwyWidthNotSuitableMax;

    this.aircraftConfigurator.rwyWidthSuitableRemark = this.aircraftConfigurators[id].rwyWidthSuitableRemark;
    this.aircraftConfigurator.rwyWidthSuitableRestrRemark = this.aircraftConfigurators[id].rwyWidthSuitableRestrRemark;

    this.aircraftConfigurator.turnOnOffMinRunwayWidthAll = this.aircraftConfigurators[id].turnOnOffMinRunwayWidthAll;
    this.aircraftConfigurator.turnOnOffMinRunwayWidthSuitable = this.aircraftConfigurators[id].turnOnOffMinRunwayWidthSuitable;
    this.aircraftConfigurator.turnOnOffMinRunwayWidthRestr = this.aircraftConfigurators[id].turnOnOffMinRunwayWidthRestr;
    this.aircraftConfigurator.turnOnOffMinRunwayWidthUnSuitable = this.aircraftConfigurators[id].turnOnOffMinRunwayWidthUnSuitable;

    this.aircraftConfigurator.rwySlopeSuitableMin = this.aircraftConfigurators[id].rwySlopeSuitableMin;
    this.aircraftConfigurator.rwySlopeSuitableMin = this.aircraftConfigurators[id].rwySlopeSuitableMin;
    this.aircraftConfigurator.rwySlopeSuitableMax = this.aircraftConfigurators[id].rwySlopeSuitableMax;
    this.aircraftConfigurator.rwySlopeSuitableMax = this.aircraftConfigurators[id].rwySlopeSuitableMax;
    this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin = this.aircraftConfigurators[id].rwySlopeSuitableFirstRestrMin;
    this.aircraftConfigurator.rwySlopeSuitableFirstRestrMin = this.aircraftConfigurators[id].rwySlopeSuitableFirstRestrMin;
    this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax = this.aircraftConfigurators[id].rwySlopeSuitableFirstRestrMax;
    this.aircraftConfigurator.rwySlopeSuitableFirstRestrMax = this.aircraftConfigurators[id].rwySlopeSuitableFirstRestrMax;
    this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin = this.aircraftConfigurators[id].rwySlopeSuitableSecondRestrMin;
    this.aircraftConfigurator.rwySlopeSuitableSecondRestrMin = this.aircraftConfigurators[id].rwySlopeSuitableSecondRestrMin;
    this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax = this.aircraftConfigurators[id].rwySlopeSuitableSecondRestrMax;
    this.aircraftConfigurator.rwySlopeSuitableSecondRestrMax = this.aircraftConfigurators[id].rwySlopeSuitableSecondRestrMax;
    this.aircraftConfigurator.rwySlopeNotSuitableMin = this.aircraftConfigurators[id].rwySlopeNotSuitableMin;
    this.aircraftConfigurator.rwySlopeNotSuitableMax = this.aircraftConfigurators[id].rwySlopeNotSuitableMax;

    this.aircraftConfigurator.rwySlopeSuitableRemark = this.aircraftConfigurators[id].rwySlopeSuitableRemark;
    this.aircraftConfigurator.rwySlopeSuitableFirstRestrRemark = this.aircraftConfigurators[id].rwySlopeSuitableFirstRestrRemark;
    this.aircraftConfigurator.rwySlopeSuitableSecondRestrRemark = this.aircraftConfigurators[id].rwySlopeSuitableSecondRestrRemark;

    this.aircraftConfigurator.turnOnOffMaxSlopeAll = this.aircraftConfigurators[id].turnOnOffMaxSlopeAll;
    this.aircraftConfigurator.turnOnOffMaxSlopeSuitable = this.aircraftConfigurators[id].turnOnOffMaxSlopeSuitable;
    this.aircraftConfigurator.turnOnOffMaxSlopeFirstRestr = this.aircraftConfigurators[id].turnOnOffMaxSlopeFirstRestr;
    this.aircraftConfigurator.turnOnOffMaxSlopeSecondRestr = this.aircraftConfigurators[id].turnOnOffMaxSlopeSecondRestr;
    this.aircraftConfigurator.turnOnOffMaxSlopeUnSuitable = this.aircraftConfigurators[id].turnOnOffMaxSlopeUnSuitable;

    this.makeAllSurfaceParamsFalse();

    this.aircraftConfigurator.rwySurfaceSuitable = this.aircraftConfigurators[id].rwySurfaceSuitable;
    this.aircraftConfigurator.rwySurfaceSuitableRestr = this.aircraftConfigurators[id].rwySurfaceSuitableRestr;
    this.aircraftConfigurator.rwySurfaceNotSuitable = this.aircraftConfigurators[id].rwySurfaceNotSuitable;

    if (this.aircraftConfigurator.rwySurfaceSuitable != null && this.aircraftConfigurator.rwySurfaceSuitable.length > 0) {
      this.aircraftConfigurator.rwySurfaceSuitableArray = this.aircraftConfigurator.rwySurfaceSuitable.split(',');
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableArray.length; i++) {
        /*BEGIN. ASPH-CONC*/
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH') {
          this.rwySurfaceSuitableASPH = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-TRTD') {
          this.rwySurfaceSuitableASPHTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC') {
          this.rwySurfaceSuitableCONC = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-TRTD') {
          this.rwySurfaceSuitableCONCTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-CONC') {
          this.rwySurfaceSuitableASPHCONC = true;
        }
        if (this.rwySurfaceSuitableASPH === true && this.rwySurfaceSuitableASPHTRTD === true &&
            this.rwySurfaceSuitableCONC === true && this.rwySurfaceSuitableCONCTRTD === true &&
            this.rwySurfaceSuitableASPHCONC === true) {
              this.rwySurfaceSuitableAsphaltAndConcrete = true;
        }
        /*END. ASPH-CONC*/
        /*BEGIN. GRAVEL*/
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL') {
          this.rwySurfaceSuitableGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-TRTD') {
          this.rwySurfaceSuitableGRVLTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-GRVL') {
          this.rwySurfaceSuitableASPHGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-GRVL') {
          this.rwySurfaceSuitableCONCGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-DIRT') {
          this.rwySurfaceSuitableGRVLDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRVL-TURF') {
          this.rwySurfaceSuitableGRVLTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CORAL') {
          this.rwySurfaceSuitableCORAL = true;
        }
        if (this.rwySurfaceSuitableGRVL === true && this.rwySurfaceSuitableGRVLTRTD === true &&
            this.rwySurfaceSuitableASPHGRVL === true && this.rwySurfaceSuitableCONCGRVL === true &&
            this.rwySurfaceSuitableGRVLDIRT === true && this.rwySurfaceSuitableGRVLTURF === true &&
            this.rwySurfaceSuitableCORAL === true) {
              this.rwySurfaceSuitableGravel = true;
        }
        /*END. GRAVEL*/
        /*BEGIN. DIRT*/
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT') {
          this.rwySurfaceSuitableDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-TRTD') {
          this.rwySurfaceSuitableDIRTTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-DIRT') {
          this.rwySurfaceSuitableASPHDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-GRVL') {
          this.rwySurfaceSuitableDIRTGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DIRT-TURF') {
          this.rwySurfaceSuitableDIRTTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CALICHE') {
          this.rwySurfaceSuitableCALICHE = true;
        }

        if (this.rwySurfaceSuitableDIRT === true && this.rwySurfaceSuitableDIRTTRTD === true &&
            this.rwySurfaceSuitableASPHDIRT === true && this.rwySurfaceSuitableDIRTGRVL === true &&
            this.rwySurfaceSuitableDIRTTURF === true && this.rwySurfaceSuitableCALICHE === true) {
              this.rwySurfaceSuitableALLDIRT = true;
        }
        /*END. DIRT*/
        /*BEGIN. TURF*/
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF') {
          this.rwySurfaceSuitableTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ASPH-TURF') {
          this.rwySurfaceSuitableASPHTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'CONC-TURF') {
          this.rwySurfaceSuitableCONCTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-GRVL') {
          this.rwySurfaceSuitableTURFGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-DIRT') {
          this.rwySurfaceSuitableTURFDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'TURF-SAND') {
          this.rwySurfaceSuitableTURFSAND = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'OIL&CHIP-T') {
          this.rwySurfaceSuitableOILCHIPT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SOD') {
          this.rwySurfaceSuitableSOD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'GRASS') {
          this.rwySurfaceSuitableGRASS = true;
        }

        if (this.rwySurfaceSuitableTURF === true && this.rwySurfaceSuitableASPHTURF === true &&
            this.rwySurfaceSuitableCONCTURF === true && this.rwySurfaceSuitableTURFGRVL === true &&
            this.rwySurfaceSuitableTURFDIRT === true && this.rwySurfaceSuitableTURFSAND === true &&
            this.rwySurfaceSuitableOILCHIPT === true && this.rwySurfaceSuitableSOD === true &&
            this.rwySurfaceSuitableGRASS === true) {
              this.rwySurfaceSuitableALLTURF = true;
        }
        /*END. TURF*/
        /*BEGIN. SnowAndIce*/
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SNOW') {
          this.rwySurfaceSuitableSNOW = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ICE') {
          this.rwySurfaceSuitableICE = true;
        }

        if (this.rwySurfaceSuitableSNOW === true && this.rwySurfaceSuitableICE === true) {
              this.rwySurfaceSuitableSnowAndIce = true;
        }
        /*END. SnowAndIce*/
        /*BEGIN. Water*/
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'WATER') {
          this.rwySurfaceSuitableWATER = true;
        }

        if (this.rwySurfaceSuitableWATER === true) {
              this.rwySurfaceSuitableAllWater = true;
        }
        /*END. Water*/
        /*BEGIN. Other*/
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ALUMINUM') {
          this.rwySurfaceSuitableALUMINUM = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'BRICK') {
          this.rwySurfaceSuitableBRICK = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'DECK') {
          this.rwySurfaceSuitableDECK = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'MATS') {
          this.rwySurfaceSuitableMATS = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'METAL') {
          this.rwySurfaceSuitableMETAL = true;
        }

        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'ROOFTOP') {
          this.rwySurfaceSuitableROOFTOP = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'SAND') {
          this.rwySurfaceSuitableSAND = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'STEEL') {
          this.rwySurfaceSuitableSTEEL  = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'STEEL-CONC') {
          this.rwySurfaceSuitableSTEELCONC = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableArray[i] === 'WOOD') {
          this.rwySurfaceSuitableWOOD = true;
        }

        if (this.rwySurfaceSuitableALUMINUM === true && this.rwySurfaceSuitableBRICK === true &&
          this.rwySurfaceSuitableDECK === true && this.rwySurfaceSuitableMATS === true &&
          this.rwySurfaceSuitableMETAL === true && this.rwySurfaceSuitableROOFTOP === true &&
          this.rwySurfaceSuitableSAND === true && this.rwySurfaceSuitableSTEEL === true &&
          this.rwySurfaceSuitableSTEELCONC === true && this.rwySurfaceSuitableWOOD === true) {
            this.rwySurfaceSuitableOther = true;
        }
        /*END. Other*/
      }
    }

    if (this.aircraftConfigurator.rwySurfaceSuitableRestr != null && this.aircraftConfigurator.rwySurfaceSuitableRestr.length > 0) {
      this.aircraftConfigurator.rwySurfaceSuitableRestrArray = this.aircraftConfigurator.rwySurfaceSuitableRestr.split(',');
      for (let i = 0; i < this.aircraftConfigurator.rwySurfaceSuitableRestrArray.length; i++) {
        /*BEGIN. ASPH-CONC*/
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH') {
          this.rwySurfaceSuitableRestrASPH = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-TRTD') {
          this.rwySurfaceSuitableRestrASPHTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC') {
          this.rwySurfaceSuitableRestrCONC = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-TRTD') {
          this.rwySurfaceSuitableRestrCONCTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-CONC') {
          this.rwySurfaceSuitableRestrASPHCONC = true;
        }
        if (this.rwySurfaceSuitableRestrASPH === true && this.rwySurfaceSuitableRestrASPHTRTD === true &&
            this.rwySurfaceSuitableRestrCONC === true && this.rwySurfaceSuitableRestrCONCTRTD === true &&
            this.rwySurfaceSuitableRestrASPHCONC === true) {
              this.rwySurfaceSuitableRestrAsphaltAndConcrete = true;
        }
        /*END. ASPH-CONC*/
        /*BEGIN. GRAVEL*/
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL') {
          this.rwySurfaceSuitableRestrGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-TRTD') {
          this.rwySurfaceSuitableRestrGRVLTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-GRVL') {
          this.rwySurfaceSuitableRestrASPHGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-GRVL') {
          this.rwySurfaceSuitableRestrCONCGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-DIRT') {
          this.rwySurfaceSuitableRestrGRVLDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRVL-TURF') {
          this.rwySurfaceSuitableRestrGRVLTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CORAL') {
          this.rwySurfaceSuitableRestrCORAL = true;
        }
        if (this.rwySurfaceSuitableRestrGRVL === true && this.rwySurfaceSuitableRestrGRVLTRTD === true &&
            this.rwySurfaceSuitableRestrASPHGRVL === true && this.rwySurfaceSuitableRestrCONCGRVL === true &&
            this.rwySurfaceSuitableRestrGRVLDIRT === true && this.rwySurfaceSuitableRestrGRVLTURF === true &&
            this.rwySurfaceSuitableRestrCORAL === true) {
              this.rwySurfaceSuitableRestrGravel = true;
        }
        /*END. GRAVEL*/
        /*BEGIN. DIRT*/
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT') {
          this.rwySurfaceSuitableRestrDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-TRTD') {
          this.rwySurfaceSuitableRestrDIRTTRTD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-DIRT') {
          this.rwySurfaceSuitableRestrASPHDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-GRVL') {
          this.rwySurfaceSuitableRestrDIRTGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DIRT-TURF') {
          this.rwySurfaceSuitableRestrDIRTTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CALICHE') {
          this.rwySurfaceSuitableRestrCALICHE = true;
        }

        if (this.rwySurfaceSuitableRestrDIRT === true && this.rwySurfaceSuitableRestrDIRTTRTD === true &&
            this.rwySurfaceSuitableRestrASPHDIRT === true && this.rwySurfaceSuitableRestrDIRTGRVL === true &&
            this.rwySurfaceSuitableRestrDIRTTURF === true && this.rwySurfaceSuitableRestrCALICHE === true) {
              this.rwySurfaceSuitableRestrALLDIRT = true;
        }
        /*END. DIRT*/
        /*BEGIN. TURF*/
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF') {
          this.rwySurfaceSuitableRestrTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ASPH-TURF') {
          this.rwySurfaceSuitableRestrASPHTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'CONC-TURF') {
          this.rwySurfaceSuitableRestrCONCTURF = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-GRVL') {
          this.rwySurfaceSuitableRestrTURFGRVL = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-DIRT') {
          this.rwySurfaceSuitableRestrTURFDIRT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'TURF-SAND') {
          this.rwySurfaceSuitableRestrTURFSAND = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'OIL&CHIP-T') {
          this.rwySurfaceSuitableRestrOILCHIPT = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SOD') {
          this.rwySurfaceSuitableRestrSOD = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'GRASS') {
          this.rwySurfaceSuitableRestrGRASS = true;
        }

        if (this.rwySurfaceSuitableRestrTURF === true && this.rwySurfaceSuitableRestrASPHTURF === true &&
            this.rwySurfaceSuitableRestrCONCTURF === true && this.rwySurfaceSuitableRestrTURFGRVL === true &&
            this.rwySurfaceSuitableRestrTURFDIRT === true && this.rwySurfaceSuitableRestrTURFSAND === true &&
            this.rwySurfaceSuitableRestrOILCHIPT === true && this.rwySurfaceSuitableRestrSOD === true &&
            this.rwySurfaceSuitableRestrGRASS === true) {
              this.rwySurfaceSuitableRestrALLTURF = true;
        }
        /*END. TURF*/
        /*BEGIN. SnowAndIce*/
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SNOW') {
          this.rwySurfaceSuitableRestrSNOW = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ICE') {
          this.rwySurfaceSuitableRestrICE = true;
        }

        if (this.rwySurfaceSuitableRestrSNOW === true && this.rwySurfaceSuitableRestrICE === true) {
              this.rwySurfaceSuitableRestrSnowAndIce = true;
        }
        /*END. SnowAndIce*/
        /*BEGIN. Water*/
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'WATER') {
          this.rwySurfaceSuitableRestrWATER = true;
        }

        if (this.rwySurfaceSuitableRestrWATER === true) {
              this.rwySurfaceSuitableRestrAllWater = true;
        }
        /*END. Water*/
        /*BEGIN. Other*/
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ALUMINUM') {
          this.rwySurfaceSuitableRestrALUMINUM = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'BRICK') {
          this.rwySurfaceSuitableRestrBRICK = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'DECK') {
          this.rwySurfaceSuitableRestrDECK = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'MATS') {
          this.rwySurfaceSuitableRestrMATS = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'METAL') {
          this.rwySurfaceSuitableRestrMETAL = true;
        }

        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'ROOFTOP') {
          this.rwySurfaceSuitableRestrROOFTOP = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'SAND') {
          this.rwySurfaceSuitableRestrSAND = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'STEEL') {
          this.rwySurfaceSuitableRestrSTEEL  = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'STEEL-CONC') {
          this.rwySurfaceSuitableRestrSTEELCONC = true;
        }
        if (this.aircraftConfigurator.rwySurfaceSuitableRestrArray[i] === 'WOOD') {
          this.rwySurfaceSuitableRestrWOOD = true;
        }

        if (this.rwySurfaceSuitableRestrALUMINUM === true && this.rwySurfaceSuitableRestrBRICK === true &&
          this.rwySurfaceSuitableRestrDECK === true && this.rwySurfaceSuitableRestrMATS === true &&
          this.rwySurfaceSuitableRestrMETAL === true && this.rwySurfaceSuitableRestrROOFTOP === true &&
          this.rwySurfaceSuitableRestrSAND === true && this.rwySurfaceSuitableRestrSTEEL === true &&
          this.rwySurfaceSuitableRestrSTEELCONC === true && this.rwySurfaceSuitableRestrWOOD === true) {
            this.rwySurfaceSuitableRestrOther = true;
        }
        /*END. Other*/
      }
    }
    
    this.aircraftConfigurator.rwySurfaceSuitableRemark = this.aircraftConfigurators[id].rwySurfaceSuitableRemark;
    this.aircraftConfigurator.rwySurfaceSuitableRestrRemark = this.aircraftConfigurators[id].rwySurfaceSuitableRestrRemark;

    this.aircraftConfigurator.turnOnOffSurfaceSuitableAll = this.aircraftConfigurators[id].turnOnOffSurfaceSuitableAll;
    this.aircraftConfigurator.turnOnOffSurfaceSuitable = this.aircraftConfigurators[id].turnOnOffSurfaceSuitable;
    this.aircraftConfigurator.turnOnOffSurfaceSuitableRestr = this.aircraftConfigurators[id].turnOnOffSurfaceSuitableRestr;
    this.aircraftConfigurator.turnOnOffSurfaceUnSuitable = this.aircraftConfigurators[id].turnOnOffSurfaceUnSuitable;

    this.aircraftConfigurator.rwyStrengthSuitableMin = this.aircraftConfigurators[id].rwyStrengthSuitableMin;
    this.aircraftConfigurator.rwyStrengthSuitableMax = this.aircraftConfigurators[id].rwyStrengthSuitableMax;
    this.aircraftConfigurator.rwyStrengthSuitableRestrMin = this.aircraftConfigurators[id].rwyStrengthSuitableRestrMin;
    this.aircraftConfigurator.rwyStrengthSuitableRestrMax = this.aircraftConfigurators[id].rwyStrengthSuitableRestrMax;
    this.aircraftConfigurator.rwyStrengthNotSuitableMin = this.aircraftConfigurators[id].rwyStrengthNotSuitableMin;
    this.aircraftConfigurator.rwyStrengthNotSuitableMax = this.aircraftConfigurators[id].rwyStrengthNotSuitableMax;

    this.aircraftConfigurator.rwyStrengthSuitableRemark = this.aircraftConfigurators[id].rwyStrengthSuitableRemark;
    this.aircraftConfigurator.rwyStrengthSuitableRestRemark = this.aircraftConfigurators[id].rwyStrengthSuitableRestRemark;

    this.aircraftConfigurator.turnOnOffMinRunwayStrengthAll = this.aircraftConfigurators[id].turnOnOffMinRunwayStrengthAll;
    this.aircraftConfigurator.turnOnOffMinRunwayStrengthSuitable = this.aircraftConfigurators[id].turnOnOffMinRunwayStrengthSuitable;
    this.aircraftConfigurator.turnOnOffMinRunwayStrengthRestr = this.aircraftConfigurators[id].turnOnOffMinRunwayStrengthRestr;
    this.aircraftConfigurator.turnOnOffMinRunwayStrengthUnSuitable = this.aircraftConfigurators[id].turnOnOffMinRunwayStrengthUnSuitable;

    this.aircraftConfigurator.refCodeSuitableMin = this.aircraftConfigurators[id].refCodeSuitableMin;
    this.aircraftConfigurator.refCodeSuitableMax = this.aircraftConfigurators[id].refCodeSuitableMax;
    this.aircraftConfigurator.refCodeSuitableRestrMin = this.aircraftConfigurators[id].refCodeSuitableRestrMin;
    this.aircraftConfigurator.refCodeSuitableRestrMax = this.aircraftConfigurators[id].refCodeSuitableRestrMax;
    this.aircraftConfigurator.refCodeNotSuitableMin = this.aircraftConfigurators[id].refCodeNotSuitableMin;
    this.aircraftConfigurator.refCodeNotSuitableMax = this.aircraftConfigurators[id].refCodeNotSuitableMax;

    this.aircraftConfigurator.arffSuitableMin = this.aircraftConfigurators[id].arffSuitableMin;
    this.aircraftConfigurator.arffSuitableMax = this.aircraftConfigurators[id].arffSuitableMax;
    this.aircraftConfigurator.arffSuitableRestrMin = this.aircraftConfigurators[id].arffSuitableRestrMin;
    this.aircraftConfigurator.arffSuitableRestrMax = this.aircraftConfigurators[id].arffSuitableRestrMax;
    this.aircraftConfigurator.arffNotSuitableMin = this.aircraftConfigurators[id].arffNotSuitableMin;
    this.aircraftConfigurator.arffNotSuitableMax = this.aircraftConfigurators[id].arffNotSuitableMax;

    this.aircraftConfigurator.arffsuitableremark = this.aircraftConfigurators[id].arffsuitableremark;
    this.aircraftConfigurator.arffsuitablerestrremark = this.aircraftConfigurators[id].arffsuitablerestrremark;

    this.aircraftConfigurator.turnOnOffMinARFFAll = this.aircraftConfigurators[id].turnOnOffMinARFFAll;
    this.aircraftConfigurator.turnOnOffMinARFFSuitable = this.aircraftConfigurators[id].turnOnOffMinARFFSuitable;
    this.aircraftConfigurator.turnOnOffMinARFFRestr = this.aircraftConfigurators[id].turnOnOffMinARFFRestr;
    this.aircraftConfigurator.turnOnOffMinARFFUnSuitable = this.aircraftConfigurators[id].turnOnOffMinARFFUnSuitable;

    this.aircraftConfigurator.aircraftMTOW = this.aircraftConfigurators[id].aircraftMTOW;
    this.aircraftConfigurator.aircraftMTOW_KG = this.aircraftConfigurators[id].aircraftMTOW_KG;

    this.aircraftConfigurator.aircraftDOW = this.aircraftConfigurators[id].aircraftDOW;
    this.aircraftConfigurator.aircraftDOW_KG = this.aircraftConfigurators[id].aircraftDOW_KG;

    this.aircraftConfigurator.acn_MTOW_F_A = this.aircraftConfigurators[id].acn_MTOW_F_A;
    this.aircraftConfigurator.acn_MTOW_F_B = this.aircraftConfigurators[id].acn_MTOW_F_B;
    this.aircraftConfigurator.acn_MTOW_F_C = this.aircraftConfigurators[id].acn_MTOW_F_C;
    this.aircraftConfigurator.acn_MTOW_F_D = this.aircraftConfigurators[id].acn_MTOW_F_D;

    this.aircraftConfigurator.acn_MTOW_R_A = this.aircraftConfigurators[id].acn_MTOW_R_A;
    this.aircraftConfigurator.acn_MTOW_R_B = this.aircraftConfigurators[id].acn_MTOW_R_B;
    this.aircraftConfigurator.acn_MTOW_R_C = this.aircraftConfigurators[id].acn_MTOW_R_C;
    this.aircraftConfigurator.acn_MTOW_R_D = this.aircraftConfigurators[id].acn_MTOW_R_D;

    this.aircraftConfigurator.acn_DOW_F_A = this.aircraftConfigurators[id].acn_DOW_F_A;
    this.aircraftConfigurator.acn_DOW_F_B = this.aircraftConfigurators[id].acn_DOW_F_B;
    this.aircraftConfigurator.acn_DOW_F_C = this.aircraftConfigurators[id].acn_DOW_F_C;
    this.aircraftConfigurator.acn_DOW_F_D = this.aircraftConfigurators[id].acn_DOW_F_D;

    this.aircraftConfigurator.acn_DOW_R_A = this.aircraftConfigurators[id].acn_DOW_R_A;
    this.aircraftConfigurator.acn_DOW_R_B = this.aircraftConfigurators[id].acn_DOW_R_B;
    this.aircraftConfigurator.acn_DOW_R_C = this.aircraftConfigurators[id].acn_DOW_R_C;
    this.aircraftConfigurator.acn_DOW_R_D = this.aircraftConfigurators[id].acn_DOW_R_D;

    this.aircraftConfigurator.mtow_SW = this.aircraftConfigurators[id].mtow_SW;
    this.aircraftConfigurator.mtow_DW = this.aircraftConfigurators[id].mtow_DW;
    this.aircraftConfigurator.mtow_DT = this.aircraftConfigurators[id].mtow_DT;
    this.aircraftConfigurator.mtow_DDT = this.aircraftConfigurators[id].mtow_DDT;

    this.aircraftConfigurator.dow_SW = this.aircraftConfigurators[id].dow_SW;
    this.aircraftConfigurator.dow_DW = this.aircraftConfigurators[id].dow_DW;
    this.aircraftConfigurator.dow_DT = this.aircraftConfigurators[id].dow_DT;
    this.aircraftConfigurator.dow_DDT = this.aircraftConfigurators[id].dow_DDT;
  }

  calculateARFFinNUM(tmp: string): number {
    let result = null;
    if (tmp) {
      if (tmp === 'A') {
        result = 5;
      } else if (tmp === 'B') {
        result = 6;
      } else if (tmp === 'C') {
        result = 7;
      } else if (tmp === 'D') {
        result = 8;
      } else if (tmp === 'E') {
        result = 10;
      }
    }
    return result;
  }

  onClickShowMainWindow() {
    if (this.showMainWindow === false) {
      this.showMainWindow = true;
      this.showHandlingWindow = false;
      this.showFuelingWindow = false;
    }
  }
  /*END Configurator END*/

  onClickShowHandling() {
    if (this.showHandlingWindow === false) {
      this.showMainWindow = false;
      this.showHandlingWindow = true;
      this.showFuelingWindow = false;
    }
  }

  onClickShowFueling() {
    if (this.showFuelingWindow === false) {
      this.showMainWindow = false;
      this.showHandlingWindow = false;
      this.showFuelingWindow = true;
    }
  }

  onClickShowAircraftConfiguratorWindow() {
    if (this.showAircraftConfiguratorWindow === false) {
      this.showAircraftConfiguratorWindow = true;

    } else if (this.showAircraftConfiguratorWindow === true) {
      this.showAircraftConfiguratorWindow = false;
      this.showAircraftConfiguratorDeleteWindow = false;
      this.deleteButtonClicked = false;
    }
  }

  onClickShowRWYwindow() {
    if (this.showRWYwindow === false) {
      this.showRWYwindow = true;
      this.showHelipadsWindow = false;
      this.showRemarksWindow = false;
    }
  }

  onClickShowHelipads() {
    if (this.showHelipadsWindow === false) {
      this.showRWYwindow = false;
      this.showHelipadsWindow = true;
      this.showRemarksWindow = false;
    }
  }

  onClickShowRemarks() {
    if (this.showRemarksWindow === false) {
      this.showRWYwindow = false;
      this.showHelipadsWindow = false;
      this.showRemarksWindow = true;
    }
  }

  onClickShowOverview() {
    if (this.showRWYwindow === true) {
      this.showOverview = true;
      this.showDeclaredDistances = false;
      this.showMarkingAndLighs = false;
    }
  }

  onClickShowDeclaredDistances() {
    if (this.showRWYwindow === true) {
      this.showOverview = false;
      this.showDeclaredDistances = true;
      this.showMarkingAndLighs = false;
    }
  }

  onClickShowMarkingAndLighs() {
    if (this.showRWYwindow === true) {
      this.showOverview = false;
      this.showDeclaredDistances = false;
      this.showMarkingAndLighs = true;
    }
  }

  handleAirportDetails() {
    const theAirportIata: string = this.route.snapshot.paramMap.get('iata');
    this.airportService.getAirport(theAirportIata).subscribe(
      data => {
        this.airport = data;
        this.airportService.formatParameters(this.airport);
        this.airportService.formatCoordinates(this.airport);
        this.listRunways(this.airport);
        this.listRemarks(this.airport);
      }
    )
  }

  listRunways(airport: Airport) {
    const facilityId: string = airport.id;

    //now search for the airport using facilityId
    this.airportService.searchRunways(facilityId).subscribe(
      data => {
        this.allRunways = data;
        this.airportService.formatRunwaysParameters(this.allRunways);
        this.runways = this.airportService.getOnlyRunways(this.allRunways);

        this.helipads = this.airportService.getOnlyHelipads(this.allRunways);
        this.numbersOfRunways = this.runways.length;
        this.numbersOfHelipads = this.helipads.length;
      }
    )
  }

  listRemarks(airport: Airport) {
    const facilityId: string = airport.id;

    //now search for the airport using facilityId
    this.airportService.searchRemarks(facilityId).subscribe(
      data => {
        this.allRemarks = data;
        this.numbersOfRemarks = this.allRemarks.length;
      }
    );
  }
}