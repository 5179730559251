import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import * as L1 from 'leaflet.markercluster';
import * as L2 from 'leaflet.zoomshowhide';
import { Airport } from '../common/airport';
import { Runway } from '../common/runway';
import { AircraftConfigurator } from '../common/aircraftConfigurator';
import { AirportDetailsComponent } from '../components/airport-details/airport-details.component';
import { AirportService } from './airport.service';

const iconUrl = './assets/images/iconfinder_map-marker_299087.png';
const iconAirport = L.icon({
  iconUrl,
  iconSize: [40, 40],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28]
});

const iconRunway = L.icon({
  iconUrl,
  iconSize: [20, 20],
  iconAnchor: [10, 20],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28]
});

@Injectable({
  providedIn: 'root'
})
export class MarkerService {

  constructor(private http: HttpClient, private airportService: AirportService) {
  }

  makeAirportMarker(map: L.Map, airport: Airport): void {
    if (airport.latitudeMap && airport.longitudeMap) {
      if (airport.iata && airport.icao) {
        const IATA_ICAO_name = airport.iata + "/" + airport.icao;
        const marker = L.marker([airport.latitudeMap, airport.longitudeMap], { icon: iconAirport })
          .bindTooltip(IATA_ICAO_name, { permanent: true, className: 'labelAirportMarker', offset: [0, 0] });
        marker.addTo(map);
      }
      if (airport.iata && !airport.icao) {
        const marker = L.marker([airport.latitudeMap, airport.longitudeMap], { icon: iconAirport })
          .bindTooltip(airport.iata, { permanent: true, className: 'labelAirportMarker', offset: [0, 0] });
        marker.addTo(map);
      }
      if (!airport.iata && airport.icao) {
        const marker = L.marker([airport.latitudeMap, airport.longitudeMap], { icon: iconAirport })
          .bindTooltip(airport.icao, { permanent: true, className: 'labelAirportMarker', offset: [0, 0] });
        marker.addTo(map);
      }
    }
  }

  makeRunwaysMarkers(runways: Runway[]): any {
    let runwayMarkers = new L1.MarkerClusterGroup({
      disableClusteringAtZoom: 13
    });

    if (runways) {
      for (let id = 0; id < runways.length; id++) {
        if (runways[id].baseEndPhysicalLatitudeMap && runways[id].baseEndPhysicalLongitudeMap && 
            runways[id].reciprocalEndPhysicalLatitudeMap && runways[id].reciprocalEndPhysicalLongitudeMap) {

          const RWYbaseENDname = "RWY" + runways[id].baseEndID;
          const markerBaseEnd = L.marker([runways[id].baseEndPhysicalLatitudeMap, runways[id].baseEndPhysicalLongitudeMap]
            , { icon: iconRunway })
            .bindTooltip(RWYbaseENDname, { permanent: true, className: 'labelRunwayMarker', offset: [0, 0] });
          runwayMarkers.addLayer(markerBaseEnd);

          const RWYreciprocalENDname = "RWY" + runways[id].reciprocalEndID;
          const markerReciprocalEnd = L.marker([runways[id].reciprocalEndPhysicalLatitudeMap, runways[id].reciprocalEndPhysicalLongitudeMap]
            , { icon: iconRunway })
            .bindTooltip(RWYreciprocalENDname, { permanent: true, className: 'labelRunwayMarker', offset: [0, 0] });
          runwayMarkers.addLayer(markerReciprocalEnd);
        }
      }
      return runwayMarkers;
    }
  }


  makeRunways(runways: Runway[], aircraftConfigurator: AircraftConfigurator, airportChild: Airport, switchConfiguratorStatus: boolean): any {
    let runwayLines = new L.LayerGroup();

    if (runways) {
      for (let id = 0; id < runways.length; id++) {
        if (runways[id].baseEndPhysicalLatitudeMap && runways[id].baseEndPhysicalLongitudeMap && 
          runways[id].reciprocalEndPhysicalLatitudeMap && runways[id].reciprocalEndPhysicalLongitudeMap) {

          const baseENDforLine = new L.LatLng(runways[id].baseEndPhysicalLatitudeMap, runways[id].baseEndPhysicalLongitudeMap);
          const reciprocalENDforLine = new L.LatLng(runways[id].reciprocalEndPhysicalLatitudeMap, runways[id].reciprocalEndPhysicalLongitudeMap);

          const markersForLine = [baseENDforLine, reciprocalENDforLine];

          let color = this.airportService.getColorOfRunwayMap(runways[id], aircraftConfigurator, airportChild, switchConfiguratorStatus);
          let firstpolyline = new L.Polyline(markersForLine, {
            color: color,
            weight: 5,
            opacity: 1,
            smoothFactor: 1

          });
          runwayLines.addLayer(firstpolyline);
        }
      }
      return runwayLines;
    }
  }
}