import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { User } from 'src/app/model/user';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { SubSink } from 'subsink';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { HeaderType } from 'src/app/enum/header-type.enum';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  public showLoading: boolean;
  private subscriptions: Subscription[] = [];

  streamCheckUsername$: Observable<Boolean>;
  streamCheckEmail$: Observable<Boolean>;

  private searchTermsCheckUsername$ = new Subject<string>();
  private searchTermsCheckEmail$ = new Subject<string>();
  userWasSuccessfullyCreated = false;
  emailSearchResults = false;
  usernameSearchResults = false;

  constructor(private router: Router, 
              private authenticationService: AuthenticationService,
              private notificationService: NotificationService,
              private userService: UserService) {}

  ngOnInit(): void {
    if (this.authenticationService.isUserLoggedIn()) {
      this.router.navigateByUrl('');
    }
    this.streamCheckUsername$ = this.searchTermsCheckUsername$.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.userService.checkIsUsernameFree(term))
    );

    this.streamCheckEmail$ = this.searchTermsCheckEmail$.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.userService.checkIsEmailFree(term))
    );
  }

  // Push a search term into the observable stream.
  doSearchUsername(term: string): void {
    let regexp = new RegExp('[a-zA-Z0-9.-_\S+]*');
    let test = regexp.test(term);
    
    if (test === true && !term.includes(' ') && term !== null && term !== '') {
      this.searchTermsCheckUsername$.next(term);
      this.usernameSearchResults = true;
    } else {
      this.usernameSearchResults = false;
    }
  }

  // Push a search term into the observable stream.
  doSearchEmail(term: string): void {
    let regexp = new RegExp('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}');
    let test = regexp.test(term);
    
    if (test === true) {
      this.searchTermsCheckEmail$.next(term);
      this.emailSearchResults = true;
    } else {
      this.emailSearchResults = false;
    }
  }

  public onRegister(user: User): void {
    this.showLoading = true;
    this.subs.add(
      this.authenticationService.register(user).subscribe(
        (response: User) => {
          this.showLoading = false;
          this.sendNotification(NotificationType.SUCCESS, `A new account was created for ${response.username}.`);
          this.userWasSuccessfullyCreated = true;
          this.onLogin(user);
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.showLoading = false;
          this.userWasSuccessfullyCreated = false;
        }
      )
    );
  }

  public onLogin(user: User): void {
    this.showLoading = true;
    this.subs.add(
      this.authenticationService.login(user).subscribe(
        (response: HttpResponse<User>) => {
          const token = response.headers.get(HeaderType.JWT_TOKEN);
          this.authenticationService.saveToken(token);
          this.authenticationService.addUserToLocalCache(response.body);
          this.router.navigateByUrl('/welcome');
          this.showLoading = false;
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendErrorNotification(NotificationType.ERROR, errorResponse.error.message);
          this.showLoading = false;
        }
      )
    );
  }
  
  private sendErrorNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}