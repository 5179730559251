import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) {}

    intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
        if (httpRequest.url.includes(`${this.authenticationService.host}/user/login`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/user/register`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/user/resetpassword`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/user/isusernamefree`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/user/isemailfree`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/api/airports`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/api/aircraftConfigurators`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/api/runways/search/findByRunwayCodeContaining`)) {
            return httpHandler.handle(httpRequest);
        }
        if (httpRequest.url.includes(`${this.authenticationService.host}/api/remarks/search/findByRemarkcodeContaining`)) {
            return httpHandler.handle(httpRequest);
        }

        this.authenticationService.loadToken();
        const token = this.authenticationService.getToken();
        const request = httpRequest.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
        return httpHandler.handle(request);
    }
}