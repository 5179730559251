import { Component, OnInit, ElementRef, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AirportService } from "src/app/services/airport.service";
import { Airport } from "src/app/common/airport";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  startWith,
  tap,
  isEmpty,
  filter,
} from "rxjs/operators";
import { fromEvent, Observable, Subject, interval } from "rxjs";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements OnInit {
  textElRef = "No clicks yet";
  clickWasInside = false;
  stream$: Observable<Airport[]>;
  showSearchResults: boolean;
  somePlaceholder = "Please enter IATA/ICAO/Airport name...";
  searchValue = null;
  private searchTerms = new Subject<string>();
  queryParams;

  constructor(private airportService: AirportService,
              private eRef: ElementRef,
              private route: ActivatedRoute) {}

 

  ngOnInit() {
    this.stream$ = this.searchTerms.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.airportService.searchAirports(term))
    );

    this.route.queryParams.subscribe(params => {
      if (params) {
        this.queryParams = params;
      }
    });
  }

  // Push a search term into the observable stream.
  doSearch(term: string): void {
    this.searchValue = null;
    this.showSearchResults = true;
    this.searchTerms.next(term);
  }
  /*closing of search results*/
  clearList() {
    this.searchValue = null;
    this.showSearchResults = false;
  }

  @HostListener('click')
  clickInside() {
    this.textElRef = 'click is INside';
    this.clickWasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.clickWasInside) {
      this.textElRef = 'click is OUTside';
      this.clearList();
    }
    this.clickWasInside = false;
  }
}